import type { FC } from 'react';
import { memo, useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import cn from 'classnames';

import { SidebarTabId } from 'app-constants';
import activeSidebarTabVar from '../Sidebar/SidebarTabs/sidebarTabsState';

export type TabOptions = {
    header: string;
    content: JSX.Element | null;
    id: SidebarTabId;
};

interface Props {
    tabs: TabOptions[];
}

const TabView: FC<Props> = ({ tabs }) => {
    const activeTab = useReactiveVar(activeSidebarTabVar);

    const makeSetActiveTabHandler = useCallback(
        (id: SidebarTabId) => () => activeSidebarTabVar(id),
        []
    );

    const content =
        tabs.length > 1
            ? tabs.find((tab) => tab.id === activeTab)?.content
            : tabs[0].content;

    return (
        <div className="flex h-full flex-col" data-test="tab-view">
            <div className="mb-2 flex h-full max-h-10">
                {!!tabs.length &&
                    tabs.map((tab) => (
                        <div
                            key={tab.header}
                            onClick={makeSetActiveTabHandler(tab.id)}
                            className={cn(
                                'flex w-full cursor-pointer items-center justify-center border-b-default border-surface-middle typo-sm-1',
                                tab.id === activeTab
                                    ? 'bg-surface-middle text-primary-white'
                                    : 'bg-surface-dark text-grey-700'
                            )}
                            data-test={`tab-view tab-view--${tab.id}`}
                        >
                            {tab.header}
                        </div>
                    ))}
            </div>
            {content}
        </div>
    );
};

export default memo(TabView);
