import { useCallback, useEffect, useRef, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { first, isBoolean } from 'lodash';
import { useTranslations } from 'next-intl';

import { AppLink, SidebarTabId } from 'app-constants';
import { betslipVars } from 'components/betting/Betslip';
import { useOnBetslipBet } from 'components/betting/Betslip/hooks';
import { useBanners } from 'components/betting/Promo/Banners';
import { useBannerZones } from 'components/betting/Promo/BannerZones';
import { BannerZoneLocation } from 'components/betting/Promo/BannerZones/constants';
import { useRouter } from 'components/HybridRouter';
import { useIsMobile } from 'components/utilities/MobileDetect';
import { Feature, useFeatureFlags } from 'services/features';
import SidebarPromoZone from '../SidebarPromoZone';
import { Tab } from '../types';
import activeSidebarTabVar from './sidebarTabsState';

interface Output {
    bannerTab: Tab | null;
    loading: boolean;
    onResponseBonusProgram: (isExist: boolean) => void;
    isBonusProgramHidden: boolean;
    isBonusProgramExist: boolean;
}

const useSidebarTabs = (): Output => {
    const oddState = useReactiveVar(betslipVars);
    const t = useTranslations('betting-global.global');
    const initPromoRef = useRef<boolean>(true);

    const { features } = useFeatureFlags();
    const { isMobile } = useIsMobile();

    const [isBonusProgramExist, setIsBonusProgramExist] = useState<
        boolean | null
    >(null); // NOTE: null - it is not loaded BP;

    const { pathname } = useRouter();

    const { data: bannerZoneData, loading: bannerZonesLoading } =
        useBannerZones({
            variables: {
                pageLocations: [BannerZoneLocation.RIGHT],
            },
            skip: pathname === AppLink.BETS,
        });
    const zoneId = first(bannerZoneData?.bannerZones)?.id;

    const { banners, loading: bannersLoading } = useBanners({ zoneId });

    const isBonusProgramHidden =
        isMobile || !features.isEnabled(Feature.BonusProgram);

    const loading = bannerZonesLoading || bannersLoading;

    const isBannerTab =
        bannerZoneData &&
        bannerZoneData.bannerZones.length > 0 &&
        banners.length > 0 &&
        (isBonusProgramHidden ||
            (!isBonusProgramExist && isBoolean(isBonusProgramExist)));

    const onResponseBonusProgram = useCallback((isExist: boolean) => {
        setIsBonusProgramExist(isExist);
    }, []);

    useEffect(() => {
        initPromoRef.current = true;
    }, [pathname]);

    useEffect(() => {
        if (!isBannerTab && activeSidebarTabVar() === SidebarTabId.Promo) {
            activeSidebarTabVar(SidebarTabId.Betslip);
        }

        if (
            initPromoRef.current &&
            isBannerTab &&
            oddState.oddIds.length === 0
        ) {
            activeSidebarTabVar(SidebarTabId.Promo);
        }

        if (
            oddState.oddIds.length > 0 ||
            (!isBonusProgramExist &&
                activeSidebarTabVar() === SidebarTabId.Quest)
        ) {
            activeSidebarTabVar(SidebarTabId.Betslip);
        }

        if (
            !oddState.oddIds.length &&
            isBonusProgramExist &&
            features.isEnabled(Feature.BonusProgram)
        ) {
            activeSidebarTabVar(SidebarTabId.Quest);
        }

        if (!loading && zoneId) {
            initPromoRef.current = false;
        }
    }, [
        isBannerTab,
        oddState.oddIds,
        loading,
        zoneId,
        isBonusProgramExist,
        features,
    ]);

    useOnBetslipBet({
        onSuccess: () => {
            activeSidebarTabVar(SidebarTabId.BetHistory);
        },
        fetchPolicy: 'cache-only',
    });

    return {
        bannerTab: isBannerTab
            ? {
                  header: t('promo').toUpperCase(),
                  id: SidebarTabId.Promo,
                  content: (
                      <SidebarPromoZone
                          loading={loading}
                          banners={banners}
                          bannerZones={bannerZoneData.bannerZones}
                      />
                  ),
              }
            : null,
        loading,
        onResponseBonusProgram,
        isBonusProgramHidden,
        isBonusProgramExist: !!isBonusProgramExist,
    };
};

export default useSidebarTabs;
