import type { FC } from 'react';
import { memo } from 'react';

import Betslip from '../Betslip';
import BetslipOddAcceptChangeModal from '../BetslipOddAcceptChangeModal';
import NotEnoughBalanceModal from '../NotEnoughBalanceModal';
import SyncWithPlaceBetVars from '../SyncWithPlaceBetVars';

const BetslipDesktop: FC = () => {
    return (
        <div className="flex h-full flex-col lg:relative">
            <SyncWithPlaceBetVars />
            <Betslip />
            <NotEnoughBalanceModal />
            <BetslipOddAcceptChangeModal />
        </div>
    );
};

export default memo(BetslipDesktop);
