import { useMemo } from 'react';

import { FetchStatus, POLLING_INTERVAL } from 'app-constants';
import { useGetArchivedBonusProgram } from 'gql/cms/queries/bonus-program/getArchivedBonusProgram.cms';
import { useGetAvailableBonusProgram } from 'gql/cms/queries/bonus-program/getAvailableBonusProgram.cms';
import { useGetProcessingBonusProgram } from 'gql/cms/queries/bonus-program/getProcessingBonusProgram.cms';
import { useGetPublicBonusProgram } from 'gql/cms/queries/bonus-program/getPublicBonusProgram.cms';
import { useAuthorization, useDidUpdate, useMounted } from 'hooks';
import { PlayerProgramStatusTypeEnumType } from 'types/api.cms';
import { MAP_STATUSES, VARIABLES } from '../constants';
import { GQLQueries } from '../types';
import { findBonusProgram } from '../utils';

type PreviewProcessingBonusProgram =
    GQLQueries['getPreviewProcessingBonusPrograms'][0]['bonusProgramsFullProcessing'][0];
type PreviewPublicBonusProgram =
    GQLQueries['getPreviewPublicBonusProgram'][0]['publicBonusPrograms'][0];
type PreviewAvailableBonusProgram =
    GQLQueries['getPreviewAvailableBonusPrograms'][0]['bonusProgramsFullAvailable'][0];
type PreviewArchivedBonusProgram =
    GQLQueries['getPreviewArchivedBonusPrograms'][0]['bonusProgramsFullArchived']['list'][0];

interface Input {
    onResponseBannerPrograms: (isExist: boolean) => void;
}

const useBannerProgramsFetcher = ({
    onResponseBannerPrograms,
}: Input): void => {
    const isMounted = useMounted();

    const { isRegistrationFinished } = useAuthorization();

    const { data: previewPublicBPData } = useGetPublicBonusProgram(() => ({
        variables: VARIABLES,
        skip: isRegistrationFinished,
        pollInterval: POLLING_INTERVAL,
    }));

    const publicBonusProgram: PreviewPublicBonusProgram | undefined =
        useMemo(() => {
            if (!isMounted || !previewPublicBPData) return;

            const { publicBonusPrograms } = previewPublicBPData;

            return publicBonusPrograms.find((programTemplate) =>
                findBonusProgram({ programTemplate })
            );
        }, [previewPublicBPData, isMounted]);

    const {
        data: previewProcessingBPData,
        networkStatus: networkStatusProcessingBP,
        called: isCalledPreviewProcessingBP,
    } = useGetProcessingBonusProgram(() => ({
        variables: VARIABLES,
        skip: !isRegistrationFinished,
        pollInterval: POLLING_INTERVAL,
        notifyOnNetworkStatusChange: true,
    }));

    const processingBonusProgram: PreviewProcessingBonusProgram | undefined =
        useMemo(() => {
            if (!isMounted || !previewProcessingBPData) return;

            const { bonusProgramsFullProcessing } = previewProcessingBPData;

            return bonusProgramsFullProcessing.find((bp) =>
                findBonusProgram(bp)
            );
        }, [isMounted, previewProcessingBPData]);

    const fetchStatusProcessingBonusProgram: FetchStatus =
        MAP_STATUSES[networkStatusProcessingBP];

    const isProcessingBonusProgramsAbsent =
        fetchStatusProcessingBonusProgram === FetchStatus.LOADED &&
        !processingBonusProgram &&
        isCalledPreviewProcessingBP;

    const {
        data: previewAvailableBPData,
        networkStatus: networkStatusAvailableBP,
        called: isCalledPreviewAvailableBP,
    } = useGetAvailableBonusProgram(() => ({
        variables: VARIABLES,
        skip: !isRegistrationFinished || !isProcessingBonusProgramsAbsent,
        pollInterval: POLLING_INTERVAL,
        notifyOnNetworkStatusChange: true,
    }));
    const fetchStatusAvailableBonusProgram: FetchStatus =
        MAP_STATUSES[networkStatusAvailableBP];

    const availableBonusProgram: PreviewAvailableBonusProgram | undefined =
        useMemo(() => {
            if (!isMounted || !previewAvailableBPData) return;

            const { bonusProgramsFullAvailable } = previewAvailableBPData;

            return bonusProgramsFullAvailable.find((bp) =>
                findBonusProgram(bp)
            );
        }, [isMounted, previewAvailableBPData]);

    const isAvailableBonusProgramsAbsent =
        fetchStatusAvailableBonusProgram === FetchStatus.LOADED &&
        !availableBonusProgram &&
        isCalledPreviewAvailableBP;

    const { data: previewArchivedBPData } = useGetArchivedBonusProgram(() => ({
        variables: VARIABLES,
        skip: !isRegistrationFinished || !isAvailableBonusProgramsAbsent,
        pollInterval: POLLING_INTERVAL,
    }));

    const archivedBonusProgram: PreviewArchivedBonusProgram | undefined =
        useMemo(() => {
            if (!isMounted || !previewArchivedBPData) return;

            const { bonusProgramsFullArchived } = previewArchivedBPData;

            return bonusProgramsFullArchived.list.find(
                (bp) =>
                    findBonusProgram(bp) &&
                    bp.bonusProgramData.playerProgram.status.type ===
                        PlayerProgramStatusTypeEnumType.Completed
            );
        }, [previewArchivedBPData, isMounted]);

    const isBonusProgramExist = isRegistrationFinished
        ? !!(
              processingBonusProgram ||
              availableBonusProgram ||
              archivedBonusProgram
          )
        : !!publicBonusProgram;

    useDidUpdate(() => {
        onResponseBannerPrograms(isBonusProgramExist);
    }, [isBonusProgramExist, onResponseBannerPrograms]);
};

export default useBannerProgramsFetcher;
