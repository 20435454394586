import type { FC } from 'react';
import { useTranslations } from 'next-intl';

import BetslipStub from 'components/betting/Betslip/BetslipStub';

const SidebarDesktopStub: FC = () => {
    const tBettingGlobal = useTranslations('betting-global.global');

    return (
        <div className="hidden min-h-[450px] max-w-[282px] flex-1 shrink-0 border-default border-surface-middle md:h-[calc(100vh-theme(spacing.header-offset)-32px)] lg:block">
            <div className="flex h-full flex-col">
                <div className="mb-2 flex h-full max-h-10">
                    <div className="flex w-full items-center justify-center border-b-default border-surface-middle bg-surface-middle text-primary-white typo-sm-1">
                        {tBettingGlobal('BettingSlip').toUpperCase()}
                    </div>
                </div>
                <BetslipStub showSpinner={false} />
            </div>
        </div>
    );
};

export default SidebarDesktopStub;
