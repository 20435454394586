import { FC, useMemo } from 'react';
import { useTranslations } from 'next-intl';

import { SidebarTabId } from 'app-constants';
import BetHistory from 'components/betting/BetsHistory';
import BetslipDesktop from 'components/betting/Betslip/BetslipDesktop';
import BonusProgram from 'components/betting/BonusProgram';
import BannerProgramsFetcher from 'components/betting/BonusProgram/BannerProgramsFetcher';
import ErrorBoundary from 'components/ErrorBoundary';
import Delayed from 'components/utilities/Delayed';
import withClientMessages from 'hocs/withClientMessages';
import { useAuthorization } from 'hooks';
import TabView from '../../TabView';
import SidebarDesktopStub from '../SidebarDesktop/SidebarDesktopStub';
import { Tab } from '../types';
import useSidebarTabs from './useSidebarTabs';

const DELAY = 2000;

const SidebarTabs: FC = () => {
    const tBettingGlobal = useTranslations('betting-global.global');
    const tBonusProgram = useTranslations('betting-bonusProgram.bonusProgram');

    const { isAuthorized } = useAuthorization();

    const {
        bannerTab,
        onResponseBonusProgram,
        isBonusProgramHidden,
        isBonusProgramExist,
    } = useSidebarTabs();

    const tabs: Tab[] = useMemo(
        () => [
            {
                header: tBettingGlobal('BettingSlip').toUpperCase(),
                content: <BetslipDesktop />,
                id: SidebarTabId.Betslip,
            },
            ...(isAuthorized
                ? [
                      {
                          header: tBettingGlobal('myBets').toUpperCase(),
                          id: SidebarTabId.BetHistory,
                          content: <BetHistory />,
                      },
                  ]
                : []),
            ...(bannerTab ? [bannerTab] : []),
            ...(!isBonusProgramHidden && isBonusProgramExist
                ? [
                      {
                          header: tBonusProgram('quest').toUpperCase(),
                          content: <BonusProgram />,
                          id: SidebarTabId.Quest,
                      },
                  ]
                : []),
        ],
        [
            bannerTab,
            isAuthorized,
            tBettingGlobal,
            tBonusProgram,
            isBonusProgramHidden,
            isBonusProgramExist,
        ]
    );

    return (
        <ErrorBoundary>
            <TabView tabs={tabs} />

            {!isBonusProgramHidden && (
                <Delayed delay={DELAY}>
                    {/* NOTE: preload banners for render 'Quest' tab */}
                    <BannerProgramsFetcher
                        onResponseBannerPrograms={onResponseBonusProgram}
                    />
                </Delayed>
            )}
        </ErrorBoundary>
    );
};

export default withClientMessages(SidebarTabs, ['betting-bonusProgram'], () => (
    <SidebarDesktopStub />
));
