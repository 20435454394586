import React, { createElement, Fragment, useState } from 'react';

import { useMount } from 'hooks';

interface Props {
    delay: number;
    children: React.ReactNode;
}

const Delayed: React.FC<Props> = ({ delay, children }) => {
    const [isRendered, setIsRender] = useState(false);

    useMount(() => {
        const timer = setTimeout(() => {
            setIsRender(true);
        }, delay);

        return () => clearTimeout(timer);
    });

    if (!isRendered) return null;

    return createElement(Fragment, {}, children);
};

export default Delayed;
