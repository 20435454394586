import React from 'react';

import useBannerProgramsFetcher from './useBannerProgramsFetcher';

interface Props {
    onResponseBannerPrograms: (isExist: boolean) => void;
}

const BannerProgramsFetcher: React.FC<Props> = ({
    onResponseBannerPrograms,
}) => {
    useBannerProgramsFetcher({ onResponseBannerPrograms });

    return null;
};

export default BannerProgramsFetcher;
