import { NetworkStatus } from '@apollo/client';
import cn from 'classnames';

import { FetchStatus } from 'app-constants';
import { GQLQueries } from './types';

export const truncateClassName = cn('overflow-hidden text-ellipsis');

export enum BonusType {
    Available = 'available',
    Archived = 'archived',
    Processing = 'processing',
}

export enum TaskType {
    Deposit = 'deposit',
}

export const VARIABLES:
    | GQLQueries['getPublicBonusProgram'][1]
    | GQLQueries['getArchivedBonusPrograms'][1]
    | GQLQueries['getProcessingBonusPrograms'][1]
    | GQLQueries['getAvailableBonusPrograms'][1] = {
    filter: {
        category: 'betting',
        displayInType: 'bet_slip',
    },
};

export const MAP_STATUSES: Record<NetworkStatus, FetchStatus> = {
    [NetworkStatus.loading]: FetchStatus.LOADING,
    [NetworkStatus.refetch]: FetchStatus.LOADING,
    [NetworkStatus.setVariables]: FetchStatus.LOADING,
    [NetworkStatus.fetchMore]: FetchStatus.LOADING,
    [NetworkStatus.poll]: FetchStatus.LOADED,
    [NetworkStatus.ready]: FetchStatus.LOADED,
    [NetworkStatus.error]: FetchStatus.LOADED,
};
