export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A local date string (i.e., with no associated timezone) in `YYYY-MM-DD` format, e.g. `2020-01-01`. */
    Date: any;
    /** The `DateTime` scalar type represents time data, represented as an ISO-8601 encoded UTC date string. */
    DateTime: any;
    /** The `Decimal` scalar type represents decimal (float with high precision as a string). */
    Decimal: any;
    /**
     * The `Upload` special type represents a file to be uploaded in the same HTTP request as specified by
     *  [graphql-multipart-request-spec](https://github.com/jaydenseric/graphql-multipart-request-spec).
     */
    Upload: any;
};

export type AcceptRuleInput = {
    type: Scalars['String'];
    versions: Array<Scalars['String']>;
};

export type AcceptTermsInput = {
    rules: Array<AcceptRuleInput>;
};

export type Achievement = {
    __typename?: 'Achievement';
    conditionRules: Array<ConditionRuleInterface>;
    /** Indicates achievement is available for player to achieve */
    enabled: Scalars['Boolean'];
    name: Scalars['String'];
    type: AchievementEnumType;
};

export type AchievementEntityImages = {
    __typename?: 'AchievementEntityImages';
    finish: Scalars['String'];
    inProgress: Scalars['String'];
};

export enum AchievementEnumType {
    Bet = 'BET',
    Bonus = 'BONUS',
    Kyc = 'KYC',
    Login = 'LOGIN',
    LoyaltyProgram = 'LOYALTY_PROGRAM',
    Payment = 'PAYMENT',
    Play = 'PLAY',
    Profile = 'PROFILE',
    TopWinners = 'TOP_WINNERS',
    Tournament = 'TOURNAMENT',
}

export type AchievementTupleType = {
    __typename?: 'AchievementTupleType';
    achievement: Achievement;
    achievementEntity: LocalizedAchievementEntity;
};

export type ActivateBonusBalanceInput = {
    active: Scalars['Boolean'];
    bonusBalanceGroupName: Scalars['String'];
    bonusBalanceId: Scalars['Int'];
};

export type AddressElement = {
    __typename?: 'AddressElement';
    city: Scalars['String'];
    /**
     * Country in ISO_3166-1 format (2 letters in upper case).
     * Example: GB, US, DE, FR, etc.
     */
    country: Scalars['String'];
    street: Scalars['String'];
    zipCode: Scalars['String'];
};

export type AleaPlayPlayData = PlayData & {
    __typename?: 'AleaPlayPlayData';
    casinoPlayerId: Scalars['String'];
    casinoSessionId: Scalars['String'];
    country: Scalars['String'];
    currency: Scalars['String'];
    demo: Scalars['Boolean'];
    device: Scalars['String'];
    environmentCredential: Scalars['String'];
    gameId: Scalars['String'];
    gameProviderName: Scalars['String'];
    gameServerUrl: Scalars['String'];
    gameSizeOptions: GameSizeOptions;
    locale: Scalars['String'];
    playUsingBonusBalanceEnabled: Scalars['Boolean'];
    signature: Scalars['String'];
};

export type AnalyticsSettingsResult = {
    __typename?: 'AnalyticsSettingsResult';
    reportServiceUrl: Scalars['String'];
};

export type ArchivedBonusProgram = {
    __typename?: 'ArchivedBonusProgram';
    bonusProgramData: ArchivedBonusProgramData;
    /**
     * Returns time of assigning bonus program to the user (aka time when it was transitioned to "processing" state).
     * Can be useful when you want to know "when it was activated".
     */
    createdAtForPlayer: Scalars['DateTime'];
    programTemplate: BonusProgramLocalized;
    /**
     * deprecated use bonusProgramData.playerProgram to get status type
     * @deprecated use ArchivedBonusProgramDataInterface::getPlayerProgram()::status instead
     */
    status: Scalars['String'];
    type: Scalars['String'];
};

export type ArchivedBonusProgramData = {
    createdAt?: Maybe<Scalars['DateTime']>;
    description: Scalars['String'];
    id?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    playerProgram: PlayerProgram;
    taskConnections: Array<TaskConnection>;
    taskSettings: TaskSettingsCollection;
};

export type ArchivedBonusProgramDataImpl = ArchivedBonusProgramData & {
    __typename?: 'ArchivedBonusProgramDataImpl';
    createdAt?: Maybe<Scalars['DateTime']>;
    description: Scalars['String'];
    id?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    playerProgram: PlayerProgram;
    taskConnections: Array<TaskConnection>;
    taskSettings: TaskSettingsCollection;
};

export type ArchivedBonusProgramListType = {
    __typename?: 'ArchivedBonusProgramListType';
    list: Array<ArchivedBonusProgram>;
    nextOffset: Scalars['Int'];
};

export type Auth4PlayData = {
    __typename?: 'Auth4PlayData';
    id: Scalars['String'];
    isEnabled: Scalars['Boolean'];
    matchKey: Scalars['String'];
    network: Scalars['String'];
    token: Scalars['String'];
};

export type Auth4PlaySettingsData = {
    __typename?: 'Auth4PlaySettingsData';
    auth4playData: Array<Auth4PlayData>;
    host: Scalars['String'];
};

export type AuthLoginTaskSettings = {
    id: Scalars['String'];
    platformClient: Scalars['String'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
};

export type AuthLoginTaskSettingsImpl = AuthLoginTaskSettings & {
    __typename?: 'AuthLoginTaskSettingsImpl';
    id: Scalars['String'];
    platformClient: Scalars['String'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
};

export enum AuthMethodIdEnum {
    AutologinTokenAuthMethodId = 'AUTOLOGIN_TOKEN_AUTH_METHOD_ID',
    EmailAuthMethodId = 'EMAIL_AUTH_METHOD_ID',
    MultiAuthMethodId = 'MULTI_AUTH_METHOD_ID',
    PhoneAuthMethodId = 'PHONE_AUTH_METHOD_ID',
    RecoveryAuthMethodId = 'RECOVERY_AUTH_METHOD_ID',
    SimpleAuthMethodId = 'SIMPLE_AUTH_METHOD_ID',
    SocialAuthMethodId = 'SOCIAL_AUTH_METHOD_ID',
    TrustlyAuthMethodId = 'TRUSTLY_AUTH_METHOD_ID',
}

export type AvailabilityCondition = {
    finishAt: Scalars['DateTime'];
    startAt: Scalars['DateTime'];
};

export type AvailabilityConditionImpl = AvailabilityCondition & {
    __typename?: 'AvailabilityConditionImpl';
    finishAt: Scalars['DateTime'];
    startAt: Scalars['DateTime'];
};

export type AvailableBonusProgram = {
    __typename?: 'AvailableBonusProgram';
    bonusProgramData: BonusProgramData;
    programTemplate: BonusProgramLocalized;
    type: Scalars['String'];
};

export type AvatarElement = {
    __typename?: 'AvatarElement';
    avatar: Scalars['String'];
    full: Scalars['String'];
    medium: Scalars['String'];
};

export type Balance = {
    balance: Scalars['Float'];
    brandId: Scalars['Int'];
    createdAt: Scalars['DateTime'];
    currency?: Maybe<Currency>;
    currencyCode?: Maybe<Scalars['String']>;
    currencyId?: Maybe<Scalars['Int']>;
    deletedAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['Int'];
    isDeleted: Scalars['Boolean'];
    playerId: Scalars['String'];
    refundSum: Scalars['Float'];
    typeId: Scalars['Int'];
};

export type BalanceImpl = Balance & {
    __typename?: 'BalanceImpl';
    balance: Scalars['Float'];
    brandId: Scalars['Int'];
    createdAt: Scalars['DateTime'];
    currency?: Maybe<Currency>;
    currencyCode?: Maybe<Scalars['String']>;
    currencyId?: Maybe<Scalars['Int']>;
    deletedAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['Int'];
    isDeleted: Scalars['Boolean'];
    playerId: Scalars['String'];
    refundSum: Scalars['Float'];
    typeId: Scalars['Int'];
};

export type BalanceJournalEntry = {
    actionTypeId: Scalars['Int'];
    balanceAfter: Scalars['Float'];
    balanceBefore: Scalars['Float'];
    balanceDelta: Scalars['Float'];
    balanceId: Scalars['Int'];
    balanceType: Scalars['Int'];
    brandId: Scalars['Int'];
    createdAt?: Maybe<Scalars['DateTime']>;
    currency?: Maybe<Currency>;
    id: Scalars['String'];
    playerId: Scalars['Int'];
};

export type BalanceJournalEntryImpl = BalanceJournalEntry & {
    __typename?: 'BalanceJournalEntryImpl';
    actionTypeId: Scalars['Int'];
    balanceAfter: Scalars['Float'];
    balanceBefore: Scalars['Float'];
    balanceDelta: Scalars['Float'];
    balanceId: Scalars['Int'];
    balanceType: Scalars['Int'];
    brandId: Scalars['Int'];
    createdAt?: Maybe<Scalars['DateTime']>;
    currency?: Maybe<Currency>;
    id: Scalars['String'];
    playerId: Scalars['Int'];
};

export type BalanceJournalEntryListType = {
    __typename?: 'BalanceJournalEntryListType';
    hasNextPart: Scalars['Boolean'];
    list: Array<BalanceJournalEntry>;
};

export enum BalanceTypeEnumType {
    Bonus = 'BONUS',
    None = 'NONE',
    Real = 'REAL',
}

export type BankIdVerificationRequest = {
    method: Scalars['String'];
    url: Scalars['String'];
};

export type BankIdVerificationRequestImpl = BankIdVerificationRequest & {
    __typename?: 'BankIdVerificationRequestImpl';
    method: Scalars['String'];
    url: Scalars['String'];
};

export type BannedLocationResultType = {
    __typename?: 'BannedLocationResultType';
    /** Returns whether current country/region is blocked */
    locationBlocked: Scalars['Boolean'];
};

export type BestPlayerData = {
    amount: Scalars['Float'];
    currency?: Maybe<Currency>;
    /** Returns null, when a game is unavailable for now (disabled or request for request platform/locale) */
    game?: Maybe<Game>;
    gameId: Scalars['String'];
    /** True if the current platform matches the game platform(mobile/web) */
    linked: Scalars['Boolean'];
    login: Scalars['String'];
    playerId: Scalars['Int'];
};

export type BestPlayerDataImpl = BestPlayerData & {
    __typename?: 'BestPlayerDataImpl';
    amount: Scalars['Float'];
    currency?: Maybe<Currency>;
    /** Returns null, when a game is unavailable for now (disabled or request for request platform/locale) */
    game?: Maybe<Game>;
    gameId: Scalars['String'];
    /** True if the current platform matches the game platform(mobile/web) */
    linked: Scalars['Boolean'];
    login: Scalars['String'];
    playerId: Scalars['Int'];
};

export type BetCoefficientGreaterThanConditionRule = ConditionRuleInterface & {
    __typename?: 'BetCoefficientGreaterThanConditionRule';
    betType: Scalars['String'];
    type: ConditionRuleEnumType;
    value: Scalars['String'];
};

export type BetGamesPlayData = PlayData & {
    __typename?: 'BetGamesPlayData';
    currentGame?: Maybe<Scalars['String']>;
    demo: Scalars['Boolean'];
    gameId: Scalars['String'];
    gameProviderName: Scalars['String'];
    gameServerUrl: Scalars['String'];
    gameSizeOptions: GameSizeOptions;
    iframeClientServer: Scalars['String'];
    language: Scalars['String'];
    partner: Scalars['String'];
    playUsingBonusBalanceEnabled: Scalars['Boolean'];
    server: Scalars['String'];
    token: Scalars['String'];
};

export type BetTaskSettings = {
    amountPerBet: Array<SettingsCurrencyMap>;
    balanceTypes: Array<Scalars['String']>;
    betCount: Scalars['Int'];
    gameIds: Array<Scalars['String']>;
    id: Scalars['String'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
    totalBetAmount: Array<SettingsCurrencyMap>;
};

export type BetTaskSettingsImpl = BetTaskSettings & {
    __typename?: 'BetTaskSettingsImpl';
    amountPerBet: Array<SettingsCurrencyMap>;
    balanceTypes: Array<Scalars['String']>;
    betCount: Scalars['Int'];
    gameIds: Array<Scalars['String']>;
    id: Scalars['String'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
    totalBetAmount: Array<SettingsCurrencyMap>;
};

export type BetsExpressOrdinar = {
    __typename?: 'BetsExpressOrdinar';
    betType: Scalars['String'];
    betsCount: Scalars['Int'];
};

export type BetsLivePrematch = {
    __typename?: 'BetsLivePrematch';
    betsCount: Scalars['Int'];
    matchType: Scalars['String'];
};

export type BettingData = {
    __typename?: 'BettingData';
    loaderScriptName: Scalars['String'];
    loaderScriptUrl: Scalars['String'];
    publicServiceUrl: Scalars['String'];
    staticUrl: Scalars['String'];
    token: Scalars['String'];
};

export type BettingFeature = {
    enabled: Scalars['Boolean'];
    name: Scalars['String'];
};

export type BettingFeatureImpl = BettingFeature & {
    __typename?: 'BettingFeatureImpl';
    enabled: Scalars['Boolean'];
    name: Scalars['String'];
};

export type BettingMatchTracker = {
    enabled: Scalars['Boolean'];
};

export type BettingMatchTrackerImpl = BettingMatchTracker & {
    __typename?: 'BettingMatchTrackerImpl';
    enabled: Scalars['Boolean'];
};

export type BettingPrediction = {
    __typename?: 'BettingPrediction';
    id: Scalars['String'];
    tournamentId: Scalars['String'];
    tournamentOutcome: BettingTournamentOutcome;
};

export type BettingSettings = {
    features: Array<BettingFeature>;
    loaderScriptName: Scalars['String'];
    matchTrackersDisabled: Scalars['Boolean'];
    publicServiceUrl: Scalars['String'];
    sportEventScoreboardUrl: Scalars['String'];
    sports: Array<BettingSport>;
    staticUrl: Scalars['String'];
    widgetsStaticUrl: Scalars['String'];
};

export type BettingSettingsImpl = BettingSettings & {
    __typename?: 'BettingSettingsImpl';
    features: Array<BettingFeature>;
    loaderScriptName: Scalars['String'];
    matchTrackersDisabled: Scalars['Boolean'];
    publicServiceUrl: Scalars['String'];
    sportEventScoreboardUrl: Scalars['String'];
    sports: Array<BettingSport>;
    staticUrl: Scalars['String'];
    widgetsStaticUrl: Scalars['String'];
};

export type BettingSport = {
    /** Contains uploaded svg icon id (empty as default) */
    iconId: Scalars['String'];
    id: Scalars['String'];
    matchTracker: BettingMatchTracker;
    name: Scalars['String'];
    type: Scalars['String'];
};

export type BettingSportImpl = BettingSport & {
    __typename?: 'BettingSportImpl';
    /** Contains uploaded svg icon id (empty as default) */
    iconId: Scalars['String'];
    id: Scalars['String'];
    matchTracker: BettingMatchTracker;
    name: Scalars['String'];
    type: Scalars['String'];
};

export type BettingSportsData = {
    __typename?: 'BettingSportsData';
    playerText: Scalars['String'];
    teamText: Scalars['String'];
};

export type BettingSsrPageRule = {
    regexp: Scalars['String'];
    routeName: Scalars['String'];
    selector: Scalars['String'];
    timeout: Scalars['Int'];
};

export type BettingSsrPageRuleImpl = BettingSsrPageRule & {
    __typename?: 'BettingSsrPageRuleImpl';
    regexp: Scalars['String'];
    routeName: Scalars['String'];
    selector: Scalars['String'];
    timeout: Scalars['Int'];
};

export type BettingTaskSettings = {
    amountPerBet: Array<SettingsCurrencyMap>;
    amountPerLoss: Array<SettingsCurrencyMap>;
    amountPerWin: Array<SettingsCurrencyMap>;
    balanceTypes: Array<Scalars['String']>;
    betCount: Scalars['Int'];
    betUnique: Scalars['Boolean'];
    id: Scalars['String'];
    lossCount: Scalars['Int'];
    lossUnique: Scalars['Boolean'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
    totalBetAmount: Array<SettingsCurrencyMap>;
    totalLossAmount: Array<SettingsCurrencyMap>;
    totalWinAmount: Array<SettingsCurrencyMap>;
    waitSettled: Scalars['Boolean'];
    winAmountsInDeltas: Scalars['Boolean'];
    winCount: Scalars['Int'];
    winUnique: Scalars['Boolean'];
};

export type BettingTaskSettingsImpl = BettingTaskSettings & {
    __typename?: 'BettingTaskSettingsImpl';
    amountPerBet: Array<SettingsCurrencyMap>;
    amountPerLoss: Array<SettingsCurrencyMap>;
    amountPerWin: Array<SettingsCurrencyMap>;
    balanceTypes: Array<Scalars['String']>;
    betCount: Scalars['Int'];
    betUnique: Scalars['Boolean'];
    id: Scalars['String'];
    lossCount: Scalars['Int'];
    lossUnique: Scalars['Boolean'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
    totalBetAmount: Array<SettingsCurrencyMap>;
    totalLossAmount: Array<SettingsCurrencyMap>;
    totalWinAmount: Array<SettingsCurrencyMap>;
    waitSettled: Scalars['Boolean'];
    winAmountsInDeltas: Scalars['Boolean'];
    winCount: Scalars['Int'];
    winUnique: Scalars['Boolean'];
};

export type BettingTournamentOutcome = {
    __typename?: 'BettingTournamentOutcome';
    matchedRounds: Scalars['Int'];
    totalRounds: Scalars['Int'];
};

export type BettingTournaments = {
    promoUrl: Scalars['String'];
    sponsors: Scalars['String'];
};

export type BettingTournamentsImpl = BettingTournaments & {
    __typename?: 'BettingTournamentsImpl';
    promoUrl: Scalars['String'];
    sponsors: Scalars['String'];
};

export type BonusBalance = {
    active: Scalars['Boolean'];
    balance: Scalars['Float'];
    balanceProgramTemplateId: Scalars['String'];
    brandId: Scalars['Int'];
    createdAt: Scalars['DateTime'];
    currency?: Maybe<Currency>;
    currencyCode?: Maybe<Scalars['String']>;
    currencyId?: Maybe<Scalars['Int']>;
    deletedAt?: Maybe<Scalars['DateTime']>;
    displayWager: Scalars['Boolean'];
    expiredAt: Scalars['DateTime'];
    features: Array<BonusBalanceFeature>;
    groups: Array<BonusBalanceGroup>;
    hasGroupName: Scalars['Boolean'];
    id: Scalars['Int'];
    isDeleted: Scalars['Boolean'];
    name: Scalars['String'];
    percentage: Scalars['Int'];
    playerId: Scalars['String'];
    rechargedRefundSum: Scalars['Float'];
    refundSum: Scalars['Float'];
    typeId: Scalars['Int'];
    wager: Scalars['Int'];
};

export type BonusBalanceActiveArgs = {
    groupName: Scalars['String'];
};

export type BonusBalanceHasGroupNameArgs = {
    groupName: Scalars['String'];
};

export type BonusBalanceFeature = {
    type: Scalars['String'];
};

export type BonusBalanceFeatureImpl = BonusBalanceFeature & {
    __typename?: 'BonusBalanceFeatureImpl';
    type: Scalars['String'];
};

export type BonusBalanceFeatureMaxTransfer = BonusBalanceFeature & {
    __typename?: 'BonusBalanceFeatureMaxTransfer';
    amount: Scalars['Decimal'];
    type: Scalars['String'];
};

export type BonusBalanceGroup = {
    isActive: Scalars['Boolean'];
    name: Scalars['String'];
};

export type BonusBalanceGroupImpl = BonusBalanceGroup & {
    __typename?: 'BonusBalanceGroupImpl';
    isActive: Scalars['Boolean'];
    name: Scalars['String'];
};

export type BonusBalanceImpl = BonusBalance & {
    __typename?: 'BonusBalanceImpl';
    active: Scalars['Boolean'];
    balance: Scalars['Float'];
    balanceProgramTemplateId: Scalars['String'];
    brandId: Scalars['Int'];
    createdAt: Scalars['DateTime'];
    currency?: Maybe<Currency>;
    currencyCode?: Maybe<Scalars['String']>;
    currencyId?: Maybe<Scalars['Int']>;
    deletedAt?: Maybe<Scalars['DateTime']>;
    displayWager: Scalars['Boolean'];
    expiredAt: Scalars['DateTime'];
    features: Array<BonusBalanceFeature>;
    groups: Array<BonusBalanceGroup>;
    hasGroupName: Scalars['Boolean'];
    id: Scalars['Int'];
    isDeleted: Scalars['Boolean'];
    name: Scalars['String'];
    percentage: Scalars['Int'];
    playerId: Scalars['String'];
    rechargedRefundSum: Scalars['Float'];
    refundSum: Scalars['Float'];
    typeId: Scalars['Int'];
    wager: Scalars['Int'];
};

export type BonusBalanceImplActiveArgs = {
    groupName: Scalars['String'];
};

export type BonusBalanceImplHasGroupNameArgs = {
    groupName: Scalars['String'];
};

export type BonusCompletedCountConditionRule = ConditionRuleInterface & {
    __typename?: 'BonusCompletedCountConditionRule';
    countEvents: Scalars['Int'];
    type: ConditionRuleEnumType;
};

export type BonusProgramData = {
    createdAt: Scalars['DateTime'];
    description: Scalars['String'];
    id: Scalars['String'];
    name: Scalars['String'];
    taskConnections: Array<TaskConnection>;
    taskSettings: TaskSettingsCollection;
};

export type BonusProgramDataBetTaskData = {
    __typename?: 'BonusProgramDataBetTaskData';
    bets: Array<Scalars['Decimal']>;
    id: Scalars['String'];
    status: BonusProgramDataTaskDataStatus;
    taskType: Scalars['String'];
};

export type BonusProgramDataBettingTaskData = {
    __typename?: 'BonusProgramDataBettingTaskData';
    bettingBets: Array<BonusProgramDataBettingTaskDataBet>;
    id: Scalars['String'];
    status: BonusProgramDataTaskDataStatus;
    taskType: Scalars['String'];
};

export type BonusProgramDataBettingTaskDataBet = {
    betAmount?: Maybe<Scalars['Decimal']>;
    id: Scalars['String'];
    winAmount?: Maybe<Scalars['Decimal']>;
};

export type BonusProgramDataBettingTaskDataBetImpl =
    BonusProgramDataBettingTaskDataBet & {
        __typename?: 'BonusProgramDataBettingTaskDataBetImpl';
        betAmount?: Maybe<Scalars['Decimal']>;
        id: Scalars['String'];
        winAmount?: Maybe<Scalars['Decimal']>;
    };

export type BonusProgramDataDepositTaskData = {
    __typename?: 'BonusProgramDataDepositTaskData';
    deposits: Array<Scalars['Decimal']>;
    id: Scalars['String'];
    status: BonusProgramDataTaskDataStatus;
    taskType: Scalars['String'];
};

export type BonusProgramDataImpl = BonusProgramData & {
    __typename?: 'BonusProgramDataImpl';
    createdAt: Scalars['DateTime'];
    description: Scalars['String'];
    id: Scalars['String'];
    name: Scalars['String'];
    taskConnections: Array<TaskConnection>;
    taskSettings: TaskSettingsCollection;
};

export type BonusProgramDataManualTaskData = {
    __typename?: 'BonusProgramDataManualTaskData';
    amount?: Maybe<Scalars['Float']>;
    coefficient?: Maybe<Scalars['Float']>;
    deltaSlot?: Maybe<Scalars['Float']>;
    deltaTrans?: Maybe<Scalars['Float']>;
    hasTrans?: Maybe<Scalars['Boolean']>;
    id: Scalars['String'];
    minSlot?: Maybe<Scalars['Float']>;
    minTrans?: Maybe<Scalars['Float']>;
    status: BonusProgramDataTaskDataStatus;
    taskType: Scalars['String'];
};

export type BonusProgramDataTask = {
    available: Scalars['Boolean'];
    completed: Scalars['Boolean'];
    data: UnionBonusProgramDataWinTaskDataBonusProgramDataBetTaskDataBonusProgramDataBettingTaskDataBonusProgramDataDepositTaskDataBonusProgramDataManualTaskDataBonusProgramDataTaskData;
    settings: TaskSettingsCollection;
    timeLineInfo: Array<TimeLine>;
};

export type BonusProgramDataTaskData = {
    __typename?: 'BonusProgramDataTaskData';
    id: Scalars['String'];
    status: BonusProgramDataTaskDataStatus;
    taskType: Scalars['String'];
};

export type BonusProgramDataTaskDataStatus = {
    type: Scalars['String'];
};

export type BonusProgramDataTaskDataStatusImpl =
    BonusProgramDataTaskDataStatus & {
        __typename?: 'BonusProgramDataTaskDataStatusImpl';
        type: Scalars['String'];
    };

export type BonusProgramDataTaskImpl = BonusProgramDataTask & {
    __typename?: 'BonusProgramDataTaskImpl';
    available: Scalars['Boolean'];
    completed: Scalars['Boolean'];
    data: UnionBonusProgramDataWinTaskDataBonusProgramDataBetTaskDataBonusProgramDataBettingTaskDataBonusProgramDataDepositTaskDataBonusProgramDataManualTaskDataBonusProgramDataTaskData;
    settings: TaskSettingsCollection;
    timeLineInfo: Array<TimeLine>;
};

export type BonusProgramDataWinTaskData = {
    __typename?: 'BonusProgramDataWinTaskData';
    id: Scalars['String'];
    status: BonusProgramDataTaskDataStatus;
    taskType: Scalars['String'];
    wins: Array<Scalars['Decimal']>;
};

export type BonusProgramFull = {
    __typename?: 'BonusProgramFull';
    availableProgramData?: Maybe<BonusProgramData>;
    bonusProgramData: BonusProgramData;
    processingProgramData?: Maybe<ProcessingBonusProgramData>;
    programTemplate: BonusProgramLocalized;
    type: Scalars['String'];
};

export type BonusProgramImage = {
    imageId: Scalars['String'];
    type: Scalars['String'];
};

export type BonusProgramImageImpl = BonusProgramImage & {
    __typename?: 'BonusProgramImageImpl';
    imageId: Scalars['String'];
    type: Scalars['String'];
};

export type BonusProgramListFilterInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    category?: InputMaybe<Scalars['String']>;
    types?: Array<BonusTypeEnum>;
};

export type BonusProgramLocalized = {
    __typename?: 'BonusProgramLocalized';
    availabilityConditions: Array<AvailabilityCondition>;
    backgroundColor: Scalars['String'];
    bonusPercent: Scalars['String'];
    cashierImageId: Scalars['String'];
    category: Scalars['String'];
    customField: Scalars['String'];
    customLabel: Scalars['String'];
    dataMaxTransfer: Array<Scalars['String']>;
    dataMinDeposit: Array<Scalars['String']>;
    defaultLocale: Scalars['String'];
    depositButtons: Array<Scalars['String']>;
    description: Scalars['String'];
    disabled: Scalars['Boolean'];
    displayIn: Array<Scalars['String']>;
    id: Scalars['String'];
    imageId: Scalars['String'];
    images: Array<BonusProgramImage>;
    internalId: Scalars['String'];
    isPublic: Scalars['Boolean'];
    label: Scalars['String'];
    layout: Scalars['String'];
    link: Scalars['String'];
    locale: Scalars['String'];
    maxBonus: Scalars['String'];
    minDeposit: Scalars['String'];
    name: Scalars['String'];
    news?: Maybe<NewsLocalized>;
    pageId: Scalars['String'];
    platformDescription: Scalars['String'];
    promotion?: Maybe<PromotionLocalized>;
    promotionId: Scalars['String'];
    quest: Scalars['Boolean'];
    rewardBonus: Scalars['String'];
    rewards: Array<RewardLocalized>;
    taskTypes: Array<TaskTypeLocalized>;
    title: Scalars['String'];
};

export enum BonusTypeEnum {
    Archived = 'ARCHIVED',
    Available = 'AVAILABLE',
    Processing = 'PROCESSING',
}

export type BreadcrumbItemResult = {
    __typename?: 'BreadcrumbItemResult';
    name: Scalars['String'];
    url: Scalars['String'];
};

export type BroadcastMessageLocalized = {
    __typename?: 'BroadcastMessageLocalized';
    content: Scalars['String'];
    endDate: Scalars['DateTime'];
    id: Scalars['String'];
    locale: Scalars['String'];
    name: Scalars['String'];
    startDate: Scalars['DateTime'];
    title: Scalars['String'];
};

export enum CaptchaContext {
    Any = 'ANY',
    Login = 'LOGIN',
    Recovery = 'RECOVERY',
    Registration = 'REGISTRATION',
    Verification = 'VERIFICATION',
}

export type CaptchaOptionsImpl = CaptchaOptionsInterface & {
    __typename?: 'CaptchaOptionsImpl';
    /**
     * Method was added because of type validation.
     * due to gql spec: An Interface type must define one or more fields.
     */
    dummy?: Maybe<Scalars['Int']>;
};

export type CaptchaOptionsInterface = {
    /**
     * Method was added because of type validation.
     * due to gql spec: An Interface type must define one or more fields.
     */
    dummy?: Maybe<Scalars['Int']>;
};

export type CaptchaTokenInput = {
    refCode?: InputMaybe<Scalars['String']>;
};

export type CaptchaType = {
    __typename?: 'CaptchaType';
    /**
     * Token from this object must be sent by the "X-Captcha-Context-Token" header in the requests for which it was requested.
     * If this field returns null, ignore the specified header.
     */
    contextToken?: Maybe<ContextToken>;
    options: CaptchaOptionsInterface;
    shouldShow: Scalars['Boolean'];
};

export type CashbackCoefficient = {
    balanceAmount: Scalars['String'];
    balanceId: Scalars['Int'];
    bonus: Scalars['String'];
    cashback: Scalars['String'];
    cashbackCalculated: Scalars['String'];
    coefficient: Scalars['String'];
    currencyCode: Scalars['String'];
    deposit: Scalars['String'];
    id: Scalars['String'];
    loyaltyStatusName: Scalars['String'];
    week: Scalars['Int'];
    withdrawal: Scalars['String'];
    year: Scalars['Int'];
};

export type CashbackCoefficientImpl = CashbackCoefficient & {
    __typename?: 'CashbackCoefficientImpl';
    balanceAmount: Scalars['String'];
    balanceId: Scalars['Int'];
    bonus: Scalars['String'];
    cashback: Scalars['String'];
    cashbackCalculated: Scalars['String'];
    coefficient: Scalars['String'];
    currencyCode: Scalars['String'];
    deposit: Scalars['String'];
    id: Scalars['String'];
    loyaltyStatusName: Scalars['String'];
    week: Scalars['Int'];
    withdrawal: Scalars['String'];
    year: Scalars['Int'];
};

export type Category = {
    __typename?: 'Category';
    games: Array<Game>;
    gamesCount: Scalars['Int'];
    id: Scalars['String'];
    imageId: Scalars['String'];
    images: Array<Image>;
    internalId: Scalars['String'];
    locale: Scalars['String'];
    slug: Scalars['String'];
    title: Scalars['String'];
    url: Scalars['String'];
};

export type CategoryGamesArgs = {
    limit?: InputMaybe<Scalars['Int']>;
};

export type ChangePasswordInput = {
    newPassword: Scalars['String'];
    newPasswordConfirm: Scalars['String'];
    oldPassword?: InputMaybe<Scalars['String']>;
};

export type Chat = {
    type: ChatWidgetTypeEnum;
};

export type ChatImpl = Chat & {
    __typename?: 'ChatImpl';
    type: ChatWidgetTypeEnum;
};

export enum ChatWidgetTypeEnum {
    Yhelper = 'YHELPER',
}

export type Clarification = {
    commission: Scalars['Decimal'];
    percent: Scalars['Decimal'];
    type: Scalars['String'];
};

export type ClarificationImpl = Clarification & {
    __typename?: 'ClarificationImpl';
    commission: Scalars['Decimal'];
    percent: Scalars['Decimal'];
    type: Scalars['String'];
};

export type CompositeTaskSettings = {
    id: Scalars['String'];
    operation: Scalars['String'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
    tasks: Array<Scalars['String']>;
};

export type CompositeTaskSettingsImpl = CompositeTaskSettings & {
    __typename?: 'CompositeTaskSettingsImpl';
    id: Scalars['String'];
    operation: Scalars['String'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
    tasks: Array<Scalars['String']>;
};

export type ConditionRule = ConditionRuleInterface & {
    __typename?: 'ConditionRule';
    type: ConditionRuleEnumType;
};

export enum ConditionRuleEnumType {
    BetCoefficientGreaterThan = 'BET_COEFFICIENT_GREATER_THAN',
    BonusCompletedCount = 'BONUS_COMPLETED_COUNT',
    DepositAmountGreaterThan = 'DEPOSIT_AMOUNT_GREATER_THAN',
    EverydayLoginCount = 'EVERYDAY_LOGIN_COUNT',
    KycStatus = 'KYC_STATUS',
    Logical = 'LOGICAL',
    LoyaltyProgramLevel = 'LOYALTY_PROGRAM_LEVEL',
    PlayerList = 'PLAYER_LIST',
    PlaySlotCount = 'PLAY_SLOT_COUNT',
    PlaySpinCount = 'PLAY_SPIN_COUNT',
    ProfileUpdates = 'PROFILE_UPDATES',
    Relational = 'RELATIONAL',
    SearchQuery = 'SEARCH_QUERY',
    TournamentsPlace = 'TOURNAMENTS_PLACE',
    TypeAcceptBetCount = 'TYPE_ACCEPT_BET_COUNT',
    TypeSportBetCount = 'TYPE_SPORT_BET_COUNT',
}

export type ConditionRuleInterface = {
    type: ConditionRuleEnumType;
};

export type ConfirmEmailTaskSettings = {
    alias: Scalars['String'];
    id: Scalars['String'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
};

export type ConfirmEmailTaskSettingsImpl = ConfirmEmailTaskSettings & {
    __typename?: 'ConfirmEmailTaskSettingsImpl';
    alias: Scalars['String'];
    id: Scalars['String'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
};

export type ConfirmPhoneTaskSettings = {
    alias: Scalars['String'];
    id: Scalars['String'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
};

export type ConfirmPhoneTaskSettingsImpl = ConfirmPhoneTaskSettings & {
    __typename?: 'ConfirmPhoneTaskSettingsImpl';
    alias: Scalars['String'];
    id: Scalars['String'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
};

export type ContextToken = {
    __typename?: 'ContextToken';
    token: Scalars['String'];
};

export type CountEventsPlayerAchievementProgress =
    PlayerAchievementProgressInterface & {
        __typename?: 'CountEventsPlayerAchievementProgress';
        /** Retrieves the type of condition rule for the specified player's achievement. */
        conditionType: ConditionRuleEnumType;
        /**
         * Retrieves the current numeric value for the specified player's achievement progress.
         * The current value represents the progress made towards the achievement,
         * while the value for opening the achievement signifies the milestone that must be reached.
         */
        currentValue: Scalars['Int'];
        /** Retrieves the type of progress for the specified player's achievement. */
        type: PlayerAchievementProgressEnumType;
        /** Retrieves the numeric value required to achieve the milestone for the specified player's achievement. */
        valueForOpen: Scalars['Int'];
    };

export type CountUniqueEventsPlayerAchievementProgress =
    PlayerAchievementProgressInterface & {
        __typename?: 'CountUniqueEventsPlayerAchievementProgress';
        /** Retrieves the type of condition rule for the specified player's achievement. */
        conditionType: ConditionRuleEnumType;
        /**
         * Retrieves the current numeric value for the specified player's achievement progress.
         * The current value represents the progress made towards the achievement,
         * while the value for opening the achievement signifies the milestone that must be reached.
         */
        currentValue: Scalars['Int'];
        /** Retrieves the type of progress for the specified player's achievement. */
        type: PlayerAchievementProgressEnumType;
        uniqueEvents: Array<Scalars['String']>;
        /** Retrieves the numeric value required to achieve the milestone for the specified player's achievement. */
        valueForOpen: Scalars['Int'];
    };

export type Country = {
    alpha2: Scalars['String'];
    alpha3: Scalars['String'];
    id: Scalars['Int'];
    name: Scalars['String'];
};

export type CountryImpl = Country & {
    __typename?: 'CountryImpl';
    alpha2: Scalars['String'];
    alpha3: Scalars['String'];
    id: Scalars['Int'];
    name: Scalars['String'];
};

export type CreateSimpleCredentialsInput = {
    login: Scalars['String'];
    password: Scalars['String'];
};

export type CredentialInterfaceInput = {
    type: Scalars['String'];
    value: Scalars['String'];
};

export type CredentialsCollectionInterfaceInput = {
    credentials: Array<CredentialInterfaceInput>;
    password: Scalars['String'];
};

export type CrmNotificationTrackerInput = {
    crmCode: Scalars['String'];
};

export type CryptoWalletAddressResponse = {
    address?: Maybe<Scalars['String']>;
};

export type CryptoWalletAddressResponseImpl = CryptoWalletAddressResponse & {
    __typename?: 'CryptoWalletAddressResponseImpl';
    address?: Maybe<Scalars['String']>;
};

export type Currency = {
    code: Scalars['String'];
    id: Scalars['Int'];
    isoCode: Scalars['String'];
    symbol: Scalars['String'];
    title: Scalars['String'];
};

export type CurrencyImpl = Currency & {
    __typename?: 'CurrencyImpl';
    code: Scalars['String'];
    id: Scalars['Int'];
    isoCode: Scalars['String'];
    symbol: Scalars['String'];
    title: Scalars['String'];
};

export type DataState = {
    createdAt?: Maybe<Scalars['DateTime']>;
    finished: Scalars['Boolean'];
    finishedAt?: Maybe<Scalars['DateTime']>;
    new: Scalars['Boolean'];
    type: Scalars['String'];
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DataStateImpl = DataState & {
    __typename?: 'DataStateImpl';
    createdAt?: Maybe<Scalars['DateTime']>;
    finished: Scalars['Boolean'];
    finishedAt?: Maybe<Scalars['DateTime']>;
    new: Scalars['Boolean'];
    type: Scalars['String'];
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DataTransaction = {
    brand: Scalars['Int'];
    kind: Scalars['String'];
    local: Scalars['String'];
    type: Scalars['String'];
};

export type DataTransactionImpl = DataTransaction & {
    __typename?: 'DataTransactionImpl';
    brand: Scalars['Int'];
    kind: Scalars['String'];
    local: Scalars['String'];
    type: Scalars['String'];
};

export type DecodedBanner = {
    buttonHref: Scalars['String'];
    buttonText: Scalars['String'];
    id: Scalars['String'];
    imageHref: Scalars['String'];
    imageSrc: Scalars['String'];
    subtitle: Scalars['String'];
    title: Scalars['String'];
};

export type DecodedBannerImpl = DecodedBanner & {
    __typename?: 'DecodedBannerImpl';
    buttonHref: Scalars['String'];
    buttonText: Scalars['String'];
    id: Scalars['String'];
    imageHref: Scalars['String'];
    imageSrc: Scalars['String'];
    subtitle: Scalars['String'];
    title: Scalars['String'];
};

export type DepositAmountGreaterThanConditionRule = ConditionRuleInterface & {
    __typename?: 'DepositAmountGreaterThanConditionRule';
    amount: Scalars['Int'];
    currencyCode: Scalars['String'];
    onlyOnce: Scalars['Boolean'];
    type: ConditionRuleEnumType;
};

export type DepositLinkInput = {
    amount: Scalars['Decimal'];
    paymentMethodId: Scalars['String'];
    recommendAmount?: InputMaybe<Scalars['Float']>;
    recommendMethod?: InputMaybe<Scalars['String']>;
    redirectUrls?: InputMaybe<RedirectUrlsInput>;
    requisitesPhone?: InputMaybe<Scalars['String']>;
    transactionCurrencyCode?: InputMaybe<Scalars['String']>;
};

export type DepositMethodsType = {
    __typename?: 'DepositMethodsType';
    depositMethods: Array<PaymentOption>;
    playerBalance: PaymentPlayerBalancesInfoDto;
};

export type DepositPaymentStatistic = {
    __typename?: 'DepositPaymentStatistic';
    amount: Scalars['Decimal'];
    count: Scalars['Int'];
    currencyCode: Scalars['String'];
};

export type DepositPlayerPaymentStatistics = {
    __typename?: 'DepositPlayerPaymentStatistics';
    finished: DepositPaymentStatistic;
    net: DepositPaymentStatistic;
    new: DepositPaymentStatistic;
    potential: DepositPaymentStatistic;
};

export type DepositTaskSettings = {
    alias: Scalars['String'];
    amountPerDeposit: Array<SettingsCurrencyMap>;
    checkCompleteStrategy: Scalars['String'];
    depositCount: Scalars['Int'];
    id: Scalars['String'];
    onlyFirst: Scalars['Boolean'];
    rewards: Array<TaskSettingsRewards>;
    skipOnFirstFail: Scalars['Boolean'];
    taskType: Scalars['String'];
    totalDepositAmount: Array<SettingsCurrencyMap>;
    unique: Scalars['Boolean'];
};

export type DepositTaskSettingsImpl = DepositTaskSettings & {
    __typename?: 'DepositTaskSettingsImpl';
    alias: Scalars['String'];
    amountPerDeposit: Array<SettingsCurrencyMap>;
    checkCompleteStrategy: Scalars['String'];
    depositCount: Scalars['Int'];
    id: Scalars['String'];
    onlyFirst: Scalars['Boolean'];
    rewards: Array<TaskSettingsRewards>;
    skipOnFirstFail: Scalars['Boolean'];
    taskType: Scalars['String'];
    totalDepositAmount: Array<SettingsCurrencyMap>;
    unique: Scalars['Boolean'];
};

export type DiiaDeeplink = {
    requestId: Scalars['String'];
    url: Scalars['String'];
};

export type DiiaDeeplinkImpl = DiiaDeeplink & {
    __typename?: 'DiiaDeeplinkImpl';
    requestId: Scalars['String'];
    url: Scalars['String'];
};

export enum DiiaRequestStatusGraphqlEnum {
    AuthInProgress = 'AUTH_IN_PROGRESS',
    AuthSuccess = 'AUTH_SUCCESS',
    ShareInProgress = 'SHARE_IN_PROGRESS',
    ShareSuccess = 'SHARE_SUCCESS',
    Unknown = 'UNKNOWN',
}

export type DiiaRequestStatusResultType = {
    __typename?: 'DiiaRequestStatusResultType';
    /** Returns whether current country/region is blocked */
    status: DiiaRequestStatusGraphqlEnum;
};

export type Document = {
    /** @deprecated CMS doesn't deal with different brand ids by design */
    brandId: Scalars['Int'];
    createdAt: Scalars['DateTime'];
    description?: Maybe<Scalars['String']>;
    fileMetadata: DocumentFileMetadata;
    id: Scalars['String'];
    /**
     * Relative URL to the document image contents (not a full one - should be merged with project domain)
     * Example: "/kyc/65b386a4-4441-4116-a667-7418620a4d36"
     */
    imageUrl: Scalars['String'];
    /** Example: "image/jpeg" */
    mimeType: Scalars['String'];
    /** @deprecated kyc-bundle doesn't deal with different player's documents by design */
    playerId: Scalars['Int'];
    processedAt?: Maybe<Scalars['DateTime']>;
    /**
     * Internal Gin Platform Image URL
     * @deprecated Should not be used at all
     */
    resourceUrl: Scalars['String'];
    source: DocumentSource;
    status: Scalars['String'];
    type: Scalars['String'];
};

export type DocumentCreateInputTypeInput = {
    description?: InputMaybe<Scalars['String']>;
    file: Scalars['Upload'];
    type: Scalars['String'];
};

export type DocumentCreateType = {
    __typename?: 'DocumentCreateType';
    document: Document;
};

export type DocumentFileMetadata = {
    __typename?: 'DocumentFileMetadata';
    /** Example: "img_002.jpg". */
    fileNameOriginal: Scalars['String'];
    /**
     * Document filesize in kilobytes (KB).
     * Example: "29089" (e.g. 29KB)
     */
    size: Scalars['Int'];
};

export type DocumentGetType = {
    __typename?: 'DocumentGetType';
    document: Document;
};

export type DocumentImpl = Document & {
    __typename?: 'DocumentImpl';
    /** @deprecated CMS doesn't deal with different brand ids by design */
    brandId: Scalars['Int'];
    createdAt: Scalars['DateTime'];
    description?: Maybe<Scalars['String']>;
    fileMetadata: DocumentFileMetadata;
    id: Scalars['String'];
    /**
     * Relative URL to the document image contents (not a full one - should be merged with project domain)
     * Example: "/kyc/65b386a4-4441-4116-a667-7418620a4d36"
     */
    imageUrl: Scalars['String'];
    /** Example: "image/jpeg" */
    mimeType: Scalars['String'];
    /** @deprecated kyc-bundle doesn't deal with different player's documents by design */
    playerId: Scalars['Int'];
    processedAt?: Maybe<Scalars['DateTime']>;
    /**
     * Internal Gin Platform Image URL
     * @deprecated Should not be used at all
     */
    resourceUrl: Scalars['String'];
    source: DocumentSource;
    status: Scalars['String'];
    type: Scalars['String'];
};

export type DocumentListType = {
    __typename?: 'DocumentListType';
    documents: Array<Document>;
};

export type DocumentSource = {
    __typename?: 'DocumentSource';
    /**
     * Shows who initiated the upload of the document
     * Examples: "player", "user", "diia", "kycaid".
     */
    id: Scalars['String'];
    type: DocumentSourceTypeEnum;
};

export enum DocumentSourceTypeEnum {
    Player = 'PLAYER',
    System = 'SYSTEM',
    Unknown = 'UNKNOWN',
    User = 'USER',
}

export type DummyTaskSettings = {
    id: Scalars['String'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
};

export type DummyTaskSettingsImpl = DummyTaskSettings & {
    __typename?: 'DummyTaskSettingsImpl';
    id: Scalars['String'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
};

export type Event = {
    brandId: Scalars['Int'];
    confirmedAt?: Maybe<Scalars['DateTime']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    data?: Maybe<EventData>;
    firstRequestedAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['String'];
    lastRequestedAt?: Maybe<Scalars['DateTime']>;
    playerId: Scalars['String'];
    type: Scalars['String'];
};

export type EventData = {
    amount?: Maybe<Scalars['String']>;
    convertedAmount?: Maybe<Scalars['String']>;
    currency?: Maybe<Scalars['String']>;
    eventId?: Maybe<Scalars['String']>;
    state?: Maybe<DataState>;
    status?: Maybe<Scalars['String']>;
    transactionId?: Maybe<DataTransaction>;
};

export type EventDataImpl = EventData & {
    __typename?: 'EventDataImpl';
    amount?: Maybe<Scalars['String']>;
    convertedAmount?: Maybe<Scalars['String']>;
    currency?: Maybe<Scalars['String']>;
    eventId?: Maybe<Scalars['String']>;
    state?: Maybe<DataState>;
    status?: Maybe<Scalars['String']>;
    transactionId?: Maybe<DataTransaction>;
};

export type EventImpl = Event & {
    __typename?: 'EventImpl';
    brandId: Scalars['Int'];
    confirmedAt?: Maybe<Scalars['DateTime']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    data?: Maybe<EventData>;
    firstRequestedAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['String'];
    lastRequestedAt?: Maybe<Scalars['DateTime']>;
    playerId: Scalars['String'];
    type: Scalars['String'];
};

export type EverydayEventsPlayerAchievementProgress =
    PlayerAchievementProgressInterface & {
        __typename?: 'EverydayEventsPlayerAchievementProgress';
        /** Retrieves the type of condition rule for the specified player's achievement. */
        conditionType: ConditionRuleEnumType;
        /**
         * Retrieves the current numeric value for the specified player's achievement progress.
         * The current value represents the progress made towards the achievement,
         * while the value for opening the achievement signifies the milestone that must be reached.
         */
        currentValue: Scalars['Int'];
        lastProgressDay?: Maybe<Scalars['DateTime']>;
        /** Retrieves the type of progress for the specified player's achievement. */
        type: PlayerAchievementProgressEnumType;
        /** Retrieves the numeric value required to achieve the milestone for the specified player's achievement. */
        valueForOpen: Scalars['Int'];
    };

export type EverydayLoginCountConditionRule = ConditionRuleInterface & {
    __typename?: 'EverydayLoginCountConditionRule';
    countDays: Scalars['Int'];
    type: ConditionRuleEnumType;
};

export type FeatureAvailableResult = {
    __typename?: 'FeatureAvailableResult';
    available: Scalars['Boolean'];
};

export type FiltersInputTypeInput = {
    authToken?: InputMaybe<Scalars['String']>;
    containedBalanceType?: InputMaybe<Scalars['String']>;
    currencyCode?: InputMaybe<Scalars['String']>;
    gameId?: InputMaybe<Scalars['String']>;
    providerId?: InputMaybe<Scalars['String']>;
    roundId?: InputMaybe<Scalars['String']>;
    roundType?: InputMaybe<Scalars['String']>;
    sessionToken?: InputMaybe<Scalars['String']>;
    totalAmountMax?: InputMaybe<Scalars['Decimal']>;
    totalAmountMin?: InputMaybe<Scalars['Decimal']>;
    type?: InputMaybe<Scalars['String']>;
};

export type FirstRequiredDepositFlowType = {
    __typename?: 'FirstRequiredDepositFlowType';
    /** First deposit flow name */
    firstRequiredDepositFlow?: Maybe<Scalars['String']>;
    /**
     * Paseto auth token.
     * The result includes the paseto token because it may change after checking the flow
     * This is due to the fact that flow data is stored in the token
     *
     * Null - if it is not changed or not a paseto token was used for authentication
     */
    paseto?: Maybe<Scalars['String']>;
};

export type FreeGamesTimeToLive = {
    __typename?: 'FreeGamesTimeToLive';
    expiredAt: Scalars['DateTime'];
    quantity: Scalars['Int'];
};

export type FreeSpin = {
    expiresAt?: Maybe<Scalars['DateTime']>;
    gameIds: Array<Scalars['String']>;
    games: Array<Game>;
    spinsNumber: Scalars['Int'];
    used: Scalars['Boolean'];
};

export type FreeSpinImpl = FreeSpin & {
    __typename?: 'FreeSpinImpl';
    expiresAt?: Maybe<Scalars['DateTime']>;
    gameIds: Array<Scalars['String']>;
    games: Array<Game>;
    spinsNumber: Scalars['Int'];
    used: Scalars['Boolean'];
};

export type FrontendGqlLocalizedTheme = {
    __typename?: 'FrontendGqlLocalizedTheme';
    backgroundColor?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    imageId?: Maybe<Scalars['String']>;
    images: Array<Image>;
    slug: Scalars['String'];
    title: Scalars['String'];
    url?: Maybe<Scalars['String']>;
};

export type FrontendServiceResult = {
    /** @deprecated Use `logs` instead */
    getLogs: Array<FrontendServiceResultLog>;
    /** @deprecated Use `type` instead */
    getType: Scalars['String'];
    logs: Array<FrontendServiceResultLog>;
    /** Example values: "terms", "rules" */
    type: Scalars['String'];
};

export type FrontendServiceResultImpl = FrontendServiceResult & {
    __typename?: 'FrontendServiceResultImpl';
    /** @deprecated Use `logs` instead */
    getLogs: Array<FrontendServiceResultLog>;
    /** @deprecated Use `type` instead */
    getType: Scalars['String'];
    logs: Array<FrontendServiceResultLog>;
    /** Example values: "terms", "rules" */
    type: Scalars['String'];
};

export type FrontendServiceResultLog = {
    /** @deprecated Use `id` instead */
    getId: Scalars['String'];
    /** @deprecated Use `log` instead */
    getLog: Scalars['String'];
    /** @deprecated Use `version` instead */
    getVersion: Scalars['String'];
    /** Identifier of change */
    id: Scalars['String'];
    /** Text for user which describes necessary changes in current version */
    log: Scalars['String'];
    /** Version identifier of change (in context of specific rule) */
    version: Scalars['String'];
};

export type FrontendServiceResultLogImpl = FrontendServiceResultLog & {
    __typename?: 'FrontendServiceResultLogImpl';
    /** @deprecated Use `id` instead */
    getId: Scalars['String'];
    /** @deprecated Use `log` instead */
    getLog: Scalars['String'];
    /** @deprecated Use `version` instead */
    getVersion: Scalars['String'];
    /** Identifier of change */
    id: Scalars['String'];
    /** Text for user which describes necessary changes in current version */
    log: Scalars['String'];
    /** Version identifier of change (in context of specific rule) */
    version: Scalars['String'];
};

export type Game = {
    __typename?: 'Game';
    backgroundImageId: Scalars['String'];
    balanceGroupName: Scalars['String'];
    categories: Array<Category>;
    /** @deprecated Use field `supportDemo` instead */
    demoAvailable: Scalars['Boolean'];
    /**
     * This field use heavy request
     * Use to get single game and only when you really need it
     */
    demoPlayData: PlayData;
    /**
     * This field use heavy request
     * Use to get single game and only when you really need it
     */
    demoPlayLink: Scalars['String'];
    demoUrl: Scalars['String'];
    description: Scalars['String'];
    enabled: Scalars['Boolean'];
    favorite: Scalars['Boolean'];
    /** External ID, without entity prefix. Use `internalId` field for internal searching in graphql */
    id: Scalars['String'];
    imageId: Scalars['String'];
    images: Array<Image>;
    internalId: Scalars['String'];
    labels: Array<LocalizedLabel>;
    licenses: Array<Scalars['String']>;
    locale: Scalars['String'];
    new: Scalars['Boolean'];
    /**
     * This field use heavy request
     * Use to get single game and only when you really need it
     */
    playData: PlayData;
    /**
     * This field use heavy request
     * Use to get single game and only when you really need it
     */
    playLink: Scalars['String'];
    playUsingBonusBalanceEnabled: Scalars['Boolean'];
    providerId: Scalars['String'];
    providerName: Scalars['String'];
    returnToPlayer: Scalars['String'];
    slug: Scalars['String'];
    supportDemo: Scalars['Boolean'];
    title: Scalars['String'];
    types: Array<GameTypeEnum>;
    url: Scalars['String'];
    vendor?: Maybe<GameVendor>;
    vendorId: Scalars['String'];
    vendorName: Scalars['String'];
    volatilityLevel: Scalars['Int'];
    wageringPercent: Scalars['Int'];
};

export type GameActualMetadata = {
    __typename?: 'GameActualMetadata';
    backgroundImage: Scalars['String'];
    enabled: Scalars['Boolean'];
    id: Scalars['String'];
    image: Scalars['String'];
    name: Scalars['String'];
};

export type GameApi = {
    demoPath: Scalars['String'];
    id: Scalars['String'];
    imageId: Scalars['String'];
    name: Scalars['String'];
    new: Scalars['Boolean'];
    path: Scalars['String'];
    providerId: Scalars['String'];
    slug: Scalars['String'];
    title: Scalars['String'];
    vendorId: Scalars['String'];
};

export type GameApiImpl = GameApi & {
    __typename?: 'GameApiImpl';
    demoPath: Scalars['String'];
    id: Scalars['String'];
    imageId: Scalars['String'];
    name: Scalars['String'];
    new: Scalars['Boolean'];
    path: Scalars['String'];
    providerId: Scalars['String'];
    slug: Scalars['String'];
    title: Scalars['String'];
    vendorId: Scalars['String'];
};

export type GameJournalEntriesListType = {
    __typename?: 'GameJournalEntriesListType';
    entries: Array<GameJournalEntry>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type GameJournalEntry = {
    actionId: Scalars['String'];
    authToken: Scalars['String'];
    balances: Array<GameJournalEntryBalance>;
    bonusAmount: Scalars['Decimal'];
    bonusAmountInUsd: Scalars['Decimal'];
    brandId: Scalars['Int'];
    createdAt: Scalars['DateTime'];
    currencyCode: Scalars['String'];
    game?: Maybe<Game>;
    gameId: Scalars['String'];
    gameName: Scalars['String'];
    gameTypes: Array<Scalars['String']>;
    id: Scalars['String'];
    license: Scalars['String'];
    platform: Scalars['String'];
    playerId: Scalars['String'];
    providerGameName: Scalars['String'];
    providerId: Scalars['String'];
    realAmount: Scalars['Decimal'];
    realAmountInUsd: Scalars['Decimal'];
    roundId: Scalars['String'];
    roundType: Scalars['String'];
    sessionToken: Scalars['String'];
    totalAmount: Scalars['Decimal'];
    totalAmountInUsd: Scalars['Decimal'];
    type: Scalars['String'];
    vendorId: Scalars['String'];
};

export type GameJournalEntryBalance = {
    afterAmount: Scalars['Decimal'];
    afterWagerAmount: Scalars['Decimal'];
    balanceId: Scalars['Int'];
    balanceTypeId: Scalars['Int'];
    beforeAmount: Scalars['Decimal'];
    beforeWagerAmount: Scalars['Decimal'];
    deltaAmount: Scalars['Decimal'];
    deltaWagerAmount: Scalars['Decimal'];
};

export type GameJournalEntryBalanceImpl = GameJournalEntryBalance & {
    __typename?: 'GameJournalEntryBalanceImpl';
    afterAmount: Scalars['Decimal'];
    afterWagerAmount: Scalars['Decimal'];
    balanceId: Scalars['Int'];
    balanceTypeId: Scalars['Int'];
    beforeAmount: Scalars['Decimal'];
    beforeWagerAmount: Scalars['Decimal'];
    deltaAmount: Scalars['Decimal'];
    deltaWagerAmount: Scalars['Decimal'];
};

export type GameJournalEntryImpl = GameJournalEntry & {
    __typename?: 'GameJournalEntryImpl';
    actionId: Scalars['String'];
    authToken: Scalars['String'];
    balances: Array<GameJournalEntryBalance>;
    bonusAmount: Scalars['Decimal'];
    bonusAmountInUsd: Scalars['Decimal'];
    brandId: Scalars['Int'];
    createdAt: Scalars['DateTime'];
    currencyCode: Scalars['String'];
    game?: Maybe<Game>;
    gameId: Scalars['String'];
    gameName: Scalars['String'];
    gameTypes: Array<Scalars['String']>;
    id: Scalars['String'];
    license: Scalars['String'];
    platform: Scalars['String'];
    playerId: Scalars['String'];
    providerGameName: Scalars['String'];
    providerId: Scalars['String'];
    realAmount: Scalars['Decimal'];
    realAmountInUsd: Scalars['Decimal'];
    roundId: Scalars['String'];
    roundType: Scalars['String'];
    sessionToken: Scalars['String'];
    totalAmount: Scalars['Decimal'];
    totalAmountInUsd: Scalars['Decimal'];
    type: Scalars['String'];
    vendorId: Scalars['String'];
};

export type GameJournalRound = {
    entries: Array<GameJournalEntry>;
};

export type GameJournalRoundImpl = GameJournalRound & {
    __typename?: 'GameJournalRoundImpl';
    entries: Array<GameJournalEntry>;
};

export type GameJournalRoundsListType = {
    __typename?: 'GameJournalRoundsListType';
    rounds: Array<GameJournalRound>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type GameLocalizedPlatformedRoutingRelatedEntity =
    RoutingRelatedEntityInfo & {
        __typename?: 'GameLocalizedPlatformedRoutingRelatedEntity';
        entity: Game;
        entityType: Scalars['String'];
        id: Scalars['String'];
    };

export enum GameSearchStrategyEnum {
    ByNgrams = 'BY_NGRAMS',
    ByPrefix = 'BY_PREFIX',
}

export type GameSizeOptions = {
    maxWidth?: Maybe<Scalars['Int']>;
    minHeight?: Maybe<Scalars['Int']>;
    minWidth?: Maybe<Scalars['Int']>;
};

export type GameSizeOptionsImpl = GameSizeOptions & {
    __typename?: 'GameSizeOptionsImpl';
    maxWidth?: Maybe<Scalars['Int']>;
    minHeight?: Maybe<Scalars['Int']>;
    minWidth?: Maybe<Scalars['Int']>;
};

export enum GameStatisticsSortKeyEnum {
    BetCount = 'BET_COUNT',
    RealBetSum = 'REAL_BET_SUM',
    TotalBetSum = 'TOTAL_BET_SUM',
}

export enum GameTypeEnum {
    AndarBahar = 'ANDAR_BAHAR',
    Arcade = 'ARCADE',
    Baccarat = 'BACCARAT',
    Bingo = 'BINGO',
    Blackjack = 'BLACKJACK',
    Card = 'CARD',
    Casual = 'CASUAL',
    CoinFlipping = 'COIN_FLIPPING',
    Craps = 'CRAPS',
    Crash = 'CRASH',
    Dice = 'DICE',
    DragonTiger = 'DRAGON_TIGER',
    Fishing = 'FISHING',
    Fortune = 'FORTUNE',
    HigherLower = 'HIGHER_LOWER',
    Holdem = 'HOLDEM',
    Instant = 'INSTANT',
    Jackpot = 'JACKPOT',
    Keno = 'KENO',
    Live = 'LIVE',
    Lottery = 'LOTTERY',
    Mines = 'MINES',
    Plinko = 'PLINKO',
    Poker = 'POKER',
    Progressive = 'PROGRESSIVE',
    Roulette = 'ROULETTE',
    ScratchCard = 'SCRATCH_CARD',
    Shooting = 'SHOOTING',
    SicBo = 'SIC_BO',
    Slots = 'SLOTS',
    SnakesAndLadders = 'SNAKES_AND_LADDERS',
    Solitaier = 'SOLITAIER',
    Table = 'TABLE',
    ThreeD = 'THREE_D',
    Unknown = 'UNKNOWN',
    Video = 'VIDEO',
    VirtualFootball = 'VIRTUAL_FOOTBALL',
    VirtualGreyhounds = 'VIRTUAL_GREYHOUNDS',
    VirtualHorses = 'VIRTUAL_HORSES',
    VirtualSports = 'VIRTUAL_SPORTS',
    VirtualTennis = 'VIRTUAL_TENNIS',
    WheelOfFortune = 'WHEEL_OF_FORTUNE',
}

export type GameVendor = {
    __typename?: 'GameVendor';
    category: Scalars['String'];
    id: Scalars['String'];
    imageId: Scalars['String'];
    images: Array<Image>;
    name: Scalars['String'];
    vendors: Array<Scalars['String']>;
};

export type GamesList = {
    count: Scalars['Int'];
    games: Array<Game>;
    searchParams?: Maybe<GamesSearchParams>;
};

export type GamesListImpl = GamesList & {
    __typename?: 'GamesListImpl';
    count: Scalars['Int'];
    games: Array<Game>;
    searchParams?: Maybe<GamesSearchParams>;
};

export type GamesSearchParams = {
    sortedByCountryIsoCode?: Maybe<Scalars['String']>;
};

export type GamesSearchParamsImpl = GamesSearchParams & {
    __typename?: 'GamesSearchParamsImpl';
    sortedByCountryIsoCode?: Maybe<Scalars['String']>;
};

export type GamificationPlayerStatistics = {
    __typename?: 'GamificationPlayerStatistics';
    casino?: Maybe<GamificationPlayerStatisticsCasino>;
    profileInfo?: Maybe<GamificationPlayerStatisticsProfileInfo>;
    sport?: Maybe<GamificationPlayerStatisticsSport>;
};

export type GamificationPlayerStatisticsCasino = {
    __typename?: 'GamificationPlayerStatisticsCasino';
    averageBet: Scalars['Decimal'];
    biggestWin: Scalars['Decimal'];
    biggestWinToSlot: Scalars['Decimal'];
    currencyCode: Scalars['String'];
    favouriteSlot?: Maybe<Scalars['String']>;
};

export type GamificationPlayerStatisticsProfileInfo = {
    __typename?: 'GamificationPlayerStatisticsProfileInfo';
    avatar: Scalars['String'];
    league: Scalars['String'];
    name: Scalars['String'];
};

export type GamificationPlayerStatisticsSport = {
    __typename?: 'GamificationPlayerStatisticsSport';
    betsCount: Scalars['Int'];
    betsExpressCount: Scalars['Int'];
    betsExpressOrdinar: Array<BetsExpressOrdinar>;
    betsLiveCount: Scalars['Int'];
    betsLivePrematch: Array<BetsLivePrematch>;
    betsOrdinaryCount: Scalars['Int'];
    betsPrematchCount: Scalars['Int'];
    expressRatio?: Maybe<Scalars['Decimal']>;
    ordinarRatio?: Maybe<Scalars['Decimal']>;
    sportWithMostBet?: Maybe<Scalars['String']>;
};

export type GamificationPlayerStatisticsToken = {
    __typename?: 'GamificationPlayerStatisticsToken';
    token: Scalars['String'];
};

export type GeoIpCountry = {
    isoCode: Scalars['String'];
};

export type GeoIpCountryImpl = GeoIpCountry & {
    __typename?: 'GeoIpCountryImpl';
    isoCode: Scalars['String'];
};

export type GeoIpLocationType = {
    __typename?: 'GeoIpLocationType';
    /**
     * Do not rely on this method as a source of truth.
     * This method does not guarantee correct data transfer and can only be used for autofilling input.
     * Returns a value only in EN language
     */
    cityName?: Maybe<Scalars['String']>;
    countryIsoCode?: Maybe<Scalars['String']>;
};

export type GinDecimal = {
    value: Scalars['String'];
};

export type GinDecimalImpl = GinDecimal & {
    __typename?: 'GinDecimalImpl';
    value: Scalars['String'];
};

export type HallOfFameResultType = {
    __typename?: 'HallOfFameResultType';
    actualMetadata: Array<GameActualMetadata>;
    winners: Array<BestPlayerData>;
};

export type HideContacts = {
    __typename?: 'HideContacts';
    email: Scalars['String'];
    phone: Scalars['String'];
};

export type HideContactsResultType = {
    __typename?: 'HideContactsResultType';
    result?: Maybe<HideContacts>;
};

export type HtmlLanguageResultType = {
    __typename?: 'HtmlLanguageResultType';
    lang: Scalars['String'];
    locale: Scalars['String'];
};

export type IdGovUaIntegrationServiceCallbackType = {
    __typename?: 'IdGovUaIntegrationServiceCallbackType';
    errorMessage: Scalars['String'];
};

export type IdGovUaIntegrationServiceGenerateLinkResult = {
    link: Scalars['String'];
};

export type IdGovUaIntegrationServiceGenerateLinkResultImpl =
    IdGovUaIntegrationServiceGenerateLinkResult & {
        __typename?: 'IdGovUaIntegrationServiceGenerateLinkResultImpl';
        link: Scalars['String'];
    };

export type IframeLink = PaymentLink & {
    __typename?: 'IframeLink';
    height: Scalars['Int'];
    type: Scalars['String'];
    url: Scalars['String'];
    width: Scalars['Int'];
};

export type Image = {
    imageId?: Maybe<Scalars['String']>;
    type: Scalars['String'];
};

export type ImageImpl = Image & {
    __typename?: 'ImageImpl';
    imageId?: Maybe<Scalars['String']>;
    type: Scalars['String'];
};

export type InfoResultType = {
    __typename?: 'InfoResultType';
    events: Array<Event>;
    stats: PlayerStatistics;
};

export type Interval = {
    __typename?: 'Interval';
    d: Scalars['Int'];
    days?: Maybe<Scalars['Int']>;
    f: Scalars['Float'];
    format: Scalars['String'];
    h: Scalars['Int'];
    i: Scalars['Int'];
    invert: Scalars['Int'];
    m: Scalars['Int'];
    s: Scalars['Int'];
    y: Scalars['Int'];
};

export type IntervalFormatArgs = {
    format: Scalars['String'];
};

export type InviteFriendsReferralInfoType = {
    __typename?: 'InviteFriendsReferralInfoType';
    referralCount: Scalars['Int'];
    referralLink: Scalars['String'];
};

export type KeyValueType = {
    __typename?: 'KeyValueType';
    value: Scalars['String'];
};

export type KycApproveTaskSettings = {
    alias: Scalars['String'];
    id: Scalars['String'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
};

export type KycApproveTaskSettingsImpl = KycApproveTaskSettings & {
    __typename?: 'KycApproveTaskSettingsImpl';
    alias: Scalars['String'];
    id: Scalars['String'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
};

export enum KycJournalActionTypeEnum {
    AffConfirm = 'AFF_CONFIRM',
    AffDecline = 'AFF_DECLINE',
    DApprove = 'D_APPROVE',
    DCreate = 'D_CREATE',
    DDecline = 'D_DECLINE',
    DDelete = 'D_DELETE',
    DEdit = 'D_EDIT',
    IncreasedAffConfirm = 'INCREASED_AFF_CONFIRM',
    IncreasedAffDecline = 'INCREASED_AFF_DECLINE',
    KycApproveFull = 'KYC_APPROVE_FULL',
    KycApproveSimple = 'KYC_APPROVE_SIMPLE',
    KycAwaitingApproval = 'KYC_AWAITING_APPROVAL',
    KycDecline = 'KYC_DECLINE',
    KycUnknown = 'KYC_UNKNOWN',
}

export type KycJournalLog = {
    __typename?: 'KycJournalLog';
    actionType: KycJournalActionTypeEnum;
    comment: Scalars['String'];
    createdAt: Scalars['DateTime'];
    id: Scalars['String'];
    systemReasonCode: Scalars['String'];
};

export type KycType = {
    __typename?: 'KycType';
    affordabilityCheckStatus: Scalars['Boolean'];
    confirmStatus: Scalars['Boolean'];
    increasedAffordabilityCheckStatus: Scalars['Boolean'];
    journal: Array<KycJournalLog>;
    status: Scalars['String'];
};

export type KycaidSettingsType = {
    __typename?: 'KycaidSettingsType';
    enabledFor: Scalars['String'];
};

export type KycaidVerification = {
    __typename?: 'KycaidVerification';
    status: KycaidVerificationStatusGraphqlEnum;
    type: KycaidVerificationTypeGraphqlEnum;
    verificationId: Scalars['String'];
    verified: Scalars['Boolean'];
};

export type KycaidVerificationRequestResult = {
    __typename?: 'KycaidVerificationRequestResult';
    link: Scalars['String'];
};

export enum KycaidVerificationStatusGraphqlEnum {
    Completed = 'COMPLETED',
    Pending = 'PENDING',
    Unused = 'UNUSED',
}

export enum KycaidVerificationTypeEnum {
    BankId = 'BANK_ID',
    FormUrl = 'FORM_URL',
}

export enum KycaidVerificationTypeGraphqlEnum {
    BankId = 'BANK_ID',
    FormUrl = 'FORM_URL',
    Unknown = 'UNKNOWN',
}

export type KycaidVerificationsResult = {
    __typename?: 'KycaidVerificationsResult';
    hasCompletedVerification: Scalars['Boolean'];
    verifications: Array<KycaidVerification>;
};

export type LabelTypeField = {
    __typename?: 'LabelTypeField';
    key: Scalars['String'];
    value: Scalars['String'];
};

export type Limit = {
    canceledAt?: Maybe<Scalars['DateTime']>;
    createdAt: Scalars['DateTime'];
    finishAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['Int'];
    interval?: Maybe<Interval>;
    money?: Maybe<Array<LimitMoney>>;
    /**
     * Example of getting nested pending limits.
     * There are some difficulties with using recursion (it doesn't work in GQL).
     * Follow the example with the level of nesting you need.
     *
     * Note: It can be only one active player limit with defined type,
     * but we can have few created limits with different params and same type which are pending.
     * For example: the next one will be active after the end of the previous one by date.
     *
     * ```
     * fragment LimitNestedFieldsFragment on Limit {
     *  pending {
     *    ...LimitFieldsFragment
     *    pending {
     *      ...LimitFieldsFragment
     *      pending {
     *        ...LimitFieldsFragment
     *      }
     *    }
     *  }
     * }
     *
     * fragment LimitFieldsFragment on Limit {
     *  id
     *  type
     *  money {
     *    field
     *    value
     *  }
     *  startAt
     *  createdAt
     *  finishAt
     * }
     * ```
     */
    pending?: Maybe<Limit>;
    period?: Maybe<Scalars['String']>;
    startAt: Scalars['DateTime'];
    type: Scalars['String'];
};

export type LimitHistory = {
    brandId: Scalars['Int'];
    createdAt?: Maybe<Scalars['DateTime']>;
    finishAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['Int'];
    money?: Maybe<Array<LimitMoney>>;
    period?: Maybe<Scalars['String']>;
    playerId: Scalars['Int'];
    startAt?: Maybe<Scalars['DateTime']>;
    type: Scalars['String'];
};

export type LimitHistoryImpl = LimitHistory & {
    __typename?: 'LimitHistoryImpl';
    brandId: Scalars['Int'];
    createdAt?: Maybe<Scalars['DateTime']>;
    finishAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['Int'];
    money?: Maybe<Array<LimitMoney>>;
    period?: Maybe<Scalars['String']>;
    playerId: Scalars['Int'];
    startAt?: Maybe<Scalars['DateTime']>;
    type: Scalars['String'];
};

export type LimitImpl = Limit & {
    __typename?: 'LimitImpl';
    canceledAt?: Maybe<Scalars['DateTime']>;
    createdAt: Scalars['DateTime'];
    finishAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['Int'];
    interval?: Maybe<Interval>;
    money?: Maybe<Array<LimitMoney>>;
    /**
     * Example of getting nested pending limits.
     * There are some difficulties with using recursion (it doesn't work in GQL).
     * Follow the example with the level of nesting you need.
     *
     * Note: It can be only one active player limit with defined type,
     * but we can have few created limits with different params and same type which are pending.
     * For example: the next one will be active after the end of the previous one by date.
     *
     * ```
     * fragment LimitNestedFieldsFragment on Limit {
     *  pending {
     *    ...LimitFieldsFragment
     *    pending {
     *      ...LimitFieldsFragment
     *      pending {
     *        ...LimitFieldsFragment
     *      }
     *    }
     *  }
     * }
     *
     * fragment LimitFieldsFragment on Limit {
     *  id
     *  type
     *  money {
     *    field
     *    value
     *  }
     *  startAt
     *  createdAt
     *  finishAt
     * }
     * ```
     */
    pending?: Maybe<Limit>;
    period?: Maybe<Scalars['String']>;
    startAt: Scalars['DateTime'];
    type: Scalars['String'];
};

export type LimitListResultType = {
    __typename?: 'LimitListResultType';
    hasNextPart: Scalars['Boolean'];
    list: Array<Limit>;
};

export type LimitMoney = {
    field: Scalars['String'];
    value: Scalars['String'];
};

export type LimitMoneyImpl = LimitMoney & {
    __typename?: 'LimitMoneyImpl';
    field: Scalars['String'];
    value: Scalars['String'];
};

export type LocalLink = PaymentLink & {
    __typename?: 'LocalLink';
    transactionId: PaymentTransactionIdDto;
    type: Scalars['String'];
    url: Scalars['String'];
};

export type LocaleSwitcherRedirectType = {
    __typename?: 'LocaleSwitcherRedirectType';
    locale: Scalars['String'];
    redirect: Scalars['String'];
};

export type LocaleSwitcherTitleType = {
    __typename?: 'LocaleSwitcherTitleType';
    locale: Scalars['String'];
    title: Scalars['String'];
};

export type LocaleSwitcherType = {
    __typename?: 'LocaleSwitcherType';
    redirects: Array<LocaleSwitcherRedirectType>;
    titles: Array<LocaleSwitcherTitleType>;
};

export type LocalizedAchievementEntity = {
    __typename?: 'LocalizedAchievementEntity';
    description: Scalars['String'];
    id: Scalars['String'];
    images: AchievementEntityImages;
    link: Scalars['String'];
    /** Return json string with data which were standardized by localization json schema */
    localizationJsonSchemaData: Scalars['String'];
    title: Scalars['String'];
};

export type LocalizedLabel = {
    __typename?: 'LocalizedLabel';
    enabled: Scalars['Boolean'];
    name: Scalars['String'];
    text: Scalars['String'];
    type: Scalars['String'];
    typeValues: Array<LabelTypeField>;
};

export type LocalizedMenu = {
    id: Scalars['String'];
    items: Array<LocalizedMenuItem>;
    locale: Scalars['String'];
};

export type LocalizedMenuImpl = LocalizedMenu & {
    __typename?: 'LocalizedMenuImpl';
    id: Scalars['String'];
    items: Array<LocalizedMenuItem>;
    locale: Scalars['String'];
};

export type LocalizedMenuItem = {
    anchor: Scalars['String'];
    children: Array<LocalizedMenuItem>;
    expanded: Scalars['Boolean'];
    imageId: Scalars['String'];
    status: Scalars['String'];
    url: Scalars['String'];
};

export type LocalizedMenuItemImpl = LocalizedMenuItem & {
    __typename?: 'LocalizedMenuItemImpl';
    anchor: Scalars['String'];
    children: Array<LocalizedMenuItem>;
    expanded: Scalars['Boolean'];
    imageId: Scalars['String'];
    status: Scalars['String'];
    url: Scalars['String'];
};

export type LocalizedStructuredPage = {
    __typename?: 'LocalizedStructuredPage';
    /** Return json string with data which were standardized by common json schema */
    commonJsonSchemaData: Scalars['String'];
    commonJsonSchemaName: Scalars['String'];
    id: Scalars['String'];
    /** Return json string with data which were standardized by localization json schema */
    localizationJsonSchemaData: Scalars['String'];
    localizationJsonSchemaName: Scalars['String'];
    name: Scalars['String'];
};

export type Login4playDataForAnonymousType = {
    __typename?: 'Login4playDataForAnonymousType';
    auth4playSettings: Auth4PlaySettingsData;
    login4playData: Scalars['String'];
    /**
     * Returns the configured host for login4play.
     * Example: "login4play.com"
     */
    login4playHost: Scalars['String'];
    socialNetworks: Array<Scalars['String']>;
};

export type Login4playDataForRegistered = {
    __typename?: 'Login4playDataForRegistered';
    activeCount: Scalars['Int'];
    canDetach: Scalars['Boolean'];
    detachType: Scalars['String'];
    login4playData: Scalars['String'];
    /**
     * Method returns the host for login4play which is taken from the settings specified in the admin
     * Example: "login4play.com"
     */
    login4playHost: Scalars['String'];
    socialButtons: Array<SocialButton>;
    socialNetworks: Array<Scalars['String']>;
};

export type Login4playResponse = {
    email: Scalars['String'];
    phone: Scalars['String'];
};

export type Login4playResponseImpl = Login4playResponse & {
    __typename?: 'Login4playResponseImpl';
    email: Scalars['String'];
    phone: Scalars['String'];
};

export type LogoutResultType = {
    __typename?: 'LogoutResultType';
    /** Enum type with possible values - ["success", "failed"]. Depends on the success of the logout on the platform */
    result: Scalars['String'];
};

export type LoyaltyPointsMultiplier = {
    endTime: Scalars['DateTime'];
    gameIds: Array<Scalars['String']>;
    id: Scalars['String'];
    startTime: Scalars['DateTime'];
    value: Scalars['Decimal'];
};

export type LoyaltyPointsMultiplierImpl = LoyaltyPointsMultiplier & {
    __typename?: 'LoyaltyPointsMultiplierImpl';
    endTime: Scalars['DateTime'];
    gameIds: Array<Scalars['String']>;
    id: Scalars['String'];
    startTime: Scalars['DateTime'];
    value: Scalars['Decimal'];
};

export type LoyaltyProgramConfigurationCashback = {
    __typename?: 'LoyaltyProgramConfigurationCashback';
    coefficient: Scalars['Decimal'];
    statusName: Scalars['String'];
};

export type LoyaltyProgramConfigurationExchangeRate = {
    __typename?: 'LoyaltyProgramConfigurationExchangeRate';
    currencyCode: Scalars['String'];
    /** Currency conversion rates */
    exchangeRate: Scalars['Decimal'];
    statusName: Scalars['String'];
};

export type LoyaltyProgramConfigurationLevel = {
    __typename?: 'LoyaltyProgramConfigurationLevel';
    level: Scalars['Int'];
    /** Transition to the next level (Level) depends on accumulated experience (Experience) */
    requiredExp: Scalars['Decimal'];
    statusName: Scalars['String'];
};

export type LoyaltyProgramConfigurationStatusName = {
    __typename?: 'LoyaltyProgramConfigurationStatusName';
    name: Scalars['String'];
};

export type LoyaltyProgramConfigurations = {
    __typename?: 'LoyaltyProgramConfigurations';
    cashbackList: Array<LoyaltyProgramConfigurationCashback>;
    exchangeRateList: Array<LoyaltyProgramConfigurationExchangeRate>;
    levelList: Array<LoyaltyProgramConfigurationLevel>;
    statusNameList: Array<LoyaltyProgramConfigurationStatusName>;
};

export type LoyaltyProgramConfigurationsResultType = {
    __typename?: 'LoyaltyProgramConfigurationsResultType';
    configurations: LoyaltyProgramConfigurations;
};

export type LoyaltyProgramExchangeRate = {
    currencyIsoAlpha3: Scalars['String'];
    rate: Scalars['Decimal'];
};

export type LoyaltyProgramExchangeRateImpl = LoyaltyProgramExchangeRate & {
    __typename?: 'LoyaltyProgramExchangeRateImpl';
    currencyIsoAlpha3: Scalars['String'];
    rate: Scalars['Decimal'];
};

export type LoyaltyProgramLevel = {
    level: Scalars['Int'];
    parentStatus: LoyaltyProgramStatus;
    requiredExp: Scalars['Decimal'];
    rewards: Array<LoyaltyProgramReward>;
};

export type LoyaltyProgramLevelImpl = LoyaltyProgramLevel & {
    __typename?: 'LoyaltyProgramLevelImpl';
    level: Scalars['Int'];
    parentStatus: LoyaltyProgramStatus;
    requiredExp: Scalars['Decimal'];
    rewards: Array<LoyaltyProgramReward>;
};

export type LoyaltyProgramParticipant = {
    avatar?: Maybe<Scalars['String']>;
    displayName?: Maybe<Scalars['String']>;
    experience: Scalars['String'];
    level: Scalars['Int'];
    playerId: Scalars['String'];
    position: Scalars['Int'];
    statusName: Scalars['String'];
};

export type LoyaltyProgramParticipantImpl = LoyaltyProgramParticipant & {
    __typename?: 'LoyaltyProgramParticipantImpl';
    avatar?: Maybe<Scalars['String']>;
    displayName?: Maybe<Scalars['String']>;
    experience: Scalars['String'];
    level: Scalars['Int'];
    playerId: Scalars['String'];
    position: Scalars['Int'];
    statusName: Scalars['String'];
};

export type LoyaltyProgramPlayerData = {
    experience: Scalars['Decimal'];
    level: LoyaltyProgramLevel;
    loyaltyPoints: Scalars['Decimal'];
    points: Array<LoyaltyProgramPlayerDataPoint>;
    updatedAt: Scalars['DateTime'];
};

export type LoyaltyProgramPlayerDataImpl = LoyaltyProgramPlayerData & {
    __typename?: 'LoyaltyProgramPlayerDataImpl';
    experience: Scalars['Decimal'];
    level: LoyaltyProgramLevel;
    loyaltyPoints: Scalars['Decimal'];
    points: Array<LoyaltyProgramPlayerDataPoint>;
    updatedAt: Scalars['DateTime'];
};

export type LoyaltyProgramPlayerDataPoint = {
    amount: Scalars['Decimal'];
    type: Scalars['String'];
};

export type LoyaltyProgramPlayerDataPointImpl =
    LoyaltyProgramPlayerDataPoint & {
        __typename?: 'LoyaltyProgramPlayerDataPointImpl';
        amount: Scalars['Decimal'];
        type: Scalars['String'];
    };

export type LoyaltyProgramReward = {
    amount: Scalars['Decimal'];
    currencyIsoAlpha3: Scalars['String'];
};

export type LoyaltyProgramRewardImpl = LoyaltyProgramReward & {
    __typename?: 'LoyaltyProgramRewardImpl';
    amount: Scalars['Decimal'];
    currencyIsoAlpha3: Scalars['String'];
};

export type LoyaltyProgramSettings = {
    exchangePointsList: Array<LoyaltyProgramSettingsExchangePoint>;
    gambleRatesList: Array<LoyaltyProgramSettingsGambleRate>;
    pointTypeList: Array<Scalars['String']>;
};

export type LoyaltyProgramSettingsExchangePoint = {
    minAmount: Scalars['Decimal'];
    pointType: Scalars['String'];
    targetBalance: LoyaltyProgramSettingsExchangePointTargetBalance;
};

export type LoyaltyProgramSettingsExchangePointImpl =
    LoyaltyProgramSettingsExchangePoint & {
        __typename?: 'LoyaltyProgramSettingsExchangePointImpl';
        minAmount: Scalars['Decimal'];
        pointType: Scalars['String'];
        targetBalance: LoyaltyProgramSettingsExchangePointTargetBalance;
    };

export type LoyaltyProgramSettingsExchangePointTargetBalance = {
    groupNames: Array<Scalars['String']>;
    type: Scalars['String'];
    wagerMultiplier: Scalars['Decimal'];
};

export type LoyaltyProgramSettingsExchangePointTargetBalanceImpl =
    LoyaltyProgramSettingsExchangePointTargetBalance & {
        __typename?: 'LoyaltyProgramSettingsExchangePointTargetBalanceImpl';
        groupNames: Array<Scalars['String']>;
        type: Scalars['String'];
        wagerMultiplier: Scalars['Decimal'];
    };

export type LoyaltyProgramSettingsGambleRate = {
    distribution: Array<LoyaltyProgramSettingsGambleRateDistribution>;
    experienceRates: Array<LoyaltyProgramSettingsGambleRateRate>;
    gambleType: Scalars['String'];
    pointRates: Array<LoyaltyProgramSettingsGambleRateRate>;
};

export type LoyaltyProgramSettingsGambleRateDistribution = {
    pointType: Scalars['String'];
    weight: Scalars['Int'];
};

export type LoyaltyProgramSettingsGambleRateDistributionImpl =
    LoyaltyProgramSettingsGambleRateDistribution & {
        __typename?: 'LoyaltyProgramSettingsGambleRateDistributionImpl';
        pointType: Scalars['String'];
        weight: Scalars['Int'];
    };

export type LoyaltyProgramSettingsGambleRateImpl =
    LoyaltyProgramSettingsGambleRate & {
        __typename?: 'LoyaltyProgramSettingsGambleRateImpl';
        distribution: Array<LoyaltyProgramSettingsGambleRateDistribution>;
        experienceRates: Array<LoyaltyProgramSettingsGambleRateRate>;
        gambleType: Scalars['String'];
        pointRates: Array<LoyaltyProgramSettingsGambleRateRate>;
    };

export type LoyaltyProgramSettingsGambleRateRate = {
    amount: Scalars['Decimal'];
    currencyCode: Scalars['String'];
};

export type LoyaltyProgramSettingsGambleRateRateImpl =
    LoyaltyProgramSettingsGambleRateRate & {
        __typename?: 'LoyaltyProgramSettingsGambleRateRateImpl';
        amount: Scalars['Decimal'];
        currencyCode: Scalars['String'];
    };

export type LoyaltyProgramSettingsImpl = LoyaltyProgramSettings & {
    __typename?: 'LoyaltyProgramSettingsImpl';
    exchangePointsList: Array<LoyaltyProgramSettingsExchangePoint>;
    gambleRatesList: Array<LoyaltyProgramSettingsGambleRate>;
    pointTypeList: Array<Scalars['String']>;
};

export type LoyaltyProgramStatus = {
    exchangeRates: Array<LoyaltyProgramExchangeRate>;
    levels: Array<LoyaltyProgramLevel>;
    name: Scalars['String'];
    startLevel?: Maybe<LoyaltyProgramLevel>;
};

export type LoyaltyProgramStatusImpl = LoyaltyProgramStatus & {
    __typename?: 'LoyaltyProgramStatusImpl';
    exchangeRates: Array<LoyaltyProgramExchangeRate>;
    levels: Array<LoyaltyProgramLevel>;
    name: Scalars['String'];
    startLevel?: Maybe<LoyaltyProgramLevel>;
};

export type LoyaltyProgramTotalData = {
    currentLevel: LoyaltyProgramLevel;
    exchangeRate: LoyaltyProgramExchangeRate;
    experience: Scalars['Decimal'];
    loyaltyPoints: Scalars['Decimal'];
    nextLevel: LoyaltyProgramLevel;
    percentage: Scalars['Decimal'];
    status: LoyaltyProgramStatus;
};

export type LoyaltyProgramTotalDataImpl = LoyaltyProgramTotalData & {
    __typename?: 'LoyaltyProgramTotalDataImpl';
    currentLevel: LoyaltyProgramLevel;
    exchangeRate: LoyaltyProgramExchangeRate;
    experience: Scalars['Decimal'];
    loyaltyPoints: Scalars['Decimal'];
    nextLevel: LoyaltyProgramLevel;
    percentage: Scalars['Decimal'];
    status: LoyaltyProgramStatus;
};

export type LuckySpinWithBetCoefficientApiType = {
    __typename?: 'LuckySpinWithBetCoefficientApiType';
    bet: Scalars['String'];
    betCoefficient: Scalars['String'];
    currencyCode: Scalars['String'];
    date: Scalars['DateTime'];
    /** @deprecated Use `getGameLocalized` field instead */
    game: GameApi;
    gameLocalized?: Maybe<Game>;
    roundActionCount: Scalars['Int'];
    roundId: Scalars['String'];
    win: Scalars['String'];
};

export type LuckySpinWithRoundProfitApiType = {
    __typename?: 'LuckySpinWithRoundProfitApiType';
    bet: Scalars['String'];
    currencyCode: Scalars['String'];
    date: Scalars['DateTime'];
    /** @deprecated Use `getGameLocalized` field instead */
    game: GameApi;
    gameLocalized?: Maybe<Game>;
    roundActionCount: Scalars['Int'];
    roundId: Scalars['String'];
    roundProfit: Scalars['String'];
    win: Scalars['String'];
};

export type ManualTaskSettings = {
    checkCompleteStrategy: Scalars['String'];
    id: Scalars['String'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
};

export type ManualTaskSettingsImpl = ManualTaskSettings & {
    __typename?: 'ManualTaskSettingsImpl';
    checkCompleteStrategy: Scalars['String'];
    id: Scalars['String'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
};

export type Message = {
    campaignId: Scalars['String'];
    content: Scalars['String'];
    createdAt: Scalars['DateTime'];
    id: Scalars['String'];
    isRead: Scalars['Boolean'];
    media?: Maybe<MessageMedia>;
    preview: Scalars['String'];
    priority: Scalars['String'];
    systemType: Scalars['String'];
    title: Scalars['String'];
};

export type MessageImpl = Message & {
    __typename?: 'MessageImpl';
    campaignId: Scalars['String'];
    content: Scalars['String'];
    createdAt: Scalars['DateTime'];
    id: Scalars['String'];
    isRead: Scalars['Boolean'];
    media?: Maybe<MessageMedia>;
    preview: Scalars['String'];
    priority: Scalars['String'];
    systemType: Scalars['String'];
    title: Scalars['String'];
};

export type MessageMedia = {
    buttons: Array<MessageMediaButton>;
    mainImage: MessageMediaMainImage;
};

export type MessageMediaButton = {
    text: Scalars['String'];
    url: Scalars['String'];
};

export type MessageMediaButtonImpl = MessageMediaButton & {
    __typename?: 'MessageMediaButtonImpl';
    text: Scalars['String'];
    url: Scalars['String'];
};

export type MessageMediaImpl = MessageMedia & {
    __typename?: 'MessageMediaImpl';
    buttons: Array<MessageMediaButton>;
    mainImage: MessageMediaMainImage;
};

export type MessageMediaMainImage = {
    default: Scalars['String'];
    desktopWebsite: Scalars['String'];
    mobileAndroid: Scalars['String'];
    mobileIos: Scalars['String'];
    mobileWebsite: Scalars['String'];
};

export type MessageMediaMainImageImpl = MessageMediaMainImage & {
    __typename?: 'MessageMediaMainImageImpl';
    default: Scalars['String'];
    desktopWebsite: Scalars['String'];
    mobileAndroid: Scalars['String'];
    mobileIos: Scalars['String'];
    mobileWebsite: Scalars['String'];
};

export enum MessageTargetPlatformTypeEnum {
    DesktopWebsite = 'DESKTOP_WEBSITE',
    MobileAndroid = 'MOBILE_ANDROID',
    MobileIos = 'MOBILE_IOS',
    MobileWebsite = 'MOBILE_WEBSITE',
}

export type MessagesInfoResult = {
    message?: Maybe<Message>;
    position: Scalars['Int'];
    total: Scalars['Int'];
    unread: Scalars['Int'];
};

export type MessagesInfoResultImpl = MessagesInfoResult & {
    __typename?: 'MessagesInfoResultImpl';
    message?: Maybe<Message>;
    position: Scalars['Int'];
    total: Scalars['Int'];
    unread: Scalars['Int'];
};

export type Mirror = {
    WWWMode: Scalars['String'];
    allowedLocales: Array<Scalars['String']>;
    authorizedAnalyticsCode: Scalars['String'];
    defaultLocale: Scalars['String'];
    domain: Scalars['String'];
    htmlLanguages: Array<HtmlLanguageResultType>;
    mainAnalyticsCode: Scalars['String'];
    metaTags: Scalars['String'];
    mobileAnalyticsCode: Scalars['String'];
    schemeMode: Scalars['String'];
};

export type MirrorImpl = Mirror & {
    __typename?: 'MirrorImpl';
    WWWMode: Scalars['String'];
    allowedLocales: Array<Scalars['String']>;
    authorizedAnalyticsCode: Scalars['String'];
    defaultLocale: Scalars['String'];
    domain: Scalars['String'];
    htmlLanguages: Array<HtmlLanguageResultType>;
    mainAnalyticsCode: Scalars['String'];
    metaTags: Scalars['String'];
    mobileAnalyticsCode: Scalars['String'];
    schemeMode: Scalars['String'];
};

export type MobileAppSettingsInput = {
    enabled?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    name?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
    operationSystem: OperationSystem;
};

export type MobileCreateFirebaseTokenInput = {
    appName?: InputMaybe<Scalars['String']>;
    deviceId: Scalars['String'];
    refCode?: InputMaybe<Scalars['String']>;
    registrationToken: Scalars['String'];
    userAgent: Scalars['String'];
};

export type MobilePushNotificationChangeSubscriptionInput = {
    isSubscribed: Scalars['Boolean'];
};

export type MobilePushSubscriptionInfo = {
    __typename?: 'MobilePushSubscriptionInfo';
    createdAt: Scalars['DateTime'];
    deviceId: Scalars['String'];
    subscribed: Scalars['Boolean'];
    type: MobilePushSubscriptionInfoTypeEnumType;
};

export enum MobilePushSubscriptionInfoTypeEnumType {
    Fcm = 'FCM',
    Ios = 'IOS',
    Safari = 'SAFARI',
    Undefined = 'UNDEFINED',
    Webpush = 'WEBPUSH',
}

export type MobilePushSubscriptionsType = {
    __typename?: 'MobilePushSubscriptionsType';
    subscriptions: Array<MobilePushSubscriptionInfo>;
};

export type MobilePushTokenInput = {
    appName?: InputMaybe<Scalars['String']>;
    deviceId: Scalars['String'];
    refCode?: InputMaybe<Scalars['String']>;
    registrationToken: Scalars['String'];
    userAgent: Scalars['String'];
};

export type MobileSettings = {
    __typename?: 'MobileSettings';
    /**
     * Download mobile application URL or empty string.
     * Empty string returned if download condition for mob app is not met or locale disabled.
     */
    appLink: Scalars['String'];
    appRate?: Maybe<Scalars['Float']>;
    appVersion: Scalars['String'];
    bannedRefCodes: Array<Scalars['String']>;
    deepLink: Scalars['String'];
    /**
     * Whether download mobile application allowed by configured download condition.
     * If you don't use `appLink` field immediately, but need to know whether it's available - use this field.
     */
    downloadAllowed: Scalars['Boolean'];
    id: Scalars['String'];
    isEnabled: Scalars['Boolean'];
    name: Scalars['String'];
    operationSystem: OperationSystem;
    title: Scalars['String'];
};

export type MoneySumPlayerAchievementProgress =
    PlayerAchievementProgressInterface & {
        __typename?: 'MoneySumPlayerAchievementProgress';
        /** Retrieves the type of condition rule for the specified player's achievement. */
        conditionType: ConditionRuleEnumType;
        currency: Scalars['String'];
        /**
         * Retrieves the current numeric value for the specified player's achievement progress.
         * The current value represents the progress made towards the achievement,
         * while the value for opening the achievement signifies the milestone that must be reached.
         */
        currentValue: Scalars['Int'];
        /** Retrieves the type of progress for the specified player's achievement. */
        type: PlayerAchievementProgressEnumType;
        /** Retrieves the numeric value required to achieve the milestone for the specified player's achievement. */
        valueForOpen: Scalars['Int'];
    };

export type MultipleLink = PaymentLink & {
    __typename?: 'MultipleLink';
    transactionIds: Array<PaymentTransactionIdDto>;
    type: Scalars['String'];
    url: Scalars['String'];
};

export type Mutation = {
    __typename?: 'Mutation';
    /**
     * >> CMS module: TermsConditions
     *
     * Accept specified terms and conditions for authenticated player.
     * ```
     * mutation {
     *   acceptTermsAndConditions(
     *     input: {
     *       rules: [
     *         { type: "terms", versions: ["2"] }
     *       ]
     *     }) {
     *       message
     *     }
     *  }
     * ```
     */
    acceptTermsAndConditions: SuccessResult;
    /**
     * >> CMS module: Bonus
     *
     * Activates an available program in the system, allowing the user to initiate the execution of a specific action or task
     */
    activateAvailableBonusProgram: SuccessResult;
    /**
     * >> CMS module: Bonus
     *
     * Activates an available program in the system, allowing the user to initiate the execution of a specific action or task
     * @deprecated use `activateAvailableBonusProgram` mutation instead
     */
    activateAvalaibleBonusProgram: SuccessResult;
    /**
     * >> CMS module: Player
     *
     *
     */
    activateBonusBalance: SuccessResult;
    /**
     * >> CMS module: Bonus
     *
     *
     * @deprecated use `activateAvailableBonusProgram` mutation instead
     */
    activateBonusProgram: SuccessResult;
    /**
     * >> CMS module: Tournaments
     *
     *
     */
    addParticipant: SuccessResult;
    /**
     * >> CMS module: Games
     *
     *
     */
    addSearchGame: SuccessResult;
    /**
     * >> CMS module: Bonus
     *
     *
     */
    cancelBonusProgram: SuccessResult;
    /**
     * >> CMS module: Payment
     *
     *
     */
    cancelPayout: Scalars['Boolean'];
    /**
     * >> CMS module: Limits
     *
     * Cancel active player limit by limitId
     */
    cancelPlayerLimits: Scalars['Boolean'];
    /**
     * >> CMS module: Player
     *
     *
     * @deprecated use resetPassword mutation or updatePassword instead
     */
    changePassword: SuccessResult;
    /**
     * >> CMS module: Player
     *
     * Clean authentication credentials to the existed player account (currently authenticated).
     */
    cleanCredentials: SuccessResult;
    /**
     * >> CMS module: Profile
     *
     * Confirmation account with the code received on the user's email address
     * ```
     * mutation {
     *   confirmEmailVerification(
     *     email: "example@example.com"
     *     token: "abcdef"
     *   ) {
     *    message
     *   }
     * }
     * ```
     */
    confirmEmailVerification: SuccessResult;
    /**
     * >> CMS module: Profile
     *
     * Finish verification session for currently authenticated user's phone (e.g. with confirmation code from SMS).
     * See also mutation `startPhoneVerification`.
     */
    confirmPhoneVerification: SuccessResult;
    /**
     * >> CMS module: Player
     *
     * Send password recovery confirmation token - credentialsRecoveryByPhone send token in SMS to the phone
     */
    confirmRecoveryPassword: SignInResultType;
    /**
     * >> CMS module: Player
     *
     * Create simple authentication credentials with login/password pair to
     * the existed player account (currently authenticated).
     */
    createSimpleCredentials: SuccessResult;
    /**
     * >> CMS module: Player
     *
     *
     */
    credentialsRecoveryByEmail: SuccessResult;
    /**
     * >> CMS module: Player
     *
     *
     */
    credentialsRecoveryByPhone: SuccessResult;
    /**
     * >> CMS module: CrmNotificationTracker
     *
     * Sends CRM notification code to Platform for tracking.
     * It's recommended to reset information about code after calling mutation (even if there was an error like about
     * incorrect code).
     *
     * Requires full authentication.
     */
    crmNotificationTracker: SuccessResult;
    /**
     * >> CMS module: Player
     *
     *
     */
    cryptoWalletAddressAdd: Scalars['Boolean'];
    /**
     * >> CMS module: Player
     *
     *
     */
    cryptoWalletRequestReject: Scalars['Boolean'];
    /**
     * >> CMS module: Bonus
     *
     *
     */
    deleteBonusProgram: SuccessResult;
    /**
     * >> CMS module: Message
     *
     * Deleted specified user message. Returns success in case of deleting not existent message (idempotency).
     */
    deleteMessage: SuccessResult;
    /**
     * >> CMS module: Message
     *
     * Deleted all user messages
     */
    deleteMessages: SuccessResult;
    /**
     * >> CMS module: Payment
     *
     * Get link for deposit and how to open. To fill form fields in payment provider form you may set optional parameters.
     * Optionally you may provide `transactionCurrencyCode` to specify transaction currency which may differ from user balance
     * currency.
     *
     * If you don't want to control the deposit amount - please specify zero amount (`0.0`).
     *
     * ```
     * Example:
     *
     * mutation($deposit: DepositLinkInput!) {
     *   depositLink(deposit: $deposit) {
     *         type
     *         url
     *      }
     *   }
     *
     * Variables:
     *
     * {
     *   "deposit": {
     *      "paymentMethodId": "payment_method:s2p",
     *      "amount": "20.55",
     *      "transactionCurrencyCode": "usd",
     *      "recommendAmount": "1.2",
     *      "recommendMethod": "bank_cards"
     *    }
     *  }
     *
     * Variables (example 2):
     *
     * {
     *   "deposit": {
     *      "paymentMethodId": "payment_method:s2p",
     *      "amount": 0,
     *      "transactionCurrencyCode": "usd"
     *    }
     *  }
     * ```
     */
    depositLink: PaymentLink;
    /**
     * >> CMS module: KYC
     *
     * KYC documents
     */
    documentCreate: DocumentCreateType;
    /**
     * >> CMS module: KYC
     *
     * KYC documents
     */
    documentDelete: SuccessResult;
    /**
     * >> CMS module: Player
     *
     *
     */
    finishRecoveryPassword: SuccessResult;
    /**
     * >> CMS module: GameStatistics
     *
     *
     */
    gamificationPlayerStatisticsGenerateToken: GamificationPlayerStatisticsToken;
    /**
     * >> CMS module: GtmDataLayer
     *
     *
     */
    gtmDataConfirmEvent: SuccessResult;
    /**
     * >> CMS module: Player
     *
     *
     */
    idGovUaCallback: IdGovUaIntegrationServiceCallbackType;
    /**
     * >> CMS module: KYC
     *
     *
     */
    kycSetAwaitingApprovalStatus: SuccessResult;
    /**
     * >> CMS module: Player
     *
     *
     */
    logout: LogoutResultType;
    /**
     * >> CMS module: Loyalty
     *
     *
     */
    loyaltyProgramExchangePoints: SuccessResult;
    /**
     * >> CMS module: Achievement
     *
     * Mark the achievement as viewed by player
     */
    markAchievementAsViewed: SuccessResult;
    /**
     * >> CMS module: Achievement
     *
     * Mark all player achievements as viewed
     */
    markAllAchievementsAsViewed: SuccessResult;
    /**
     * >> CMS module: Message
     *
     *
     */
    markMessagesAsRead: SuccessResult;
    /**
     * >> CMS module: Message
     *
     *
     */
    messageEvent: SuccessResult;
    /**
     * >> CMS module: MobilePushNotification
     *
     * Creates an APN Token, on the Platform.
     * Requires full authentication.
     *
     * ```
     * Example:
     *
     * mutation {
     *     mobileCreateApnToken(apnTokenInput: {
     *         registrationToken: "test"
     *         deviceId: "test"
     *         userAgent: "test"
     *         refCode: "test"
     *         appName: "test"
     *     }){
     *         message
     *     }
     * }
     *
     * ```
     *
     * MobilePushTokenInput fields:
     *
     * deviceID: Unique device fingerprint.
     * registrationToken: Registration token.
     * userAgent: User agent - must be passed explicitly.
     * refCode: Referral code(Optional).
     * appName: Application name(Optional).
     */
    mobileCreateApnToken: SuccessResult;
    /**
     * >> CMS module: MobilePushNotification
     *
     * Creates a Firebase Token, on the Platform.
     * Requires full authentication.
     *
     * ```
     * Example:
     *
     * mutation {
     *     mobileCreateFirebaseToken(mobileCreateFirebaseTokenInput: {
     *         registrationToken: "test"
     *         deviceId: "test"
     *         userAgent: "test"
     *         refCode: "test"
     *         appName: "test"
     *     }){
     *         message
     *     }
     * }
     *
     * ```
     *
     * mobileCreateFirebaseTokenInput fields:
     *
     * deviceID: Unique device fingerprint.
     * registrationToken: Registration token.
     * userAgent: User agent - must be passed explicitly.
     * refCode: Referral code(Optional).
     * appName: Application name(Optional).
     */
    mobileCreateFirebaseToken: SuccessResult;
    /**
     * >> CMS module: MobilePushNotification
     *
     * Edits the subscription for push notifications to phones, on the Platform.
     * Requires full authentication.
     */
    mobilePushNotificationChangeSubscription: SuccessResult;
    /**
     * >> CMS module: Payment
     *
     * Get link for payout and how to open. To fill form fields in payout form you have to set withdrawMethodId and  amount.
     * Optionally you may provide `transactionCurrencyCode` to specify transaction currency which may differ from user balance
     *  currency.
     *
     * If you don't want to control the payout amount - please specify zero amount (`0.0`).
     *
     * ```
     * Example:
     *
     * mutation($payoutLink: PayoutLinkInput!) {
     *     payoutLink(payoutLink: $payoutLink) {
     *         type
     *         url
     *     }
     * }
     *
     * Variables:
     *
     * {
     *     payoutLink : {
     *         withdrawMethodId : 'payment_method:s2p',
     *         amount : 20,
     *         transactionCurrencyCode: 'usd'
     *     }
     * }
     *
     * Variables (example 2):
     *
     * {
     *     payoutLink : {
     *         withdrawMethodId : 'payment_method:s2p',
     *         amount : 0,
     *         transactionCurrencyCode: 'usd'
     *     }
     * }
     * ```
     */
    payoutLink: PaymentLink;
    /**
     * >> CMS module: Player
     *
     * Set player auth settings on platform
     */
    playerAuthSettings: SuccessResult;
    /**
     * >> CMS module: Player
     *
     * Use trackers input to track clicks on links. Defaults trackers: [landing_id, ref_code, uuid, crm_notification, actionpay, affdata].
     */
    reSignIn: ReSignInResultType;
    /**
     * >> CMS module: Player
     *
     * Use trackers input to track clicks on links. Defaults trackers: [landing_id, ref_code, uuid, crm_notification, actionpay, affdata].
     */
    recoverySignIn: SignInResultType;
    /**
     * >> CMS module: WebPushNotification
     *
     * Register the user to web push subscription in all browsers instead of safari.
     * ```
     * Example:
     *
     * mutation {
     *  registerWebPushSubscription(webPushSubscriptionInput:
     *  {
     *      endpoint: "/test-endpoint",
     *      p256dhKey: "test-key",
     *      authKey: "test-key2",
     *  }
     *  )
     *  {
     *      message
     *  }
     * }
     *
     * ```
     */
    registerWebPushSubscription: SuccessResult;
    /**
     * >> CMS module: KYC
     *
     * Change status of KYC required documents after uploading
     */
    requiredDocumentUploaded: SuccessResult;
    /**
     * >> CMS module: Player
     *
     * Discredit current user credentials and assign a new password for the user
     * or creates if there is no old one
     */
    resetPassword: SuccessResult;
    /**
     * >> CMS module: Player
     *
     *
     */
    resetPasswordByRecovery: SuccessResult;
    /**
     * >> CMS module: WebPushNotification
     *
     * Subscribes the user to web push notifications in Safari browser.
     * ```
     * Example:
     *
     * mutation {
     *  safariWebPushCreate(webPushCreateInput:
     *  {
     *      deviceToken: "exampleDeviceToken",
     *      domain: "exampleDomain.com"
     *  }
     *  )
     *  {
     *      message
     *  }
     * }
     *
     * ```
     */
    safariWebPushCreate: SuccessResult;
    /**
     * >> CMS module: SelfExclusion
     *
     *
     */
    selfExclusionLockPlayer: SuccessResult;
    /**
     * >> CMS module: Bonus
     *
     * Sets the active program in the system.
     * It enables the user to choose a specific program to continue execution if they have started executing multiple programs simultaneously
     */
    setActiveBonusProgram: SuccessResult;
    /**
     * >> CMS module: Bonus
     *
     *
     * @deprecated use `setActiveBonusProgram` mutation instead
     */
    setBonusProgramActive: SuccessResult;
    /**
     * >> CMS module: Games
     *
     *
     */
    setGamesFavorite: SuccessResult;
    /**
     * >> CMS module: Limits
     *
     * Create (setup) player limit with specified type.
     */
    setPlayerLimits: Scalars['Boolean'];
    /**
     * >> CMS module: Player
     *
     * Use trackers input to track clicks on links. Defaults trackers: [landing_id, ref_code, uuid, crm_notification, actionpay, affdata].
     */
    signIn: SignInResultType;
    /**
     * >> CMS module: Player
     *
     * Use trackers input to track clicks on links. Defaults trackers: [landing_id, ref_code, uuid, crm_notification, actionpay, affdata].
     */
    signInByAppleId: SignInResultType;
    /**
     * >> CMS module: Player
     *
     * Use trackers input to track clicks on links. Defaults trackers: [landing_id, ref_code, uuid, crm_notification, actionpay, affdata].
     */
    signInByToken: SignInResultType;
    /**
     * >> CMS module: Player
     *
     * Mutation to create new user.
     * If redeemCode is not valid registration will go on. You can validate this field using `checkRedeemCode` request
     */
    signUp: SignUpResultType;
    /**
     * >> CMS module: Promo
     *
     * Sign up by token that was generated by `promoRemoteToken` query
     */
    signUpByPromoRemoteToken: SignUpResultType;
    /**
     * >> CMS module: Player
     *
     *
     */
    signUpFinish: SignUpResultType;
    /**
     * >> CMS module: Login4play
     *
     *
     */
    socialAttach: SuccessResult;
    /**
     * >> CMS module: Login4play
     *
     *
     */
    socialDetach: SuccessResult;
    /**
     * >> CMS module: Login4play
     *
     * Use trackers input to track clicks on links. Defaults trackers: [landing_id, ref_code, uuid, crm_notification, actionpay, affdata].
     */
    socialLogin: SocialLoginResultType;
    /**
     * >> CMS module: Profile
     *
     * Sending email verification link to the user's email address.
     * Callback url is optional parameter. Use it if the task requires it.
     *
     * ```
     * mutation {
     *   startEmailVerification(
     *     startEmailVerification: {
     *       email: "example@example.com"
     *       callbackUrl: "https://example.com/callback-url" # optional
     *     }
     *   ) {
     *    message
     *   }
     * }
     * ```
     */
    startEmailVerification: SuccessResult;
    /**
     * >> CMS module: Profile
     *
     * Initiate verification session for currently authenticated user's phone (e.g. SMS with confirmation code).
     */
    startPhoneVerification: SuccessResult;
    /**
     * >> CMS module: TeamRace
     *
     * Join to team race
     * ```
     * TournamentRoundsJoinInput:
     * tournamentId: String - Team race tournament id to join.
     * roundId: String - Round id to join.
     * teamId: String - One of team ids to join(can't be changed).
     *
     * ```
     */
    teamRaceTournamentRoundsJoin: SuccessResult;
    /**
     * >> CMS module: Player
     *
     * Generate trustpilot link
     */
    trustpilot: Trustpilot;
    /**
     * >> CMS module: Player
     *
     *
     */
    trustpilotUnsubscribe: SuccessResult;
    /**
     * >> CMS module: Games
     *
     *
     */
    updateFavoriteThemes: SuccessResult;
    /**
     * >> CMS module: Player
     *
     * Updates existing password. Old password is required
     */
    updatePassword: SuccessResult;
    /**
     * >> CMS module: Profile
     *
     *
     */
    updateProfile: SuccessResult;
    /**
     * >> CMS module: Profile
     *
     *
     */
    updateProfileLocale: SuccessResult;
    /**
     * >> CMS module: Player
     *
     *
     */
    useRedeemCode: SuccessResult;
    /**
     * >> CMS module: WheelOfFortune
     *
     *
     */
    wheelOfFortuneClose: SuccessResult;
    /**
     * >> CMS module: WheelOfFortune
     *
     *
     */
    wheelOfFortuneReSpin?: Maybe<WheelOfFortune>;
    /**
     * >> CMS module: WheelOfFortune
     *
     *
     */
    wheelOfFortuneSpin?: Maybe<WheelOfFortune>;
};

export type MutationAcceptTermsAndConditionsArgs = {
    input: AcceptTermsInput;
};

export type MutationActivateAvailableBonusProgramArgs = {
    id: Scalars['String'];
};

export type MutationActivateAvalaibleBonusProgramArgs = {
    id: Scalars['String'];
};

export type MutationActivateBonusBalanceArgs = {
    input: ActivateBonusBalanceInput;
};

export type MutationActivateBonusProgramArgs = {
    id: Scalars['String'];
};

export type MutationAddParticipantArgs = {
    tournamentId: Scalars['String'];
};

export type MutationAddSearchGameArgs = {
    gameId: Scalars['String'];
};

export type MutationCancelBonusProgramArgs = {
    id: Scalars['String'];
};

export type MutationCancelPayoutArgs = {
    transactionID: Scalars['String'];
};

export type MutationCancelPlayerLimitsArgs = {
    limitId: Scalars['Int'];
};

export type MutationChangePasswordArgs = {
    changePassword: ChangePasswordInput;
};

export type MutationConfirmEmailVerificationArgs = {
    email: Scalars['String'];
    token: Scalars['String'];
};

export type MutationConfirmPhoneVerificationArgs = {
    code: Scalars['String'];
};

export type MutationConfirmRecoveryPasswordArgs = {
    token: Scalars['String'];
};

export type MutationCreateSimpleCredentialsArgs = {
    input: CreateSimpleCredentialsInput;
};

export type MutationCredentialsRecoveryByEmailArgs = {
    callbackUrl?: InputMaybe<Scalars['String']>;
    email: Scalars['String'];
};

export type MutationCredentialsRecoveryByPhoneArgs = {
    phone: Scalars['String'];
};

export type MutationCrmNotificationTrackerArgs = {
    tracker: CrmNotificationTrackerInput;
};

export type MutationCryptoWalletAddressAddArgs = {
    cryptoWalletAddress: Scalars['String'];
};

export type MutationDeleteBonusProgramArgs = {
    id: Scalars['String'];
};

export type MutationDeleteMessageArgs = {
    id: Scalars['String'];
};

export type MutationDepositLinkArgs = {
    deposit: DepositLinkInput;
};

export type MutationDocumentCreateArgs = {
    documentCreate: DocumentCreateInputTypeInput;
};

export type MutationDocumentDeleteArgs = {
    id: Scalars['String'];
};

export type MutationFinishRecoveryPasswordArgs = {
    password: Scalars['String'];
};

export type MutationGamificationPlayerStatisticsGenerateTokenArgs = {
    scopes: Array<ScopeEnumType>;
};

export type MutationGtmDataConfirmEventArgs = {
    eventId: Scalars['String'];
};

export type MutationIdGovUaCallbackArgs = {
    code: Scalars['String'];
    state: Scalars['String'];
};

export type MutationLogoutArgs = {
    token?: InputMaybe<Scalars['String']>;
};

export type MutationLoyaltyProgramExchangePointsArgs = {
    amount: Scalars['String'];
    type?: InputMaybe<Scalars['String']>;
};

export type MutationMarkAchievementAsViewedArgs = {
    name: Scalars['String'];
};

export type MutationMessageEventArgs = {
    action: Scalars['String'];
    messageId: Scalars['String'];
};

export type MutationMobileCreateApnTokenArgs = {
    apnTokenInput: MobilePushTokenInput;
};

export type MutationMobileCreateFirebaseTokenArgs = {
    mobileCreateFirebaseTokenInput: MobileCreateFirebaseTokenInput;
};

export type MutationMobilePushNotificationChangeSubscriptionArgs = {
    input: MobilePushNotificationChangeSubscriptionInput;
};

export type MutationPayoutLinkArgs = {
    payoutLink: PayoutLinkInput;
};

export type MutationPlayerAuthSettingsArgs = {
    isTwoFactorEnabled: Scalars['Boolean'];
};

export type MutationReSignInArgs = {
    input: ReSignInInput;
};

export type MutationRecoverySignInArgs = {
    input?: InputMaybe<SignInRecoveryInput>;
    token: Scalars['String'];
};

export type MutationRegisterWebPushSubscriptionArgs = {
    webPushSubscriptionInput: RegisterWebPushSubscriptionInput;
};

export type MutationRequiredDocumentUploadedArgs = {
    uploadedDocuments: Array<RequiredDocumentUploadedInput>;
};

export type MutationResetPasswordArgs = {
    changePassword: ChangePasswordInput;
};

export type MutationResetPasswordByRecoveryArgs = {
    password: Scalars['String'];
    token: Scalars['String'];
};

export type MutationSafariWebPushCreateArgs = {
    webPushCreateInput: SafariWebPushCreateInput;
};

export type MutationSelfExclusionLockPlayerArgs = {
    lockPlayer?: InputMaybe<SelfExclusionLockPlayerInput>;
};

export type MutationSetActiveBonusProgramArgs = {
    id: Scalars['String'];
};

export type MutationSetBonusProgramActiveArgs = {
    id: Scalars['String'];
};

export type MutationSetGamesFavoriteArgs = {
    gameId: Scalars['String'];
    isFavorite: Scalars['Boolean'];
};

export type MutationSetPlayerLimitsArgs = {
    input: PlayerLimitsInput;
};

export type MutationSignInArgs = {
    signIn: SignInInput;
};

export type MutationSignInByAppleIdArgs = {
    input?: InputMaybe<SignInByAppleIdInput>;
    uid: Scalars['String'];
};

export type MutationSignInByTokenArgs = {
    input?: InputMaybe<SignInByTokenInput>;
    token: Scalars['String'];
};

export type MutationSignUpArgs = {
    playerRequisites?: InputMaybe<PlayerRequisitesTypeInput>;
    signUp: SignUpInput;
};

export type MutationSignUpByPromoRemoteTokenArgs = {
    remoteSignUpInput: PromoRemoteTokenSignUpInput;
};

export type MutationSignUpFinishArgs = {
    input?: InputMaybe<SignUpFinishInput>;
    playerRequisites: PlayerRequisitesTypeInput;
};

export type MutationSocialAttachArgs = {
    login4playToken: Scalars['String'];
};

export type MutationSocialDetachArgs = {
    network: Scalars['String'];
    uid: Scalars['String'];
};

export type MutationSocialLoginArgs = {
    socialLoginInput: SocialLoginInput;
};

export type MutationStartEmailVerificationArgs = {
    startEmailVerification: StartEmailVerificationInput;
};

export type MutationTeamRaceTournamentRoundsJoinArgs = {
    roundsJoinInput: TournamentRoundsJoinInput;
};

export type MutationUpdateFavoriteThemesArgs = {
    isFavorite: Scalars['Boolean'];
    themeId: Scalars['String'];
};

export type MutationUpdatePasswordArgs = {
    changePassword: ChangePasswordInput;
};

export type MutationUpdateProfileArgs = {
    updateProfile?: InputMaybe<UpdateProfileInput>;
};

export type MutationUpdateProfileLocaleArgs = {
    locale: Scalars['String'];
};

export type MutationUseRedeemCodeArgs = {
    redeemCode: Scalars['String'];
};

export type MutationWheelOfFortuneCloseArgs = {
    wheelName: Scalars['String'];
};

export type MutationWheelOfFortuneReSpinArgs = {
    wheelName: Scalars['String'];
};

export type MutationWheelOfFortuneSpinArgs = {
    gameType: WheelOfFortuneGameTypeEnum;
    multiplier: Scalars['Decimal'];
    wheelName: Scalars['String'];
};

export type NameElement = {
    __typename?: 'NameElement';
    first: Scalars['String'];
    last: Scalars['String'];
    maiden: Scalars['String'];
    middle: Scalars['String'];
    patronymic: Scalars['String'];
};

export type NetEntPlayData = PlayData & {
    __typename?: 'NetEntPlayData';
    demo: Scalars['Boolean'];
    gameId: Scalars['String'];
    gameInclusionJsLink: Scalars['String'];
    gameProviderName: Scalars['String'];
    gameServerUrl: Scalars['String'];
    gameSizeOptions: GameSizeOptions;
    launchConfig: NetEntPlayDataLaunchConfig;
    maxWidth?: Maybe<Scalars['Int']>;
    minHeight?: Maybe<Scalars['Int']>;
    minWidth?: Maybe<Scalars['Int']>;
    playUsingBonusBalanceEnabled: Scalars['Boolean'];
};

export type NetEntPlayDataLaunchConfig = {
    __typename?: 'NetEntPlayDataLaunchConfig';
    casinoBrand: Scalars['String'];
    gameId: Scalars['String'];
    gameServer: Scalars['String'];
    language: Scalars['String'];
    lobbyUrl: Scalars['String'];
    sessionId: Scalars['String'];
    staticServer: Scalars['String'];
};

export type NewsByCategory = {
    __typename?: 'NewsByCategory';
    activeCategoryId: Scalars['String'];
    categories: Array<NewsCategoryLocalized>;
};

export type NewsCategoryLocalized = {
    __typename?: 'NewsCategoryLocalized';
    childrenCategories: Array<NewsCategoryLocalized>;
    defaultLocale: Scalars['String'];
    id: Scalars['String'];
    imageId: Scalars['String'];
    isPublished: Scalars['Boolean'];
    layout: Scalars['String'];
    locale: Scalars['String'];
    name: Scalars['String'];
    news: Array<NewsLocalized>;
    parentCategory?: Maybe<NewsCategoryLocalized>;
    pattern: Scalars['String'];
    slug: Scalars['String'];
    title: Scalars['String'];
};

export type NewsLocalized = {
    __typename?: 'NewsLocalized';
    backgroundColor: Scalars['String'];
    bonusPrograms: Array<BonusProgramLocalized>;
    categories: Array<NewsCategoryLocalized>;
    content: Scalars['String'];
    defaultLocale: Scalars['String'];
    description: Scalars['String'];
    detailPictureId: Scalars['String'];
    displayCondition: Scalars['String'];
    endDate?: Maybe<Scalars['DateTime']>;
    id: Scalars['String'];
    isPublished: Scalars['Boolean'];
    layout: Scalars['String'];
    locale: Scalars['String'];
    markdownTemplate: Scalars['Boolean'];
    mobilePreviewPictureId: Scalars['String'];
    name: Scalars['String'];
    nextId?: Maybe<Scalars['String']>;
    nonDisplayCondition: Scalars['String'];
    /**
     * Temporary solution will deprecate after
     * https://jira.syneforge.com/browse/GINBC-993
     */
    parsedContent: Scalars['String'];
    prevId?: Maybe<Scalars['String']>;
    previewPictureId: Scalars['String'];
    publishDate: Scalars['DateTime'];
    sidebarWidgetPictureId: Scalars['String'];
    slug: Scalars['String'];
    sportPreviewPictureId: Scalars['String'];
    tags: Array<Scalars['String']>;
    template: Scalars['String'];
    title: Scalars['String'];
    /** Relative URL */
    url: Scalars['String'];
    viewsCount: Scalars['Int'];
};

export type NewsLocalizedRoutingRelatedEntity = RoutingRelatedEntityInfo & {
    __typename?: 'NewsLocalizedRoutingRelatedEntity';
    entity: NewsLocalized;
    entityType: Scalars['String'];
    id: Scalars['String'];
};

export type NonActuallyLimitsResultType = {
    __typename?: 'NonActuallyLimitsResultType';
    hasNextPart: Scalars['Boolean'];
    list: Array<Limit>;
};

export enum OperationSystem {
    Android = 'ANDROID',
    Ios = 'IOS',
}

export type OwnFeatureFlag = {
    __typename?: 'OwnFeatureFlag';
    enabled: Scalars['Boolean'];
    name: Scalars['String'];
};

export type PageLocalized = {
    __typename?: 'PageLocalized';
    content: Scalars['String'];
    /** @deprecated don't use this field */
    endDate?: Maybe<Scalars['DateTime']>;
    id: Scalars['String'];
    imageId: Scalars['String'];
    layout: Scalars['String'];
    layoutType: Scalars['String'];
    markdownTemplate: Scalars['Boolean'];
    name: Scalars['String'];
    /** @deprecated don't use this field */
    publishDate?: Maybe<Scalars['DateTime']>;
    /** @deprecated this field is always true */
    publishDateHasCome: Scalars['Boolean'];
    /** @deprecated this field is always true and is deprecated */
    published: Scalars['Boolean'];
    renderContent: Scalars['String'];
    slug: Scalars['String'];
    tags: Array<Scalars['String']>;
    template: Scalars['String'];
    title: Scalars['String'];
    url?: Maybe<Scalars['String']>;
};

export type PageLocalizedRoutingRelatedEntity = RoutingRelatedEntityInfo & {
    __typename?: 'PageLocalizedRoutingRelatedEntity';
    entity: PageLocalized;
    entityType: Scalars['String'];
    id: Scalars['String'];
};

export type Participant = {
    featureName: Scalars['String'];
    featureVariant: Scalars['String'];
    scenarioIdentifier: Scalars['String'];
    shouldAnalysis: Scalars['Boolean'];
};

export type ParticipantImpl = Participant & {
    __typename?: 'ParticipantImpl';
    featureName: Scalars['String'];
    featureVariant: Scalars['String'];
    scenarioIdentifier: Scalars['String'];
    shouldAnalysis: Scalars['Boolean'];
};

export type PaymentAmountDto = {
    __typename?: 'PaymentAmountDto';
    currencyCode: Scalars['String'];
    value: Scalars['String'];
};

export type PaymentCommissionDto = {
    __typename?: 'PaymentCommissionDto';
    amount?: Maybe<Scalars['Float']>;
    percent?: Maybe<Scalars['Int']>;
    type: Scalars['String'];
};

export type PaymentHistoryResult = {
    hasNextPart: Scalars['Boolean'];
    transactions: Array<PaymentTransactionInterface>;
};

export type PaymentHistoryResultImpl = PaymentHistoryResult & {
    __typename?: 'PaymentHistoryResultImpl';
    hasNextPart: Scalars['Boolean'];
    transactions: Array<PaymentTransactionInterface>;
};

export type PaymentHistoryTransaction = PaymentMethodLocalization &
    PaymentTransactionInterface & {
        __typename?: 'PaymentHistoryTransaction';
        action?: Maybe<Scalars['String']>;
        imageId: Scalars['String'];
        paymentMethod: PaymentMethodLocalization;
        title: Scalars['String'];
        transaction: PaymentTransactionDto;
    };

export type PaymentLink = {
    type: Scalars['String'];
    url: Scalars['String'];
};

export type PaymentLinkImpl = PaymentLink & {
    __typename?: 'PaymentLinkImpl';
    type: Scalars['String'];
    url: Scalars['String'];
};

export type PaymentMethodLocalization = {
    imageId: Scalars['String'];
    title: Scalars['String'];
};

export type PaymentMethodLocalizationImpl = PaymentMethodLocalization & {
    __typename?: 'PaymentMethodLocalizationImpl';
    imageId: Scalars['String'];
    title: Scalars['String'];
};

export enum PaymentMethodTypeEnum {
    TypeDeposit = 'TYPE_DEPOSIT',
    TypePayout = 'TYPE_PAYOUT',
}

export type PaymentOption = {
    common: Scalars['String'];
    defaultLocale: Scalars['String'];
    /**
     * Array of recommended amounts (decimal/integer string) to display buttons for quick fill deposit amount.
     * Example: ["300", "500", "1000", "2000", "5000"]
     */
    depositButtons: Array<Scalars['String']>;
    enabled: Scalars['Boolean'];
    id: Scalars['String'];
    imageId: Scalars['String'];
    locale: Scalars['String'];
    methodType: Scalars['String'];
    name: Scalars['String'];
    settings: PaymentSettingDto;
    systemName: Scalars['String'];
    title: Scalars['String'];
    type: Scalars['String'];
};

export type PaymentOptionImpl = PaymentOption & {
    __typename?: 'PaymentOptionImpl';
    common: Scalars['String'];
    defaultLocale: Scalars['String'];
    /**
     * Array of recommended amounts (decimal/integer string) to display buttons for quick fill deposit amount.
     * Example: ["300", "500", "1000", "2000", "5000"]
     */
    depositButtons: Array<Scalars['String']>;
    enabled: Scalars['Boolean'];
    id: Scalars['String'];
    imageId: Scalars['String'];
    locale: Scalars['String'];
    methodType: Scalars['String'];
    name: Scalars['String'];
    settings: PaymentSettingDto;
    systemName: Scalars['String'];
    title: Scalars['String'];
    type: Scalars['String'];
};

export type PaymentPlayerBalancesInfoDto = {
    bonus: Scalars['Float'];
    currency?: Maybe<Currency>;
    real: Scalars['Float'];
    total: Scalars['Float'];
    withdrawal: Scalars['Float'];
};

export type PaymentPlayerBalancesInfoDtoImpl = PaymentPlayerBalancesInfoDto & {
    __typename?: 'PaymentPlayerBalancesInfoDtoImpl';
    bonus: Scalars['Float'];
    currency?: Maybe<Currency>;
    real: Scalars['Float'];
    total: Scalars['Float'];
    withdrawal: Scalars['Float'];
};

export type PaymentSettingDto = {
    commission: PaymentCommissionDto;
    convertCurrency: Scalars['String'];
    maxAmount: PaymentAmountDto;
    minAmount: PaymentAmountDto;
    status: Scalars['String'];
};

export type PaymentSettingDtoImpl = PaymentSettingDto & {
    __typename?: 'PaymentSettingDtoImpl';
    commission: PaymentCommissionDto;
    convertCurrency: Scalars['String'];
    maxAmount: PaymentAmountDto;
    minAmount: PaymentAmountDto;
    status: Scalars['String'];
};

export type PaymentTransaction = PaymentMethodLocalization &
    PaymentTransactionInterface & {
        __typename?: 'PaymentTransaction';
        imageId: Scalars['String'];
        paymentMethod: PaymentMethodLocalization;
        title: Scalars['String'];
        transaction: PaymentTransactionDto;
    };

export type PaymentTransactionAmountDto = {
    /** Operation amount in balance currency, may include fee (on payout for instance) */
    balanceAmount: PaymentAmountDto;
    /**
     * @deprecated Deprecated, use "transactionAmount or "balanceAmount" depending on usage context (what sum do you need to display - the sum of external payment or sum of balance change).
     * transactionAmount and balanceAmount are equals when operation currency and valance currency are equal. But there may be exceptions when there is operation fee, in this
     * case fee included in balanceAmount.
     */
    source: PaymentAmountDto;
    /** Operation amount in operation currency */
    transactionAmount: PaymentAmountDto;
};

export type PaymentTransactionAmountDtoImpl = PaymentTransactionAmountDto & {
    __typename?: 'PaymentTransactionAmountDtoImpl';
    /** Operation amount in balance currency, may include fee (on payout for instance) */
    balanceAmount: PaymentAmountDto;
    /**
     * @deprecated Deprecated, use "transactionAmount or "balanceAmount" depending on usage context (what sum do you need to display - the sum of external payment or sum of balance change).
     * transactionAmount and balanceAmount are equals when operation currency and valance currency are equal. But there may be exceptions when there is operation fee, in this
     * case fee included in balanceAmount.
     */
    source: PaymentAmountDto;
    /** Operation amount in operation currency */
    transactionAmount: PaymentAmountDto;
};

export type PaymentTransactionDto = {
    amount: PaymentTransactionAmountDto;
    id: PaymentTransactionIdDto;
    status: PaymentTransactionStatusDto;
    type: Scalars['String'];
};

export type PaymentTransactionDtoImpl = PaymentTransactionDto & {
    __typename?: 'PaymentTransactionDtoImpl';
    amount: PaymentTransactionAmountDto;
    id: PaymentTransactionIdDto;
    status: PaymentTransactionStatusDto;
    type: Scalars['String'];
};

export type PaymentTransactionIdDto = {
    local: Scalars['String'];
};

export type PaymentTransactionIdDtoImpl = PaymentTransactionIdDto & {
    __typename?: 'PaymentTransactionIdDtoImpl';
    local: Scalars['String'];
};

export type PaymentTransactionImpl = PaymentTransactionInterface & {
    __typename?: 'PaymentTransactionImpl';
    paymentMethod: PaymentMethodLocalization;
    transaction: PaymentTransactionDto;
};

export type PaymentTransactionInterface = {
    paymentMethod: PaymentMethodLocalization;
    transaction: PaymentTransactionDto;
};

export type PaymentTransactionStatusDto = {
    createdAt?: Maybe<Scalars['String']>;
    type: Scalars['String'];
};

export type PaymentTransactionStatusDtoImpl = PaymentTransactionStatusDto & {
    __typename?: 'PaymentTransactionStatusDtoImpl';
    createdAt?: Maybe<Scalars['String']>;
    type: Scalars['String'];
};

export type PayoutCommissionClaricationProfitTaxType = Clarification & {
    __typename?: 'PayoutCommissionClaricationProfitTaxType';
    commission: Scalars['Decimal'];
    currentPayout: Scalars['Decimal'];
    percent: Scalars['Decimal'];
    taxableIncome: Scalars['Decimal'];
    taxedAmount: Scalars['Decimal'];
    totalDeposit: Scalars['Decimal'];
    totalPayout: Scalars['Decimal'];
    type: Scalars['String'];
};

export type PayoutCommissionClaricationWagerBalanceType = Clarification & {
    __typename?: 'PayoutCommissionClaricationWagerBalanceType';
    balanceWagerAmount: Scalars['Decimal'];
    commission: Scalars['Decimal'];
    percent: Scalars['Decimal'];
    type: Scalars['String'];
};

export type PayoutCommissionForSystemInput = {
    systemName: Scalars['String'];
};

export type PayoutCommissionForSystemType = {
    __typename?: 'PayoutCommissionForSystemType';
    commission: WithdrawCommissionDto;
};

export type PayoutLinkInput = {
    amount: Scalars['Float'];
    redirectUrls?: InputMaybe<RedirectUrlsInput>;
    requisitesCard?: InputMaybe<Scalars['String']>;
    requisitesCardMask?: InputMaybe<Scalars['String']>;
    requisitesPhone?: InputMaybe<Scalars['String']>;
    transactionCurrencyCode?: InputMaybe<Scalars['String']>;
    transactionId?: InputMaybe<Scalars['String']>;
    withdrawMethodId: Scalars['String'];
};

export type PayoutMethodsType = {
    __typename?: 'PayoutMethodsType';
    hasBonusBalance: Scalars['Boolean'];
    playerBalance: PaymentPlayerBalancesInfoDto;
    withdrawMethods: Array<PaymentOption>;
};

export enum Period {
    Month = 'MONTH',
    Week = 'WEEK',
    Year = 'YEAR',
}

export enum Platform {
    DesktopWebsite = 'DESKTOP_WEBSITE',
    MobileWebsite = 'MOBILE_WEBSITE',
}

export type PlayData = {
    demo: Scalars['Boolean'];
    gameId: Scalars['String'];
    gameProviderName: Scalars['String'];
    gameServerUrl: Scalars['String'];
    gameSizeOptions: GameSizeOptions;
    playUsingBonusBalanceEnabled: Scalars['Boolean'];
};

export type PlayDataImpl = PlayData & {
    __typename?: 'PlayDataImpl';
    demo: Scalars['Boolean'];
    gameId: Scalars['String'];
    gameProviderName: Scalars['String'];
    gameServerUrl: Scalars['String'];
    gameSizeOptions: GameSizeOptions;
    playUsingBonusBalanceEnabled: Scalars['Boolean'];
};

export type PlaySlotCountConditionRule = ConditionRuleInterface & {
    __typename?: 'PlaySlotCountConditionRule';
    countSlots: Scalars['Int'];
    type: ConditionRuleEnumType;
};

export type PlaySpinCountConditionRule = ConditionRuleInterface & {
    __typename?: 'PlaySpinCountConditionRule';
    countSpins: Scalars['Int'];
    type: ConditionRuleEnumType;
};

export type Player = SymfonyUserInterface &
    UserInterface & {
        __typename?: 'Player';
        address?: Maybe<AddressElement>;
        avatar?: Maybe<AvatarElement>;
        countryId: Scalars['Int'];
        createdAt: Scalars['DateTime'];
        dateOfBirthday?: Maybe<Scalars['DateTime']>;
        dateOfBirthdayConfirmed: Scalars['Boolean'];
        displayName: Scalars['String'];
        email: Scalars['String'];
        emailConfirmed: Scalars['Boolean'];
        emailConfirmedAt?: Maybe<Scalars['DateTime']>;
        gambleBlocked: Scalars['Boolean'];
        gender: Scalars['String'];
        id: Scalars['String'];
        license: Scalars['String'];
        login: Scalars['String'];
        loginBlocked: Scalars['Boolean'];
        name?: Maybe<NameElement>;
        onfidoCheckedAt?: Maybe<Scalars['DateTime']>;
        pep: Scalars['Boolean'];
        phone: Scalars['String'];
        phoneConfirmed: Scalars['Boolean'];
        placeOfBirth: Scalars['String'];
        priorityTypeOfGambling: Scalars['String'];
        receiveEmails: Scalars['Boolean'];
        refCode: Scalars['String'];
        roles: Array<Scalars['String']>;
        sanctioned: Scalars['Boolean'];
        security?: Maybe<SecurityElement>;
        selfDeclaredPep: Scalars['Boolean'];
        skype: Scalars['String'];
        steam?: Maybe<SteamElement>;
        transactionBlocked: Scalars['Boolean'];
        updatedAt: Scalars['DateTime'];
        userName: Scalars['String'];
        wealth?: Maybe<WealthElement>;
    };

export type PlayerAchievement = {
    __typename?: 'PlayerAchievement';
    achievementName: Scalars['String'];
    allPlayersProgress?: Maybe<Scalars['Int']>;
    openAt?: Maybe<Scalars['DateTime']>;
    /**
     * Player progress in earn achievement.
     * Progress is array cuz achievement can require more than 1 condition to be reached
     */
    progress: Array<PlayerAchievementProgressInterface>;
    viewAt?: Maybe<Scalars['DateTime']>;
};

export type PlayerAchievementProgress = PlayerAchievementProgressInterface & {
    __typename?: 'PlayerAchievementProgress';
    /** Retrieves the type of condition rule for the specified player's achievement. */
    conditionType: ConditionRuleEnumType;
    /**
     * Retrieves the current numeric value for the specified player's achievement progress.
     * The current value represents the progress made towards the achievement,
     * while the value for opening the achievement signifies the milestone that must be reached.
     */
    currentValue: Scalars['Int'];
    /** Retrieves the type of progress for the specified player's achievement. */
    type: PlayerAchievementProgressEnumType;
    /** Retrieves the numeric value required to achieve the milestone for the specified player's achievement. */
    valueForOpen: Scalars['Int'];
};

export enum PlayerAchievementProgressEnumType {
    CountEvents = 'COUNT_EVENTS',
    CountUniqueEvents = 'COUNT_UNIQUE_EVENTS',
    EverydayEvents = 'EVERYDAY_EVENTS',
    MoneySum = 'MONEY_SUM',
    SlotsCount = 'SLOTS_COUNT',
}

export type PlayerAchievementProgressInterface = {
    /** Retrieves the type of condition rule for the specified player's achievement. */
    conditionType: ConditionRuleEnumType;
    /**
     * Retrieves the current numeric value for the specified player's achievement progress.
     * The current value represents the progress made towards the achievement,
     * while the value for opening the achievement signifies the milestone that must be reached.
     */
    currentValue: Scalars['Int'];
    /** Retrieves the type of progress for the specified player's achievement. */
    type: PlayerAchievementProgressEnumType;
    /** Retrieves the numeric value required to achieve the milestone for the specified player's achievement. */
    valueForOpen: Scalars['Int'];
};

export type PlayerAchievementTupleType = {
    __typename?: 'PlayerAchievementTupleType';
    achievement: PlayerAchievement;
    achievementEntity: LocalizedAchievementEntity;
};

export type PlayerAchievementsResultType = {
    __typename?: 'PlayerAchievementsResultType';
    achievements: Array<PlayerAchievementTupleType>;
};

export type PlayerAuthSettings = {
    __typename?: 'PlayerAuthSettings';
    twoFactorEnabled: Scalars['Boolean'];
};

export type PlayerAvailableAchievementsResultType = {
    __typename?: 'PlayerAvailableAchievementsResultType';
    achievements: Array<AchievementTupleType>;
};

export type PlayerBalanceJournalEntry = {
    actionTypeId: Scalars['Int'];
    balanceAfter: Scalars['Float'];
    balanceBefore: Scalars['Float'];
    balanceDelta: Scalars['Float'];
    balanceId: Scalars['Int'];
    balanceType: Scalars['Int'];
    brandId: Scalars['Int'];
    createdAt?: Maybe<Scalars['DateTime']>;
    currency?: Maybe<Currency>;
    id: Scalars['String'];
    playerId: Scalars['Int'];
};

export type PlayerBalanceJournalEntryImpl = PlayerBalanceJournalEntry & {
    __typename?: 'PlayerBalanceJournalEntryImpl';
    actionTypeId: Scalars['Int'];
    balanceAfter: Scalars['Float'];
    balanceBefore: Scalars['Float'];
    balanceDelta: Scalars['Float'];
    balanceId: Scalars['Int'];
    balanceType: Scalars['Int'];
    brandId: Scalars['Int'];
    createdAt?: Maybe<Scalars['DateTime']>;
    currency?: Maybe<Currency>;
    id: Scalars['String'];
    playerId: Scalars['Int'];
};

export type PlayerBalanceJournalEntryListType = {
    __typename?: 'PlayerBalanceJournalEntryListType';
    hasNextPart: Scalars['Boolean'];
    list: Array<PlayerBalanceJournalEntry>;
};

export type PlayerBalancesResultType = {
    __typename?: 'PlayerBalancesResultType';
    bonusBalances: Array<BonusBalance>;
    currency: Currency;
    realBalance: Balance;
};

export type PlayerCashbackCoefficientsResultType = {
    __typename?: 'PlayerCashbackCoefficientsResultType';
    cashbackCoefficient?: Maybe<CashbackCoefficient>;
};

export type PlayerLimitsInput = {
    interval?: InputMaybe<Scalars['String']>;
    money?: InputMaybe<Scalars['Float']>;
    period?: InputMaybe<Scalars['String']>;
    reason?: InputMaybe<Scalars['String']>;
    type: Scalars['String'];
};

export type PlayerPasswordResultType = {
    __typename?: 'PlayerPasswordResultType';
    playerPasswordExist: Scalars['Boolean'];
};

export type PlayerProgram = {
    __typename?: 'PlayerProgram';
    status: PlayerProgramStatus;
};

export type PlayerProgramStatus = {
    __typename?: 'PlayerProgramStatus';
    assignedAt: Scalars['DateTime'];
    type: PlayerProgramStatusTypeEnumType;
};

export enum PlayerProgramStatusTypeEnumType {
    Canceled = 'CANCELED',
    Completed = 'COMPLETED',
    Expired = 'EXPIRED',
    Processing = 'PROCESSING',
    Unknown = 'UNKNOWN',
}

export type PlayerRequisitesTypeInput = {
    city?: InputMaybe<Scalars['String']>;
    countryId?: InputMaybe<Scalars['Int']>;
    currencyId: Scalars['Int'];
    dateOfBirth?: InputMaybe<Scalars['DateTime']>;
    displayName?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    gender?: InputMaybe<Scalars['String']>;
    hash?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    maidenLastName?: InputMaybe<Scalars['String']>;
    passportIssuedBy?: InputMaybe<Scalars['String']>;
    passportIssuedOn?: InputMaybe<Scalars['DateTime']>;
    passportNumber?: InputMaybe<Scalars['String']>;
    passportSeries?: InputMaybe<Scalars['String']>;
    patronymic?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
    phoneConfirmed?: InputMaybe<Scalars['Boolean']>;
    placeOfBirth?: InputMaybe<Scalars['String']>;
    policyAgree?: InputMaybe<Scalars['Boolean']>;
    priorityTypeOfGambling?: InputMaybe<Scalars['String']>;
    receiveEmails?: InputMaybe<Scalars['Boolean']>;
    receiveSms?: InputMaybe<Scalars['Boolean']>;
    redeemCode?: InputMaybe<Scalars['String']>;
    refCode?: InputMaybe<Scalars['String']>;
    residence?: InputMaybe<ProfileResidenceInput>;
    securityAnswer?: InputMaybe<Scalars['String']>;
    securityQuestion?: InputMaybe<Scalars['String']>;
    selfDeclaredPep?: InputMaybe<Scalars['Boolean']>;
    skype?: InputMaybe<Scalars['String']>;
    street?: InputMaybe<Scalars['String']>;
    taxNumber?: InputMaybe<ProfileTaxNumberInput>;
    zipCode?: InputMaybe<Scalars['String']>;
};

export type PlayerSegment = {
    __typename?: 'PlayerSegment';
    segment: Scalars['Boolean'];
};

export type PlayerStatistics = {
    loginRefCode: Scalars['String'];
    registrationAt: Scalars['DateTime'];
    registrationRefCode: Scalars['String'];
    totalDepositCount: Scalars['Int'];
    totalDepositSum: Scalars['Decimal'];
    uuid: Scalars['String'];
};

export type PlayerStatisticsImpl = PlayerStatistics & {
    __typename?: 'PlayerStatisticsImpl';
    loginRefCode: Scalars['String'];
    registrationAt: Scalars['DateTime'];
    registrationRefCode: Scalars['String'];
    totalDepositCount: Scalars['Int'];
    totalDepositSum: Scalars['Decimal'];
    uuid: Scalars['String'];
};

export type ProcessingBonusProgram = {
    __typename?: 'ProcessingBonusProgram';
    bonusProgramData: ProcessingBonusProgramData;
    programTemplate: BonusProgramLocalized;
    type: Scalars['String'];
};

export type ProcessingBonusProgramData = {
    active: Scalars['Boolean'];
    allowedSetActive: Scalars['Boolean'];
    completed: Scalars['Boolean'];
    description: Scalars['String'];
    name: Scalars['String'];
    playerProgram: PlayerProgram;
    programPlayerId: Scalars['String'];
    programTemplate: BonusProgramData;
    programTemplateId: Scalars['String'];
    taskConnections: Array<TaskConnection>;
    tasks: Array<BonusProgramDataTask>;
};

export type ProcessingBonusProgramDataImpl = ProcessingBonusProgramData & {
    __typename?: 'ProcessingBonusProgramDataImpl';
    active: Scalars['Boolean'];
    allowedSetActive: Scalars['Boolean'];
    completed: Scalars['Boolean'];
    description: Scalars['String'];
    name: Scalars['String'];
    playerProgram: PlayerProgram;
    programPlayerId: Scalars['String'];
    programTemplate: BonusProgramData;
    programTemplateId: Scalars['String'];
    taskConnections: Array<TaskConnection>;
    tasks: Array<BonusProgramDataTask>;
};

export type ProfileAddressInput = {
    city: Scalars['String'];
    countryId: Scalars['String'];
    street: Scalars['String'];
    zipCode: Scalars['String'];
};

export type ProfileAvatarInput = {
    avatar: Scalars['String'];
    full?: InputMaybe<Scalars['String']>;
    medium?: InputMaybe<Scalars['String']>;
};

export type ProfileCitizenshipInput = {
    countryId: Scalars['String'];
};

export type ProfileDisplayInput = {
    displayName: Scalars['String'];
};

export type ProfileDobInput = {
    dob: Scalars['Date'];
};

export type ProfileEmailInput = {
    email: Scalars['String'];
    optOut: Scalars['Boolean'];
};

export type ProfileGenderInput = {
    gender: Scalars['String'];
};

export type ProfileIdentificationInput = {
    passportCountryId: Scalars['String'];
    passportIssuedBy: Scalars['String'];
    passportIssuedOn: Scalars['DateTime'];
    passportNumber: Scalars['String'];
    passportSeries: Scalars['String'];
};

export type ProfileInfo = {
    avatar: Scalars['String'];
    avatarFull: Scalars['String'];
    avatarMedium: Scalars['String'];
    brandId: Scalars['Int'];
    city: Scalars['String'];
    countryId?: Maybe<Scalars['Int']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    /** @deprecated Outdated due to date format change. Use - formattedDateOfBirth. */
    dateOfBirth?: Maybe<Scalars['DateTime']>;
    displayName: Scalars['String'];
    dobCorrect: Scalars['Boolean'];
    email: Scalars['String'];
    emailConfirmed: Scalars['Boolean'];
    emailConfirmedAt?: Maybe<Scalars['DateTime']>;
    firstName: Scalars['String'];
    formattedDateOfBirth?: Maybe<Scalars['String']>;
    gambleBlocked: Scalars['Boolean'];
    gender?: Maybe<Scalars['String']>;
    isPep: Scalars['Boolean'];
    isSanctioned: Scalars['Boolean'];
    lastName: Scalars['String'];
    login: Scalars['String'];
    loginBlocked: Scalars['Boolean'];
    maidenLastName: Scalars['String'];
    onfidoCheckedAt?: Maybe<Scalars['DateTime']>;
    phone: Scalars['String'];
    phoneConfirmed: Scalars['Boolean'];
    placeOfBirth: Scalars['String'];
    playerId: Scalars['String'];
    priorityTypeofGambling: Scalars['String'];
    receiveEmails: Scalars['Boolean'];
    receiveSms: Scalars['Boolean'];
    refCode: Scalars['String'];
    residence?: Maybe<ProfileResidence>;
    securityAnswer: Scalars['String'];
    securityQuestion: Scalars['String'];
    selfDeclaredPep: Scalars['Boolean'];
    skype: Scalars['String'];
    sourceOfWealth: Scalars['String'];
    sourceOfWealthCreatedAt?: Maybe<Scalars['DateTime']>;
    street: Scalars['String'];
    taxNumber?: Maybe<TaxNumber>;
    tradeUrl: Scalars['String'];
    transactionBlocked: Scalars['Boolean'];
    uid: Scalars['String'];
    updatedAt?: Maybe<Scalars['DateTime']>;
    zipCode: Scalars['String'];
};

export type ProfileInfoImpl = ProfileInfo & {
    __typename?: 'ProfileInfoImpl';
    avatar: Scalars['String'];
    avatarFull: Scalars['String'];
    avatarMedium: Scalars['String'];
    brandId: Scalars['Int'];
    city: Scalars['String'];
    countryId?: Maybe<Scalars['Int']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    /** @deprecated Outdated due to date format change. Use - formattedDateOfBirth. */
    dateOfBirth?: Maybe<Scalars['DateTime']>;
    displayName: Scalars['String'];
    dobCorrect: Scalars['Boolean'];
    email: Scalars['String'];
    emailConfirmed: Scalars['Boolean'];
    emailConfirmedAt?: Maybe<Scalars['DateTime']>;
    firstName: Scalars['String'];
    formattedDateOfBirth?: Maybe<Scalars['String']>;
    gambleBlocked: Scalars['Boolean'];
    gender?: Maybe<Scalars['String']>;
    isPep: Scalars['Boolean'];
    isSanctioned: Scalars['Boolean'];
    lastName: Scalars['String'];
    login: Scalars['String'];
    loginBlocked: Scalars['Boolean'];
    maidenLastName: Scalars['String'];
    onfidoCheckedAt?: Maybe<Scalars['DateTime']>;
    phone: Scalars['String'];
    phoneConfirmed: Scalars['Boolean'];
    placeOfBirth: Scalars['String'];
    playerId: Scalars['String'];
    priorityTypeofGambling: Scalars['String'];
    receiveEmails: Scalars['Boolean'];
    receiveSms: Scalars['Boolean'];
    refCode: Scalars['String'];
    residence?: Maybe<ProfileResidence>;
    securityAnswer: Scalars['String'];
    securityQuestion: Scalars['String'];
    selfDeclaredPep: Scalars['Boolean'];
    skype: Scalars['String'];
    sourceOfWealth: Scalars['String'];
    sourceOfWealthCreatedAt?: Maybe<Scalars['DateTime']>;
    street: Scalars['String'];
    taxNumber?: Maybe<TaxNumber>;
    tradeUrl: Scalars['String'];
    transactionBlocked: Scalars['Boolean'];
    uid: Scalars['String'];
    updatedAt?: Maybe<Scalars['DateTime']>;
    zipCode: Scalars['String'];
};

export type ProfileLiveContactInput = {
    login: Scalars['String'];
    system: Scalars['String'];
};

export type ProfileNameInput = {
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    patronymic: Scalars['String'];
};

export type ProfilePhoneInput = {
    optOut: Scalars['Boolean'];
    phone: Scalars['String'];
};

export type ProfileResidence = {
    __typename?: 'ProfileResidence';
    building: Scalars['String'];
    city: Scalars['String'];
    flat: Scalars['String'];
    house: Scalars['String'];
    region: Scalars['String'];
    street: Scalars['String'];
    zipCode: Scalars['String'];
};

export type ProfileResidenceInput = {
    building: Scalars['String'];
    city: Scalars['String'];
    flat: Scalars['String'];
    house: Scalars['String'];
    region: Scalars['String'];
    street: Scalars['String'];
    zipCode: Scalars['String'];
};

export type ProfileSecurityInput = {
    answer: Scalars['String'];
    question: Scalars['String'];
};

export type ProfileSkypeInput = {
    skype: Scalars['String'];
};

export type ProfileTaxNumberInput = {
    number: Scalars['String'];
    type: TaxTypeEnum;
};

export type ProfileUpdateConditionRule = ConditionRuleInterface & {
    __typename?: 'ProfileUpdateConditionRule';
    checkType: Scalars['String'];
    type: ConditionRuleEnumType;
};

export type PromoRemoteSignUpInput = {
    currencyCode: Scalars['String'];
    displayName?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    login?: InputMaybe<Scalars['String']>;
    password: Scalars['String'];
    phone?: InputMaybe<Scalars['String']>;
    successUrl?: InputMaybe<Scalars['String']>;
    trackers?: InputMaybe<TrackersInput>;
};

export type PromoRemoteTokenSignUpInput = {
    token: Scalars['String'];
};

export type PromotionLocalized = {
    __typename?: 'PromotionLocalized';
    animationIconId: Scalars['String'];
    bonusPrograms: Array<Scalars['String']>;
    content: Scalars['String'];
    /** @deprecated Use `texts` map instead */
    contentCurrency: Scalars['String'];
    defaultLocale: Scalars['String'];
    description: Scalars['String'];
    detailPictureId: Scalars['String'];
    endDate: Scalars['DateTime'];
    id: Scalars['String'];
    images: Array<PromotionMap>;
    isMarkdownTemplate: Scalars['Boolean'];
    isPublished: Scalars['Boolean'];
    layout: Scalars['String'];
    locale: Scalars['String'];
    name: Scalars['String'];
    path: Scalars['String'];
    previewPictureId: Scalars['String'];
    publishDate: Scalars['DateTime'];
    slug: Scalars['String'];
    staticIconId: Scalars['String'];
    tags: Array<TagLocalized>;
    template: Scalars['String'];
    texts: Array<PromotionMap>;
    title: Scalars['String'];
    titleCurrency: Scalars['String'];
    type: Scalars['String'];
};

export type PromotionLocalizedListType = {
    __typename?: 'PromotionLocalizedListType';
    list: Array<PromotionLocalized>;
};

export type PromotionMap = {
    key: Scalars['String'];
    value: Scalars['String'];
    valueCurrency: Scalars['String'];
};

export type PromotionMapImpl = PromotionMap & {
    __typename?: 'PromotionMapImpl';
    key: Scalars['String'];
    value: Scalars['String'];
    valueCurrency: Scalars['String'];
};

export type PublicBonusProgramListFilterInput = {
    category?: InputMaybe<Scalars['String']>;
    displayInType?: InputMaybe<Scalars['String']>;
};

export type Query = {
    __typename?: 'Query';
    /**
     * >> CMS module: BroadcastMessage
     *
     *
     */
    activeMessages: Array<BroadcastMessageLocalized>;
    /**
     * >> CMS module: Frontend
     *
     *
     */
    analyticsSettings: AnalyticsSettingsResult;
    /**
     * >> CMS module: Currency
     *
     *
     */
    availableCurrency: Array<Currency>;
    /**
     * >> CMS module: Player
     *
     *
     * @deprecated use kycaidVerificationRequest instead
     */
    bankIdVerificationRequest: BankIdVerificationRequest;
    /**
     * >> CMS module: Player
     *
     * Returns information whether your location (country/region) listed as banned for some actions
     */
    bannedLocation: BannedLocationResultType;
    /**
     * >> CMS module: Banner
     *
     *
     */
    banners: Array<DecodedBanner>;
    /**
     * >> CMS module: Betting
     *
     * Query contains show betting check. Depends on betting banned countries, features and betting access.
     * If betting forbidden query return 'betting.forbidden' extension code
     */
    bettingData: BettingData;
    /**
     * >> CMS module: Betting
     *
     *
     */
    bettingPredictions: Array<BettingPrediction>;
    /**
     * >> CMS module: Betting
     *
     *
     */
    bettingSettings: BettingSettings;
    /**
     * >> CMS module: Betting
     *
     *
     */
    bettingSportById?: Maybe<BettingSportsData>;
    /**
     * >> CMS module: Betting
     *
     *
     */
    bettingTournamentSettingsById?: Maybe<BettingTournaments>;
    /**
     * >> CMS module: Bonus
     *
     *
     * @deprecated use query `playerBonusBalanceJournal` instead
     */
    bonusHistory: BalanceJournalEntryListType;
    /**
     * >> CMS module: Bonus
     *
     * Returns all information about the bonus program by its ID
     */
    bonusProgramById?: Maybe<BonusProgramLocalized>;
    /**
     * >> CMS module: Bonus
     *
     *
     * @deprecated use bonusProgramsFullArchived, bonusProgramsFullAvailable or bonusProgramsFullProcessing query instead
     */
    bonusPrograms: Array<BonusProgramFull>;
    /**
     * >> CMS module: Bonus
     *
     *
     */
    bonusProgramsByIds: Array<BonusProgramLocalized>;
    /**
     * >> CMS module: Bonus
     *
     *
     */
    bonusProgramsFullArchived: ArchivedBonusProgramListType;
    /**
     * >> CMS module: Bonus
     *
     *
     */
    bonusProgramsFullAvailable: Array<AvailableBonusProgram>;
    /**
     * >> CMS module: Bonus
     *
     *
     */
    bonusProgramsFullProcessing: Array<ProcessingBonusProgram>;
    /**
     * >> CMS module: Bonus
     *
     *
     */
    bonusQuests: Array<BonusProgramFull>;
    /**
     * >> CMS module: News
     *
     *
     */
    bonuses: Array<NewsLocalized>;
    /**
     * >> CMS module: News
     *
     *
     */
    breadcrumbs: Array<NewsCategoryLocalized>;
    /**
     * >> CMS module: Captcha
     *
     *
     */
    captcha: CaptchaType;
    /**
     * >> CMS module: Player
     *
     * Returns information about the user's cashback coefficient
     */
    cashbackCoefficient?: Maybe<PlayerCashbackCoefficientsResultType>;
    /**
     * >> CMS module: News
     *
     *
     */
    categoryNews: NewsByCategory;
    /**
     * >> CMS module: ChatWidget
     *
     * Chat widget - an interactive element on a webpage or within an application for real-time communication with support.
     * Query returns the settings of the chat widget used in the project.
     * If the settings cannot be retrieved, it returns the error 'chat_widget.chat_settings_not_configured'
     */
    chatWidget: Chat;
    /**
     * >> CMS module: Player
     *
     *
     */
    checkRecoveryToken: SuccessResult;
    /**
     * >> CMS module: Redeem
     *
     *
     */
    checkRedeemCode: SuccessResult;
    /**
     * >> CMS module: Frontend
     *
     *
     */
    countries: Array<Country>;
    /**
     * >> CMS module: GeoIp
     *
     *
     * @deprecated use `geoIpLocation` query
     */
    countryByUserIp: GeoIpCountry;
    /**
     * >> CMS module: Player
     *
     *
     */
    cryptoWalletAddress: CryptoWalletAddressResponse;
    /**
     * >> CMS module: Player
     *
     *
     */
    cryptoWalletRequestExist: Scalars['Boolean'];
    /**
     * >> CMS module: Mirror
     *
     *
     */
    currentMirror?: Maybe<Mirror>;
    /**
     * >> CMS module: Locale
     *
     *
     */
    defaultLocale: Scalars['String'];
    /**
     * >> CMS module: Payment
     *
     *
     */
    depositMethods: DepositMethodsType;
    /**
     * >> CMS module: Payment
     *
     * Get user deposit statistics
     */
    depositStatistics: DepositPlayerPaymentStatistics;
    /**
     * >> CMS module: Player
     *
     *
     */
    diiaAuthorizationDeeplink: DiiaDeeplink;
    /**
     * >> CMS module: Player
     *
     *
     */
    diiaDeeplink: DiiaDeeplink;
    /**
     * >> CMS module: Player
     *
     * Request - abstraction for object representing state of document sharing / authorization process.
     * Throws error when request is not found.
     *
     * Requires full authentication.
     */
    diiaDocumentRequestStatus: DiiaRequestStatusResultType;
    /**
     * >> CMS module: Player
     *
     * Request - abstraction for object representing state of document sharing / authorization process.
     * Throws error when request is not found.
     *
     * Requires full authentication.
     * @deprecated use `diiaDocumentRequestStatus` query instead
     */
    diiaRequestStatus: DiiaRequestStatusResultType;
    /**
     * >> CMS module: KYC
     *
     * KYC documents
     */
    document: DocumentGetType;
    /**
     * >> CMS module: KYC
     *
     * KYC documents
     */
    documents: DocumentListType;
    /**
     * >> CMS module: RedisRouting
     *
     * CMS provides the ability to control routing for access to CMS entities in the admin area
     * (e.g. introduce dynamic route with probably any route).
     * To properly support dynamic routes - you need to use this GQL query.
     * In result - you will know whether there is dynamic route for some path and info about related entity
     * (news, game, etc).
     *
     * Attention: will return no entity data if there is existent dynamic route for provided path BUT
     * entity is not published at all or for requested locale.
     *
     * path example: /en/example
     *
     * See wiki docs for additional info about CMS GraphQL.
     */
    dynamicRouteByPath: DynamicRouteResult;
    /**
     * >> CMS module: Games
     *
     * Param "sortByBetSum" is deprecated. Use "sortKeyByStatistic" instead
     */
    favoriteGames: GamesList;
    /**
     * >> CMS module: Games
     *
     *
     */
    favoriteGamesVendors: Array<GameVendor>;
    /**
     * >> CMS module: Games
     *
     *
     */
    favoriteThemes: Array<FrontendGqlLocalizedTheme>;
    /**
     * >> CMS module: FeatureFlags
     *
     * Platform feature flags
     */
    featureFlags: Array<Participant>;
    /**
     * >> CMS module: Player
     *
     * Provides information about current first required deposit flow for authenticated player.
     * Generates new paseto token if flow was initiated before (for auth token) and finished for now.
     *
     * Works only for fully authenticated players (with paseto token)..
     */
    firstRequiredDepositFlow: FirstRequiredDepositFlowType;
    /**
     * >> CMS module: Games
     *
     *
     */
    freeSpins: Array<FreeSpin>;
    /**
     * >> CMS module: Games
     *
     *
     */
    game?: Maybe<Game>;
    /**
     * >> CMS module: Games
     *
     *
     */
    gameActualMetadata: Array<GameActualMetadata>;
    /**
     * >> CMS module: Games
     *
     *
     */
    gameBySlug?: Maybe<Game>;
    /**
     * >> CMS module: GameJournal
     *
     *
     */
    gameJournalEntriesByRoundId: GameJournalEntriesListType;
    /**
     * >> CMS module: GameJournal
     *
     *
     */
    gameJournalEntriesList: GameJournalEntriesListType;
    /**
     * >> CMS module: GameJournal
     *
     *
     */
    gameJournalRoundsList: GameJournalRoundsListType;
    /**
     * >> CMS module: Games
     *
     * Allows you to get a list of themes that have games
     * ```
     * query {
     *   gameThemes(filteredByCategorySlug: "all-games", filteredByVendorIds: ["vendor-id"]) {
     *     title
     *     slug
     *     imageId
     *   }
     * }
     * ```
     */
    gameThemes: Array<FrontendGqlLocalizedTheme>;
    /**
     * >> CMS module: Games
     *
     * Param "sortByBetSum" is deprecated. Use "sortKeyByStatistic" instead
     */
    games: GamesList;
    /**
     * >> CMS module: Games
     *
     *
     */
    gamesByIds: GamesList;
    /**
     * >> CMS module: Games
     *
     *
     */
    gamesCategories: Array<Category>;
    /**
     * >> CMS module: Games
     *
     *
     */
    gamesCategory?: Maybe<Category>;
    /**
     * >> CMS module: Games
     *
     *
     */
    gamesCategoryByDefaultSlug?: Maybe<Category>;
    /**
     * >> CMS module: Games
     *
     *
     */
    gamesCategoryBySlug?: Maybe<Category>;
    /**
     * >> CMS module: Games
     *
     *
     */
    gamesVendors: Array<GameVendor>;
    /**
     * >> CMS module: GameStatistics
     *
     *
     */
    gamificationPlayerStatistics: GamificationPlayerStatistics;
    /**
     * >> CMS module: GameStatistics
     *
     *
     */
    gamificationPlayerStatisticsByToken: GamificationPlayerStatistics;
    /**
     * >> CMS module: GameStatistics
     *
     *
     */
    gamificationPlayerStatisticsPublicPageUrl: Scalars['String'];
    /**
     * >> CMS module: GeoIp
     *
     * Used to obtain user geodata based on the request IP address, such as country code or city name
     */
    geoIpLocation: GeoIpLocationType;
    /**
     * >> CMS module: GtmDataLayer
     *
     *
     */
    gtmDataInfo: InfoResultType;
    /**
     * >> CMS module: Games
     *
     * List of the best players for a specific time period. Filtered by year/month.
     */
    hallOfFame: HallOfFameResultType;
    /**
     * >> CMS module: HideContacts
     *
     * (email, phone) from hide contacts if settings for current mirror and locale exists
     */
    hideContacts: HideContactsResultType;
    /**
     * >> CMS module: Player
     *
     *
     */
    idGovUaGenerateLink: IdGovUaIntegrationServiceGenerateLinkResult;
    /**
     * >> CMS module: Player
     *
     *
     */
    inviteFriendsReferralInfo: InviteFriendsReferralInfoType;
    /**
     * >> CMS module: Features
     *
     *
     */
    isFeatureAvailable: FeatureAvailableResult;
    /**
     * >> CMS module: KeyValue
     *
     * Throws error in case of not found settings by key AND no default value provided
     */
    keyValue: KeyValueType;
    /**
     * >> CMS module: KYC
     *
     * Information related to the KYC(Know Your Customer) process, ensuring compliance with regulatory requirements and verifying the identity of users
     */
    kyc: KycType;
    /**
     * >> CMS module: Player
     *
     *
     */
    kycaidSettings: KycaidSettingsType;
    /**
     * >> CMS module: Player
     *
     * Start verification request via `kycaid` system
     */
    kycaidVerificationRequest: KycaidVerificationRequestResult;
    /**
     * >> CMS module: Player
     *
     * Fetch information about processed verifications via `kycaid` system
     */
    kycaidVerifications: KycaidVerificationsResult;
    /**
     * >> CMS module: Games
     *
     * Param "sortByBetSum" is deprecated. Use "sortKeyByStatistic" instead
     */
    lastPlayedGames: GamesList;
    /**
     * >> CMS module: Games
     *
     *
     */
    lastPlayedGamesVendors: Array<GameVendor>;
    /**
     * >> CMS module: Menu
     *
     *
     */
    localeMenu?: Maybe<LocalizedMenu>;
    /**
     * >> CMS module: Locale
     *
     *
     */
    localeSwitcher: LocaleSwitcherType;
    /**
     * >> CMS module: Locale
     *
     *
     */
    locales: Array<Scalars['String']>;
    /**
     * >> CMS module: Loyalty
     *
     *
     */
    loyaltyProgramConfigurations: LoyaltyProgramConfigurationsResultType;
    /**
     * >> CMS module: Loyalty
     *
     * Manages the multipliers associated with the loyalty program, which enhance the rewards or benefits earned by players
     */
    loyaltyProgramMultipliers: Array<LoyaltyPointsMultiplier>;
    /**
     * >> CMS module: Loyalty
     *
     *
     */
    loyaltyProgramParticipants: Array<LoyaltyProgramParticipant>;
    /**
     * >> CMS module: Loyalty
     *
     * Returns statistic by player id, and optionally N players before and after specified player, sorted by position
     */
    loyaltyProgramParticipantsByPlayerId: Array<LoyaltyProgramParticipant>;
    /**
     * >> CMS module: Loyalty
     *
     * Returns common brand statistic by player id, and optionally N players before and after specified player, sorted by position
     */
    loyaltyProgramParticipantsCommon: Array<LoyaltyProgramParticipant>;
    /**
     * >> CMS module: Loyalty
     *
     * Provides detailed information about a player's participation in the loyalty program
     */
    loyaltyProgramPlayerData?: Maybe<LoyaltyProgramPlayerData>;
    /**
     * >> CMS module: Loyalty
     *
     *
     */
    loyaltyProgramSettings: LoyaltyProgramSettings;
    /**
     * >> CMS module: Loyalty
     *
     *
     */
    loyaltyProgramStatuses: Array<LoyaltyProgramStatus>;
    /**
     * >> CMS module: Loyalty
     *
     * Provides an overview of the total data related to the loyalty program
     */
    loyaltyProgramTotalData?: Maybe<LoyaltyProgramTotalData>;
    /**
     * >> CMS module: Games
     *
     *
     */
    luckySpinsTopBetCoefficients: Array<LuckySpinWithBetCoefficientApiType>;
    /**
     * >> CMS module: Games
     *
     *
     */
    luckySpinsTopRoundProfits: Array<LuckySpinWithRoundProfitApiType>;
    /**
     * >> CMS module: Message
     *
     * Note: fetching message changes 'read' message status (platform logic)
     */
    message?: Maybe<Message>;
    /**
     * >> CMS module: Message
     *
     * Returns a list of messages for a specific user
     * ```
     * query {
     *   messages (targetPlatform: DESKTOP_WEBSITE, createdFrom: "2022-11-10", isRead: false) {
     *     id
     *     title
     *     createdAt
     *     priority
     *   }
     * }
     * ```
     */
    messages: Array<Message>;
    /**
     * >> CMS module: Message
     *
     * Returns messages info for a specific user
     * ```
     * query {
     *   messagesInfo(targetPlatform: DESKTOP_WEBSITE, createdFrom: "2022-11-10") {
     *     total
     *     unread
     *     position
     *     message {
     *       id
     *     }
     *   }
     * }
     * ```
     */
    messagesInfo: MessagesInfoResult;
    /**
     * >> CMS module: MobileAppSettings
     *
     *
     */
    mobileAppSettings: Array<MobileSettings>;
    /**
     * >> CMS module: MobilePushNotification
     *
     * returns list of player subscriptions
     */
    mobilePushSubscriptions: MobilePushSubscriptionsType;
    /**
     * >> CMS module: News
     *
     *
     */
    news: SearchNewsResultType;
    /**
     * >> CMS module: News
     *
     *
     */
    newsCategories: SearchNewsCategoriesResultType;
    /**
     * >> CMS module: News
     *
     * Throws error if news not found or not published for current locale (code: "news.not.found", category: "Gin")
     */
    newsDetail: NewsLocalized;
    /**
     * >> CMS module: Tag
     *
     *
     */
    newsTags: Array<TagLocalized>;
    /**
     * >> CMS module: FeatureFlags
     *
     * Feature flags which where configured in cms admin page
     */
    ownFeatureFlags: Array<OwnFeatureFlag>;
    /**
     * >> CMS module: Pages
     *
     * $ids - The array of slugs
     * @deprecated use pagesByIds or pagesBySlugs query instead
     */
    pages: Array<PageLocalized>;
    /**
     * >> CMS module: Pages
     *
     * $ids The array of page ids
     */
    pagesByIds: Array<PageLocalized>;
    /**
     * >> CMS module: Pages
     *
     * $slugs The array of slugs
     */
    pagesBySlugs: Array<PageLocalized>;
    /**
     * >> CMS module: Payment
     *
     * Default sort order is from newest to oldest. Set reverseOrder true to sort from oldest to newest
     */
    paymentHistory: PaymentHistoryResult;
    /**
     * >> CMS module: Payment
     *
     * Get user payment history by exact transactionId
     */
    paymentHistoryByTransactionId?: Maybe<PaymentHistoryTransaction>;
    /**
     * >> CMS module: Payment
     *
     * Retrieve commission details about payout via payment system option
     */
    payoutCommissionForSystem: PayoutCommissionForSystemType;
    /**
     * >> CMS module: Payment
     *
     *
     */
    payoutMethods: PayoutMethodsType;
    /**
     * >> CMS module: Achievement
     *
     * Player Achievements list which player have progress or already achieved
     * If achievement is disabled on platform, it will still be in the list.
     * Requires authentication.
     *
     * Dev note: pagination can be broken because "no content" achievements will be filtered our without auto-requested
     * next page to fulfill requested limit.
     */
    playerAchievements: PlayerAchievementsResultType;
    /**
     * >> CMS module: Limits
     *
     * List of currently actual limits that have not expired (finishAt is null OR finishAt > CURRENT_TIME)
     * type parameter example: `losses|wager|gaming-time|activity-time|deposit|break-time`
     */
    playerActuallyLimits: Array<Limit>;
    /**
     * >> CMS module: Player
     *
     * Returns player auth settings stored on platform
     */
    playerAuthSettings: PlayerAuthSettings;
    /**
     * >> CMS module: Achievement
     *
     * Player available achievements list which player can achieve or already achieved or have progress
     * If achievement is disabled on platform, it will not be in the list.
     * Requires authentication.
     */
    playerAvailableAchievements: PlayerAvailableAchievementsResultType;
    /**
     * >> CMS module: Player
     *
     * Information about user balances.
     * Includes information about real and bonus balances
     */
    playerBalances?: Maybe<PlayerBalancesResultType>;
    /**
     * >> CMS module: Player
     *
     * Includes information about the history of the user's bonuses for a certain period
     */
    playerBonusBalanceJournal: PlayerBalanceJournalEntryListType;
    /**
     * >> CMS module: Limits
     *
     * List of player limits. Will return for all types unless you set the exact value of the type.
     * $type parameter example: `losses|wager|gaming-time|activity-time|deposit|break-time`
     */
    playerLimits: Array<Limit>;
    /**
     * >> CMS module: Limits
     *
     * List of all player limits by type
     * $type parameter example: `losses|wager|gaming-time|activity-time|deposit|break-time`
     */
    playerLimitsByType: LimitListResultType;
    /**
     * >> CMS module: Limits
     *
     * ```
     * Get list of player limits(list of current state of limits).
     * To describe in more detail what a limit state is, consider an example
     * Note: the actual behavior of the limit may differ from the current one.
     *
     * The example is for demonstration purposes only:
     *  - Limit: deposit (maximum deposit amount for some period of time)
     *  - Period: 1 day (per day)
     *  - Money: 100 EUR
     *  - Now: 2022-10-11T10:25:00+00:00:00
     * Player deposited today 70 EUR and want to deposit again for some amount.
     * Client application can try to resolve what amount of deposit player will be allowed to proceed.
     *
     * query {
     *   playerLimitsHistory(type: "deposit") {
     *     period
     *     createdAt
     *     finishAt
     *     money {
     *       field
     *       value
     *     }
     *   }
     * }
     *
     * Response:
     *
     * {
     *     "data": {
     *         "playerLimitsHistory": [
     *             {
     *                 "period": "day",
     *                 "createdAt": "2022-10-11T00:00:00+00:00",
     *                 "finishAt": "2022-10-12T00:00:00+00:00",
     *                 "money": [
     *                     {
     *                         "field": "amount",
     *                         "value": "70"
     *                     },
     *                     {
     *                         "field": "currency_code",
     *                         "value": "EUR"
     *                     }
     *                 ]
     *             },
     *             {
     *                 "period": "day",
     *                 "createdAt": "2022-09-19T00:00:00+00:00",
     *                 "finishAt": "2022-09-20T00:00:00+00:00",
     *                 "money": [
     *                     {
     *                         "field": "amount",
     *                         "value": "50"
     *                     },
     *                     {
     *                         "field": "currency_code",
     *                         "value": "EUR"
     *                     }
     *                 ]
     *             }
     *         ]
     *     }
     * }
     * ```
     */
    playerLimitsHistory: Array<LimitHistory>;
    /**
     * >> CMS module: Limits
     *
     * List of all player limits by type
     * $type parameter example: `losses|wager|gaming-time|activity-time|deposit|break-time`
     * @deprecated use `playerLimitsByType` query instead
     */
    playerLimitsList: LimitListResultType;
    /**
     * >> CMS module: Limits
     *
     * List of currently not actual (finished) limits (finishAt is not null OR finishAt <= CURRENT_TIME)
     * $type parameter example: `losses|wager|gaming-time|activity-time|deposit|break-time`
     */
    playerNonActuallyLimits: NonActuallyLimitsResultType;
    /**
     * >> CMS module: Player
     *
     *
     */
    playerPasswordExist: PlayerPasswordResultType;
    /**
     * >> CMS module: Games
     *
     *
     */
    playerSearchGames: GamesList;
    /**
     * >> CMS module: PlayerSegment
     *
     * On platform player can be related to some lists.
     * This query return info about player relation to blacklist
     */
    playerSegmentBlacklist: PlayerSegment;
    /**
     * >> CMS module: PlayerSegment
     *
     * On platform player can be related to some lists.
     * This query return info about player relation to vip list
     */
    playerSegmentVip: PlayerSegment;
    /**
     * >> CMS module: Games
     *
     *
     */
    popularGames: GamesList;
    /**
     * >> CMS module: Profile
     *
     *
     */
    profileInfo: ProfileInfo;
    /**
     * >> CMS module: Promo
     *
     * Use trackers input to track clicks on links. Defaults trackers: [landing_id, ref_code, uuid, crm_notification, actionpay, affdata].
     */
    promoRemoteToken: RemoteSignUpTokenType;
    /**
     * >> CMS module: Promotions
     *
     *
     */
    promotion?: Maybe<PromotionLocalized>;
    /**
     * >> CMS module: Promotions
     *
     *
     */
    promotionList: PromotionLocalizedListType;
    /**
     * >> CMS module: Bonus
     *
     *
     */
    publicBonusPrograms: Array<BonusProgramLocalized>;
    /**
     * >> CMS module: Games
     *
     *
     */
    randomGames: GamesList;
    /**
     * >> CMS module: Games
     *
     *
     */
    recommendedGames: GamesList;
    /**
     * >> CMS module: Player
     *
     *
     */
    referralsHistory: ReferralInvitesResponse;
    /**
     * >> CMS module: News
     *
     *
     */
    relatedNews: RelatedNewsResultType;
    /**
     * >> CMS module: Currency
     *
     *
     */
    relevantCurrency: Scalars['String'];
    /**
     * >> CMS module: Locale
     *
     * The query is useful for determining the user's locale when first visiting the site
     * (when user doesn't provide concrete locale explicitly - for example, in URL).
     * Returns the relevant locale for the user based on certain criteria, such as:
     * - custom header (see docs)
     * - cookie (see docs)
     * - ip address (per-country settings)
     * - `accept-language` header
     * - mirror default locale
     * - project default locale
     */
    relevantLocale: RelevantLocaleResultType;
    /**
     * >> CMS module: KYC
     *
     * KYC required document's list of additional parameters (bank masks, payment system info etc.)
     */
    requiredDocumentParameters: RequiredDocumentParameterListType;
    /**
     * >> CMS module: KYC
     *
     * A complete list of KYC required documents can be uploaded by the user depending on the request.
     */
    requiredDocumentPresets: RequiredDocumentListType;
    /**
     * >> CMS module: KYC
     *
     * History of uploaded KYC required documents
     */
    requiredDocumentUploadedHistory: RequiredDocumentUploadedListType;
    /**
     * >> CMS module: KYC
     *
     * KYC required documents list, that must be uploaded by user to proceed KYC verification.
     */
    requiredDocuments: RequiredDocumentListType;
    /**
     * >> CMS module: KYC
     *
     * KYC required documents processing status
     */
    requiredDocumentsStatus: RequiredDocumentStatusType;
    /**
     * >> CMS module: RouteDisplaySettings
     *
     *
     */
    routeDisplaySettings?: Maybe<RouteDisplaySettings>;
    /**
     * >> CMS module: Seo
     *
     *
     */
    seoBreadcrumbs?: Maybe<Array<BreadcrumbItemResult>>;
    /**
     * >> CMS module: Seo
     *
     *
     */
    seoHeaders: Array<SeoHeaderResultType>;
    /**
     * >> CMS module: Seo
     *
     *
     */
    seoRedirectByPage: SeoRedirectResultType;
    /**
     * >> CMS module: SeoRobots
     *
     *
     */
    seoRobotsRule?: Maybe<SeoRobotsRuleType>;
    /**
     * >> CMS module: Seo
     *
     *
     */
    seoRules: Array<Seo>;
    /**
     * >> CMS module: Seo
     *
     *
     */
    seoRulesByPage: SeoResult;
    /**
     * >> CMS module: Yhelper
     *
     *
     * @deprecated use: chatWidget query instead
     */
    siteheartSettings?: Maybe<SiteheartType>;
    /**
     * >> CMS module: Slider
     *
     *
     */
    slider?: Maybe<Slider>;
    /**
     * >> CMS module: Login4play
     *
     *
     */
    socialButtons: Login4playDataForAnonymousType;
    /**
     * >> CMS module: Login4play
     *
     *
     */
    socialButtonsAttached: Login4playDataForRegistered;
    /**
     * >> CMS module: Login4play
     *
     *
     */
    socialNetworks: Array<Scalars['String']>;
    /**
     * >> CMS module: StructuredPage
     *
     * Structured page contains json data based on preconfigured json schemas for brand
     */
    structuredPage: LocalizedStructuredPage;
    /**
     * >> CMS module: StructuredPage
     *
     * This query allows you to get a localized structural page by id.
     * Please use this query only if you are sure that you need a page with your hardcoded id (if you can't use slug or you are going to reuse structured data globally, not only for some specific page).
     */
    structuredPageById: LocalizedStructuredPage;
    /**
     * >> CMS module: SubLogo
     *
     *
     */
    subLogo?: Maybe<SubLogoLocalized>;
    /**
     * >> CMS module: TeamRace
     *
     * List of participants.
     * ```
     *
     * TournamentParticipantsInput:
     * tournamentId: String - Team race tournament id.
     * last: Bool - Get only last round participant.
     *
     * ```
     */
    teamRaceTournamentParticipants: TournamentTeamRaceParticipants;
    /**
     * >> CMS module: TeamRace
     *
     * Get round info of team-race tournament
     * ```
     * TournamentRoundInfoInput:
     * tournamentId: String - Team race tournament id.
     * roundId: String - Round id.
     * previous: Bool - Get previous round if roundId present.
     * sort: TeamRaceSortTypeEnum - Sorting parameter for places.
     * ```
     */
    teamRaceTournamentRoundInfo: TournamentTeamRaceRounds;
    /**
     * >> CMS module: TeamRace
     *
     * Get rounds info of team-race tournament
     * ```
     *
     * TournamentRoundsInfoInput:
     *  tournamentId: String - Team race tournament id.
     *  last: Bool - Get only last round.
     *  sort: TeamRaceSortTypeEnum - Sorting parameter for places.
     *
     * ```
     */
    teamRaceTournamentRoundsInfo: TournamentTeamRaceRounds;
    /**
     * >> CMS module: TermsConditions
     *
     * ```
     * query {
     *   termsAndConditionsLogs {
     *     getType
     *     getLogs {
     *       getId
     *       getLog
     *       getVersion
     *     }
     *   }
     * }
     * ```
     * @deprecated Use `unacceptedTermsAndConditionsLogs` query instead
     */
    termsAndConditionsLogs: Array<FrontendServiceResult>;
    /**
     * >> CMS module: News
     *
     *
     */
    topCategoryList: NewsByCategory;
    /**
     * >> CMS module: Games
     *
     *
     */
    topSearchGames: GamesList;
    /**
     * >> CMS module: Tournaments
     *
     *
     */
    tournamentById?: Maybe<TournamentLocalized>;
    /**
     * >> CMS module: Tournaments
     *
     *
     */
    tournamentBySlug?: Maybe<TournamentLocalized>;
    /**
     * >> CMS module: Tournaments
     *
     *
     */
    tournamentByTitle?: Maybe<TournamentLocalized>;
    /**
     * >> CMS module: Tournaments
     *
     *
     */
    tournamentParticipantCount: Scalars['Int'];
    /**
     * >> CMS module: Tournaments
     *
     * Complete information about tournaments based on special filters
     */
    tournamentsByFilters: Array<TournamentLocalized>;
    /**
     * >> CMS module: Payment
     *
     *
     */
    transactionLink: PaymentLink;
    /**
     * >> CMS module: TermsConditions
     *
     * Displays unaccepted (not yet accepted) terms and conditions by authenticated player.
     * ```
     * query {
     *   unacceptedTermsAndConditionsLogs {
     *     logs {
     *       type
     *       logs {
     *         id
     *         log
     *         version
     *       }
     *     }
     *   }
     * }
     * ```
     */
    unacceptedTermsAndConditionsLogs: UnacceptedLogsResultType;
    /**
     * >> CMS module: Player
     *
     *
     */
    user?: Maybe<SymfonyUserInterface>;
    /**
     * >> CMS module: WebPushNotification
     *
     * Settings for chrome / safari / mobile push notifications
     */
    webPushNotificationSettings: WebPushSettingsResultType;
    /**
     * >> CMS module: WheelOfFortune
     *
     *
     */
    wheelOfFortuneFreeGames?: Maybe<WheelOfFortuneFreeGames>;
    /**
     * >> CMS module: WheelOfFortune
     *
     *
     */
    wheelOfFortuneInfo?: Maybe<WheelOfFortune>;
    /**
     * >> CMS module: WheelOfFortune
     *
     *
     */
    wheelOfFortuneStatus?: Maybe<WheelOfFortune>;
    /**
     * >> CMS module: Widget
     *
     *
     */
    widgetByName?: Maybe<WidgetLocalized>;
    /**
     * >> CMS module: Games
     *
     *
     */
    winners: Array<Winner>;
    /**
     * >> CMS module: Games
     *
     *
     */
    winnersByPeriod: Array<Winner>;
};

export type QueryBankIdVerificationRequestArgs = {
    redirectPath?: InputMaybe<Scalars['String']>;
};

export type QueryBannersArgs = {
    ids: Array<Scalars['String']>;
};

export type QueryBettingPredictionsArgs = {
    limit: Scalars['Int'];
    offset: Scalars['Int'];
};

export type QueryBettingSportByIdArgs = {
    id: Scalars['String'];
};

export type QueryBettingTournamentSettingsByIdArgs = {
    id: Scalars['String'];
};

export type QueryBonusHistoryArgs = {
    dateFrom: Scalars['String'];
    dateTo: Scalars['String'];
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type QueryBonusProgramByIdArgs = {
    id: Scalars['String'];
};

export type QueryBonusProgramsArgs = {
    archivedLimit?: InputMaybe<Scalars['Int']>;
    filter?: InputMaybe<BonusProgramListFilterInput>;
    templateIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryBonusProgramsByIdsArgs = {
    ids: Array<Scalars['String']>;
};

export type QueryBonusProgramsFullArchivedArgs = {
    filter?: InputMaybe<TypedBonusProgramListFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    templateIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryBonusProgramsFullAvailableArgs = {
    filter?: InputMaybe<TypedBonusProgramListFilterInput>;
};

export type QueryBonusProgramsFullProcessingArgs = {
    filter?: InputMaybe<TypedBonusProgramListFilterInput>;
};

export type QueryBonusQuestsArgs = {
    archivedLimit?: InputMaybe<Scalars['Int']>;
    filter?: InputMaybe<BonusProgramListFilterInput>;
    templateIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryBonusesArgs = {
    categoryIds?: Array<Scalars['String']>;
    excludeIds?: Array<Scalars['String']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    tagIds?: Array<Scalars['String']>;
};

export type QueryBreadcrumbsArgs = {
    categoryId: Scalars['String'];
};

export type QueryCaptchaArgs = {
    captchaContext?: InputMaybe<CaptchaContext>;
    contextInput?: InputMaybe<CaptchaTokenInput>;
};

export type QueryCategoryNewsArgs = {
    categoryId: Scalars['String'];
};

export type QueryCheckRecoveryTokenArgs = {
    token: Scalars['String'];
};

export type QueryCheckRedeemCodeArgs = {
    code: Scalars['String'];
};

export type QueryCountryByUserIpArgs = {
    default?: InputMaybe<Scalars['String']>;
};

export type QueryDiiaDeeplinkArgs = {
    requestId?: InputMaybe<Scalars['String']>;
};

export type QueryDiiaDocumentRequestStatusArgs = {
    requestId: Scalars['String'];
};

export type QueryDiiaRequestStatusArgs = {
    requestId: Scalars['String'];
};

export type QueryDocumentArgs = {
    id: Scalars['String'];
};

export type QueryDynamicRouteByPathArgs = {
    path: Scalars['String'];
};

export type QueryFavoriteGamesArgs = {
    gameStatisticsSortKey?: InputMaybe<GameStatisticsSortKeyEnum>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    sortByBetSum?: InputMaybe<Scalars['Boolean']>;
    vendorIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryGameArgs = {
    id: Scalars['String'];
};

export type QueryGameActualMetadataArgs = {
    gameIds: Array<Scalars['String']>;
};

export type QueryGameBySlugArgs = {
    slug: Scalars['String'];
};

export type QueryGameJournalEntriesByRoundIdArgs = {
    roundId: Scalars['String'];
};

export type QueryGameJournalEntriesListArgs = {
    dateFrom: Scalars['DateTime'];
    dateTo: Scalars['DateTime'];
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type QueryGameJournalRoundsListArgs = {
    dateFrom: Scalars['DateTime'];
    dateTo: Scalars['DateTime'];
    filters?: InputMaybe<FiltersInputTypeInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type QueryGameThemesArgs = {
    filteredByCategorySlug?: InputMaybe<Scalars['String']>;
    filteredByVendorIds?: Array<Scalars['String']>;
};

export type QueryGamesArgs = {
    categorySlug?: InputMaybe<Scalars['String']>;
    filterByThemeSlug?: InputMaybe<Scalars['String']>;
    gameStatisticsSortKey?: InputMaybe<GameStatisticsSortKeyEnum>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    searchStrategy?: InputMaybe<GameSearchStrategyEnum>;
    sortByBetSum?: InputMaybe<Scalars['Boolean']>;
    vendorIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryGamesByIdsArgs = {
    ids: Array<Scalars['String']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type QueryGamesCategoriesArgs = {
    limit?: InputMaybe<Scalars['Int']>;
};

export type QueryGamesCategoryArgs = {
    id: Scalars['String'];
};

export type QueryGamesCategoryByDefaultSlugArgs = {
    locale: Scalars['String'];
    slug: Scalars['String'];
};

export type QueryGamesCategoryBySlugArgs = {
    slug: Scalars['String'];
};

export type QueryGamesVendorsArgs = {
    categorySlug?: InputMaybe<Scalars['String']>;
};

export type QueryGamificationPlayerStatisticsByTokenArgs = {
    token: Scalars['String'];
};

export type QueryGamificationPlayerStatisticsPublicPageUrlArgs = {
    scopes: Array<ScopeEnumType>;
};

export type QueryHallOfFameArgs = {
    month?: InputMaybe<Scalars['Int']>;
    year?: InputMaybe<Scalars['Int']>;
};

export type QueryIsFeatureAvailableArgs = {
    featureName: Scalars['String'];
};

export type QueryKeyValueArgs = {
    default?: InputMaybe<Scalars['String']>;
    key: Scalars['String'];
};

export type QueryKycaidVerificationRequestArgs = {
    kycaidVerificationType?: InputMaybe<KycaidVerificationTypeEnum>;
    redirectPath?: InputMaybe<Scalars['String']>;
};

export type QueryLastPlayedGamesArgs = {
    gameStatisticsSortKey?: InputMaybe<GameStatisticsSortKeyEnum>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    sortByBetSum?: InputMaybe<Scalars['Boolean']>;
    vendorIds?: Array<Scalars['String']>;
};

export type QueryLocaleMenuArgs = {
    id: Scalars['String'];
    locale: Scalars['String'];
};

export type QueryLocaleSwitcherArgs = {
    path: Scalars['String'];
};

export type QueryLoyaltyProgramParticipantsArgs = {
    limit: Scalars['Int'];
    offset?: InputMaybe<Scalars['Int']>;
    statusName?: InputMaybe<Scalars['String']>;
};

export type QueryLoyaltyProgramParticipantsByPlayerIdArgs = {
    countPlayersAfter?: InputMaybe<Scalars['Int']>;
    countPlayersBefore?: InputMaybe<Scalars['Int']>;
    playerId: Scalars['String'];
};

export type QueryLoyaltyProgramParticipantsCommonArgs = {
    countPlayersAfter?: InputMaybe<Scalars['Int']>;
    countPlayersBefore?: InputMaybe<Scalars['Int']>;
    playerId: Scalars['String'];
};

export type QueryLuckySpinsTopBetCoefficientsArgs = {
    period?: InputMaybe<Period>;
    platform?: InputMaybe<Platform>;
};

export type QueryLuckySpinsTopRoundProfitsArgs = {
    period?: InputMaybe<Period>;
    platform?: InputMaybe<Platform>;
};

export type QueryMessageArgs = {
    id: Scalars['String'];
};

export type QueryMessagesArgs = {
    createdFrom?: InputMaybe<Scalars['DateTime']>;
    isRead?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    targetPlatform?: InputMaybe<MessageTargetPlatformTypeEnum>;
};

export type QueryMessagesInfoArgs = {
    createdFrom?: InputMaybe<Scalars['DateTime']>;
    targetPlatform?: InputMaybe<MessageTargetPlatformTypeEnum>;
};

export type QueryMobileAppSettingsArgs = {
    input: MobileAppSettingsInput;
};

export type QueryNewsArgs = {
    input?: InputMaybe<SearchNewsInputTypeInput>;
};

export type QueryNewsCategoriesArgs = {
    input: SearchNewsCategoriesInputTypeInput;
};

export type QueryNewsDetailArgs = {
    id: Scalars['String'];
};

export type QueryNewsTagsArgs = {
    tagsIds?: Array<Scalars['String']>;
};

export type QueryPagesArgs = {
    ids: Array<Scalars['String']>;
};

export type QueryPagesByIdsArgs = {
    ids: Array<Scalars['String']>;
};

export type QueryPagesBySlugsArgs = {
    slugs: Array<Scalars['String']>;
};

export type QueryPaymentHistoryArgs = {
    dateFrom?: InputMaybe<Scalars['DateTime']>;
    dateTo?: InputMaybe<Scalars['DateTime']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    reverseOrder?: InputMaybe<Scalars['Boolean']>;
    statuses?: InputMaybe<Array<Scalars['String']>>;
    type?: InputMaybe<PaymentMethodTypeEnum>;
};

export type QueryPaymentHistoryByTransactionIdArgs = {
    transactionId: Scalars['String'];
};

export type QueryPayoutCommissionForSystemArgs = {
    input: PayoutCommissionForSystemInput;
};

export type QueryPlayerAchievementsArgs = {
    isOpened?: InputMaybe<Scalars['Boolean']>;
    isViewed?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type QueryPlayerActuallyLimitsArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    type?: InputMaybe<Scalars['String']>;
};

export type QueryPlayerBonusBalanceJournalArgs = {
    dateFrom: Scalars['String'];
    dateTo: Scalars['String'];
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type QueryPlayerLimitsArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    type?: InputMaybe<Scalars['String']>;
};

export type QueryPlayerLimitsByTypeArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    type: Scalars['String'];
};

export type QueryPlayerLimitsHistoryArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    type: Scalars['String'];
};

export type QueryPlayerLimitsListArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    type: Scalars['String'];
};

export type QueryPlayerNonActuallyLimitsArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    type?: InputMaybe<Scalars['String']>;
};

export type QueryPlayerSearchGamesArgs = {
    gameStatisticsSortKey?: InputMaybe<GameStatisticsSortKeyEnum>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    vendorIds?: Array<Scalars['String']>;
};

export type QueryPopularGamesArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type QueryPromoRemoteTokenArgs = {
    remoteSignUpInput: PromoRemoteSignUpInput;
};

export type QueryPromotionArgs = {
    id: Scalars['String'];
};

export type QueryPromotionListArgs = {
    type?: InputMaybe<Scalars['String']>;
};

export type QueryPublicBonusProgramsArgs = {
    filter?: InputMaybe<PublicBonusProgramListFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type QueryRandomGamesArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type QueryRecommendedGamesArgs = {
    excludeIds?: Array<Scalars['String']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type QueryReferralsHistoryArgs = {
    dateFrom?: InputMaybe<Scalars['DateTime']>;
    dateTo?: InputMaybe<Scalars['DateTime']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type QueryRelatedNewsArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    locale: Scalars['String'];
    newsId: Scalars['String'];
    tagsIds: Array<Scalars['String']>;
};

export type QueryRouteDisplaySettingsArgs = {
    path?: InputMaybe<Scalars['String']>;
    routeId?: InputMaybe<Scalars['String']>;
};

export type QuerySeoBreadcrumbsArgs = {
    id: Scalars['String'];
};

export type QuerySeoHeadersArgs = {
    path: Scalars['String'];
};

export type QuerySeoRedirectByPageArgs = {
    pageUrl: Scalars['String'];
};

export type QuerySeoRobotsRuleArgs = {
    url: Scalars['String'];
};

export type QuerySeoRulesByPageArgs = {
    pageUrl: Scalars['String'];
};

export type QuerySliderArgs = {
    id: Scalars['String'];
};

export type QuerySocialButtonsArgs = {
    params?: InputMaybe<SocialButtonsLoginParamsInput>;
    redirectUrl: Scalars['String'];
};

export type QueryStructuredPageArgs = {
    slug: Scalars['String'];
};

export type QueryStructuredPageByIdArgs = {
    id: Scalars['String'];
};

export type QueryTeamRaceTournamentParticipantsArgs = {
    participantsInput: TournamentParticipantsInput;
};

export type QueryTeamRaceTournamentRoundInfoArgs = {
    roundInfoInput: TournamentRoundInfoInput;
};

export type QueryTeamRaceTournamentRoundsInfoArgs = {
    roundsInfoInput: TournamentRoundsInfoInput;
};

export type QueryTopCategoryListArgs = {
    categoryId: Scalars['String'];
};

export type QueryTopSearchGamesArgs = {
    gameStatisticsSortKey?: InputMaybe<GameStatisticsSortKeyEnum>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    search?: InputMaybe<Scalars['String']>;
    vendorIds?: Array<Scalars['String']>;
};

export type QueryTournamentByIdArgs = {
    id: Scalars['String'];
};

export type QueryTournamentBySlugArgs = {
    slug: Scalars['String'];
};

export type QueryTournamentByTitleArgs = {
    title: Scalars['String'];
};

export type QueryTournamentParticipantCountArgs = {
    id: Scalars['String'];
};

export type QueryTournamentsByFiltersArgs = {
    active?: InputMaybe<Scalars['Boolean']>;
    finished?: InputMaybe<Scalars['Boolean']>;
    ids?: InputMaybe<Array<Scalars['String']>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    sortName?: InputMaybe<Scalars['String']>;
    sortType?: InputMaybe<Scalars['String']>;
    started?: InputMaybe<Scalars['Boolean']>;
    types?: InputMaybe<Array<Scalars['String']>>;
    vipStatus?: InputMaybe<VipStatusTypeEnum>;
};

export type QueryTransactionLinkArgs = {
    transactionId: Scalars['String'];
};

export type QueryWheelOfFortuneFreeGamesArgs = {
    wheelName: Scalars['String'];
};

export type QueryWheelOfFortuneInfoArgs = {
    currencyCode: Scalars['String'];
    gameType: WheelOfFortuneGameTypeEnum;
    multiplier: Scalars['Decimal'];
    wheelName: Scalars['String'];
};

export type QueryWheelOfFortuneStatusArgs = {
    gameType: WheelOfFortuneGameTypeEnum;
    multiplier: Scalars['Decimal'];
    wheelName: Scalars['String'];
};

export type QueryWidgetByNameArgs = {
    name: Scalars['String'];
};

export type QueryWinnersArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type QueryWinnersByPeriodArgs = {
    gameId?: InputMaybe<Scalars['String']>;
    limit?: InputMaybe<Scalars['Int']>;
    period: Scalars['Int'];
};

export type ReCaptchaOptions = CaptchaOptionsInterface & {
    __typename?: 'ReCaptchaOptions';
    /**
     * Method was added because of type validation.
     * due to gql spec: An Interface type must define one or more fields.
     */
    dummy?: Maybe<Scalars['Int']>;
    publicKey: Scalars['String'];
    reCaptchaVersion: Scalars['Int'];
};

export type ReSignInInput = {
    trackers?: InputMaybe<TrackersInput>;
};

export type ReSignInResultType = {
    __typename?: 'ReSignInResultType';
    /** CMS player token */
    pasetoToken: Scalars['String'];
    /** Token from platform */
    token: Scalars['String'];
};

export type RedirectUrlsInput = {
    redirectBackUrl?: InputMaybe<Scalars['String']>;
    redirectFailUrl?: InputMaybe<Scalars['String']>;
    redirectProgressUrl?: InputMaybe<Scalars['String']>;
    redirectSuccessUrl?: InputMaybe<Scalars['String']>;
};

export type Referral = {
    createdAt: Scalars['String'];
    playerName: Scalars['String'];
};

export type ReferralImpl = Referral & {
    __typename?: 'ReferralImpl';
    createdAt: Scalars['String'];
    playerName: Scalars['String'];
};

export type ReferralInvitesResponse = {
    hasNextPart: Scalars['Boolean'];
    referrals: Array<Referral>;
};

export type ReferralInvitesResponseImpl = ReferralInvitesResponse & {
    __typename?: 'ReferralInvitesResponseImpl';
    hasNextPart: Scalars['Boolean'];
    referrals: Array<Referral>;
};

export type RegisterWebPushSubscriptionInput = {
    authKey: Scalars['String'];
    endpoint: Scalars['String'];
    p256dhKey: Scalars['String'];
};

export type RelatedNewsResultType = {
    __typename?: 'RelatedNewsResultType';
    newsCategories: Array<NewsCategoryLocalized>;
    newsList: Array<NewsLocalized>;
};

export type RelationalConditionRule = ConditionRuleInterface & {
    __typename?: 'RelationalConditionRule';
    rule: ConditionRuleInterface;
    type: ConditionRuleEnumType;
};

export type RelevantLocaleResultType = {
    __typename?: 'RelevantLocaleResultType';
    locale: Scalars['String'];
};

export type RemoteSignUpTokenType = {
    __typename?: 'RemoteSignUpTokenType';
    /** Token for remote sign up using `signUpByRemoteToken` mutation */
    token: Scalars['String'];
};

export type RequiredDocument = {
    __typename?: 'RequiredDocument';
    /** Document type(snake_case string). Example: `bank_cards` */
    documentType: Scalars['String'];
    /** Identifier of loader group(snake_case string). Example: `bank_card` */
    group: Scalars['String'];
    /** Identifier of group title(snake_case string). */
    groupTitleKey: Scalars['String'];
    kycDocumentType: RequiredDocumentKycTypeEnum;
    /** @deprecated use field `kycDocumentType` instead */
    kycDocumentTypeRawString: Scalars['String'];
};

export enum RequiredDocumentKycTypeEnum {
    AddressProof = 'ADDRESS_PROOF',
    DrivingLicence = 'DRIVING_LICENCE',
    IdPass = 'ID_PASS',
    NationalIdentityCard = 'NATIONAL_IDENTITY_CARD',
    Other = 'OTHER',
    Passport = 'PASSPORT',
    PaymentMethod = 'PAYMENT_METHOD',
    PermanentResidencePermit = 'PERMANENT_RESIDENCE_PERMIT',
    SelfieWithId = 'SELFIE_WITH_ID',
    SourceOfFunds = 'SOURCE_OF_FUNDS',
    SourceOfWealth = 'SOURCE_OF_WEALTH',
    TaxNumber = 'TAX_NUMBER',
}

export type RequiredDocumentListType = {
    __typename?: 'RequiredDocumentListType';
    documents: Array<RequiredDocument>;
};

export type RequiredDocumentParameter = {
    __typename?: 'RequiredDocumentParameter';
    /** Identifier of loader group(snake_case string). */
    group: Scalars['String'];
    /** Type of parameter(snake_case string). */
    type: Scalars['String'];
    /** Any string. Value of parameter. */
    value: Scalars['String'];
};

export type RequiredDocumentParameterListType = {
    __typename?: 'RequiredDocumentParameterListType';
    parameters: Array<RequiredDocumentParameter>;
};

export type RequiredDocumentStatusType = {
    __typename?: 'RequiredDocumentStatusType';
    status: RequiredDocumentStatusTypeEnum;
};

export enum RequiredDocumentStatusTypeEnum {
    StatusUnknown = 'STATUS_UNKNOWN',
    StatusWaitingApproval = 'STATUS_WAITING_APPROVAL',
    StatusWaitingDocuments = 'STATUS_WAITING_DOCUMENTS',
    StatusWaitingRequest = 'STATUS_WAITING_REQUEST',
}

export type RequiredDocumentUploaded = {
    __typename?: 'RequiredDocumentUploaded';
    /** Document type(snake_case string). Example: `bank_cards` */
    documentType: Scalars['String'];
    /** Identifier of loader group(snake_case string). Example: `bank_card` */
    group: Scalars['String'];
    /** Identifier of uploaded document. */
    id: Scalars['String'];
};

export type RequiredDocumentUploadedInput = {
    documentType: Scalars['String'];
    group: Scalars['String'];
    id: Scalars['String'];
};

export type RequiredDocumentUploadedListType = {
    __typename?: 'RequiredDocumentUploadedListType';
    documents: Array<RequiredDocumentUploaded>;
};

export enum RewardImageEnumType {
    Basic = 'BASIC',
}

export type RewardLocalized = {
    __typename?: 'RewardLocalized';
    description: Scalars['String'];
    id: Scalars['String'];
    images: Array<RewardLocalizedImage>;
    rewardType: RewardTypeEnumType;
};

export type RewardLocalizedImage = {
    __typename?: 'RewardLocalizedImage';
    imageId: Scalars['String'];
    type: RewardImageEnumType;
};

export enum RewardTypeEnumType {
    Basic = 'BASIC',
    BonusBalance = 'BONUS_BALANCE',
    FreeSpins = 'FREE_SPINS',
    LoyaltyMultiplier = 'LOYALTY_MULTIPLIER',
    LoyaltyPoints = 'LOYALTY_POINTS',
    RealBalance = 'REAL_BALANCE',
    ScratchLottery = 'SCRATCH_LOTTERY',
    WheelOfFortune = 'WHEEL_OF_FORTUNE',
}

export type RewardsBasicSettings = {
    currencyMap: Array<SettingsCurrencyMap>;
    rewardType: RewardTypeEnumType;
};

export type RewardsBasicSettingsImpl = RewardsBasicSettings & {
    __typename?: 'RewardsBasicSettingsImpl';
    currencyMap: Array<SettingsCurrencyMap>;
    rewardType: RewardTypeEnumType;
};

export type RewardsBonusBalanceSettings = RewardsBasicSettings & {
    __typename?: 'RewardsBonusBalanceSettings';
    currencyMap: Array<SettingsCurrencyMap>;
    rewardType: RewardTypeEnumType;
    timeToLive: Scalars['String'];
    wagerMultiplier?: Maybe<Scalars['Decimal']>;
};

export type RewardsFreeSpinsSettings = RewardsBasicSettings & {
    __typename?: 'RewardsFreeSpinsSettings';
    balanceType: BalanceTypeEnumType;
    currencyMap: Array<SettingsCurrencyMap>;
    gameIds: Array<Scalars['String']>;
    numberOfBets: Scalars['Int'];
    provider: Scalars['String'];
    rewardType: RewardTypeEnumType;
};

export type RewardsLoyaltyMultiplierSettings = RewardsBasicSettings & {
    __typename?: 'RewardsLoyaltyMultiplierSettings';
    currencyMap: Array<SettingsCurrencyMap>;
    rewardType: RewardTypeEnumType;
    timeToLive: Scalars['String'];
};

export type RewardsLoyaltyPointsSettings = RewardsBasicSettings & {
    __typename?: 'RewardsLoyaltyPointsSettings';
    currencyMap: Array<SettingsCurrencyMap>;
    rewardType: RewardTypeEnumType;
};

export type RewardsRealBalanceSettings = RewardsBasicSettings & {
    __typename?: 'RewardsRealBalanceSettings';
    currencyMap: Array<SettingsCurrencyMap>;
    rewardType: RewardTypeEnumType;
    wagerMultiplier?: Maybe<Scalars['Decimal']>;
};

export type RewardsScratchLotterySettings = RewardsBasicSettings & {
    __typename?: 'RewardsScratchLotterySettings';
    currencyMap: Array<SettingsCurrencyMap>;
    rewardType: RewardTypeEnumType;
};

export type RewardsWheelOfFortuneSettings = RewardsBasicSettings & {
    __typename?: 'RewardsWheelOfFortuneSettings';
    currencyMap: Array<SettingsCurrencyMap>;
    rewardType: RewardTypeEnumType;
};

export type Route = {
    path: Scalars['String'];
};

export type RouteDisplaySettings = {
    routeId: Scalars['String'];
    settings: Array<RouteDisplaySettingsValue>;
};

export type RouteDisplaySettingsImpl = RouteDisplaySettings & {
    __typename?: 'RouteDisplaySettingsImpl';
    routeId: Scalars['String'];
    settings: Array<RouteDisplaySettingsValue>;
};

export type RouteDisplaySettingsStringValue = RouteDisplaySettingsValue & {
    __typename?: 'RouteDisplaySettingsStringValue';
    id: Scalars['String'];
    type: Scalars['String'];
    value: Scalars['String'];
};

export type RouteDisplaySettingsValue = {
    id: Scalars['String'];
    type: Scalars['String'];
};

export type RouteDisplaySettingsValueImpl = RouteDisplaySettingsValue & {
    __typename?: 'RouteDisplaySettingsValueImpl';
    id: Scalars['String'];
    type: Scalars['String'];
};

export type RouteImpl = Route & {
    __typename?: 'RouteImpl';
    path: Scalars['String'];
};

export type RoutingRelatedEntityInfo = {
    entityType: Scalars['String'];
    id: Scalars['String'];
};

export type RoutingRelatedEntityInfoImpl = RoutingRelatedEntityInfo & {
    __typename?: 'RoutingRelatedEntityInfoImpl';
    entityType: Scalars['String'];
    id: Scalars['String'];
};

export type SmsLink = PaymentLink & {
    __typename?: 'SMSLink';
    transactionId: PaymentTransactionIdDto;
    type: Scalars['String'];
    url: Scalars['String'];
};

export type SafariWebPushCreateInput = {
    deviceToken: Scalars['String'];
    domain: Scalars['String'];
};

export enum ScopeEnumType {
    Casino = 'CASINO',
    Sport = 'SPORT',
}

export type SearchNewsCategoriesInputTypeInput = {
    newsIds: Array<Scalars['String']>;
};

export type SearchNewsCategoriesResultType = {
    __typename?: 'SearchNewsCategoriesResultType';
    categories: Array<NewsCategoryLocalized>;
};

export type SearchNewsInputTypeInput = {
    categoryIds?: Array<Scalars['String']>;
    categorySlugs?: Array<Scalars['String']>;
    excludedIds?: Array<Scalars['String']>;
    limit?: InputMaybe<Scalars['Int']>;
    name?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
    tagIds?: Array<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
};

export type SearchNewsResultType = {
    __typename?: 'SearchNewsResultType';
    count: Scalars['Int'];
    news: Array<NewsLocalized>;
};

export type SecurityElement = {
    __typename?: 'SecurityElement';
    answer: Scalars['String'];
    question: Scalars['String'];
};

export type SelfExclusionLockPlayerInput = {
    period?: InputMaybe<Scalars['String']>;
};

export type Seo = {
    ampLink: Scalars['String'];
    description: Scalars['String'];
    id: Scalars['String'];
    keywords: Scalars['String'];
    metaTags: Array<SeoMetaTag>;
    mirror?: Maybe<Mirror>;
    pageHeader: Scalars['String'];
    route?: Maybe<Route>;
    texts: Array<SeoText>;
    title: Scalars['String'];
    url: Scalars['String'];
};

export type SeoAlternateLinksResult = {
    __typename?: 'SeoAlternateLinksResult';
    href: Scalars['String'];
    hrefLang: Scalars['String'];
};

export type SeoHeaderResultType = {
    __typename?: 'SeoHeaderResultType';
    key: Scalars['String'];
    value: Scalars['String'];
};

export type SeoImpl = Seo & {
    __typename?: 'SeoImpl';
    ampLink: Scalars['String'];
    description: Scalars['String'];
    id: Scalars['String'];
    keywords: Scalars['String'];
    metaTags: Array<SeoMetaTag>;
    mirror?: Maybe<Mirror>;
    pageHeader: Scalars['String'];
    route?: Maybe<Route>;
    texts: Array<SeoText>;
    title: Scalars['String'];
    url: Scalars['String'];
};

export type SeoInfo = {
    alternateLinkList: Array<SeoAlternateLinksResult>;
    /** Deprecated, use "alternateLinkList" */
    alternateLinks: Array<Scalars['String']>;
    ampLink: Scalars['String'];
    canonical: Scalars['String'];
    description: Scalars['String'];
    keywords: Scalars['String'];
    metaTags: Array<SeoMetaTag>;
    mirrorAnalyticCodes: Array<Scalars['String']>;
    mirrorMetaTags: Scalars['String'];
    text: Scalars['String'];
    title: Scalars['String'];
};

export type SeoInfoImpl = SeoInfo & {
    __typename?: 'SeoInfoImpl';
    alternateLinkList: Array<SeoAlternateLinksResult>;
    /** Deprecated, use "alternateLinkList" */
    alternateLinks: Array<Scalars['String']>;
    ampLink: Scalars['String'];
    canonical: Scalars['String'];
    description: Scalars['String'];
    keywords: Scalars['String'];
    metaTags: Array<SeoMetaTag>;
    mirrorAnalyticCodes: Array<Scalars['String']>;
    mirrorMetaTags: Scalars['String'];
    text: Scalars['String'];
    title: Scalars['String'];
};

export type SeoMetaTag = {
    content: Scalars['String'];
    name: Scalars['String'];
};

export type SeoMetaTagImpl = SeoMetaTag & {
    __typename?: 'SeoMetaTagImpl';
    content: Scalars['String'];
    name: Scalars['String'];
};

export type SeoRedirectFromRequest = {
    __typename?: 'SeoRedirectFromRequest';
    /** @deprecated use "url" field instead */
    path: Scalars['String'];
    statusCode: Scalars['Int'];
    url: Scalars['String'];
};

export type SeoRedirectResultType = {
    __typename?: 'SeoRedirectResultType';
    redirect?: Maybe<SeoRedirectFromRequest>;
};

export type SeoResult = {
    __typename?: 'SeoResult';
    info?: Maybe<SeoInfo>;
    redirect?: Maybe<SeoRedirectFromRequest>;
    seo?: Maybe<Seo>;
};

export type SeoRobotsRuleType = {
    __typename?: 'SeoRobotsRuleType';
    mirrorId: Scalars['String'];
    mirrorRule: Scalars['Boolean'];
    routes: Array<Scalars['String']>;
    rule: Scalars['String'];
    urls: Array<Scalars['String']>;
    userAgent: Scalars['String'];
};

export type SeoText = {
    content: Scalars['String'];
};

export type SeoTextImpl = SeoText & {
    __typename?: 'SeoTextImpl';
    content: Scalars['String'];
};

export type SettingsCurrencyMap = {
    currencyCode: Scalars['String'];
    max?: Maybe<Scalars['Decimal']>;
    min?: Maybe<Scalars['Decimal']>;
};

export type SettingsCurrencyMapImpl = SettingsCurrencyMap & {
    __typename?: 'SettingsCurrencyMapImpl';
    currencyCode: Scalars['String'];
    max?: Maybe<Scalars['Decimal']>;
    min?: Maybe<Scalars['Decimal']>;
};

export type SignInByAppleIdInput = {
    trackers?: InputMaybe<TrackersInput>;
};

export type SignInByTokenInput = {
    trackers?: InputMaybe<TrackersInput>;
};

export type SignInInput = {
    login: Scalars['String'];
    password: Scalars['String'];
    trackers?: InputMaybe<TrackersInput>;
};

export type SignInRecoveryInput = {
    trackers?: InputMaybe<TrackersInput>;
};

export type SignInResultType = {
    __typename?: 'SignInResultType';
    asyncToken: Scalars['String'];
    id: Scalars['String'];
    message: Scalars['String'];
    paseto: Scalars['String'];
    token: Scalars['String'];
    userName: Scalars['String'];
};

export type SignUpFinishInput = {
    trackers?: InputMaybe<TrackersInput>;
};

export type SignUpInput = {
    agreement: Scalars['Boolean'];
    authMethod?: InputMaybe<AuthMethodIdEnum>;
    confirmEmailCallbackUrl?: InputMaybe<Scalars['String']>;
    credentials: CredentialsCollectionInterfaceInput;
    redeemCode?: InputMaybe<Scalars['String']>;
    refCode?: InputMaybe<Scalars['String']>;
    seenScratchLottery?: InputMaybe<Scalars['String']>;
    trackers?: InputMaybe<TrackersInput>;
};

export type SignUpResultType = {
    __typename?: 'SignUpResultType';
    /** Specified if player profile *failed* to be created (e.g. due to duplicated tax number, etc) */
    createProfileErrorCode?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    needEmailConfirmation: Scalars['Boolean'];
    needPhoneConfirmation: Scalars['Boolean'];
    paseto?: Maybe<Scalars['String']>;
    token?: Maybe<Scalars['String']>;
    userName?: Maybe<Scalars['String']>;
};

export type SiteheartType = {
    __typename?: 'SiteheartType';
    scriptUrl: Scalars['String'];
    signature: Scalars['String'];
    widgetId: Scalars['Int'];
};

export type Slide = {
    __typename?: 'Slide';
    content: Scalars['String'];
    /** @deprecated The backend already automatically filters the slides */
    defaultLocale: Scalars['String'];
    endDate: Scalars['DateTime'];
    entity?: Maybe<UnionGameTournamentLocalizedBonusProgramLocalizedPromotionLocalizedCategoryFrontendGqlLocalizedTheme>;
    fields: Array<SlideField>;
    id: Scalars['String'];
    label: Scalars['String'];
    /** @deprecated The backend already automatically filters the slides */
    locale: Scalars['String'];
    /** @deprecated This internal field will be removed in the future */
    mobile?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    publishDate: Scalars['DateTime'];
    /** @deprecated The backend already automatically filters the slides */
    published: Scalars['Boolean'];
    /** @deprecated The backend already automatically filters the slides */
    sliderId: Scalars['String'];
    type: Scalars['String'];
};

export type SlideField = {
    __typename?: 'SlideField';
    key: Scalars['String'];
    value: Scalars['String'];
};

export type Slider = {
    id: Scalars['String'];
    name: Scalars['String'];
    slides: Array<Slide>;
};

export type SliderSlidesArgs = {
    refCode?: InputMaybe<Scalars['String']>;
};

export type SliderImpl = Slider & {
    __typename?: 'SliderImpl';
    id: Scalars['String'];
    name: Scalars['String'];
    slides: Array<Slide>;
};

export type SliderImplSlidesArgs = {
    refCode?: InputMaybe<Scalars['String']>;
};

export type SlotoplexPlayData = PlayData & {
    __typename?: 'SlotoplexPlayData';
    demo: Scalars['Boolean'];
    gameId: Scalars['String'];
    gameProviderName: Scalars['String'];
    gameServerUrl: Scalars['String'];
    gameSizeOptions: GameSizeOptions;
    launchScript: Scalars['String'];
    playUsingBonusBalanceEnabled: Scalars['Boolean'];
};

export type SlotsCountPlayerAchievementProgress =
    PlayerAchievementProgressInterface & {
        __typename?: 'SlotsCountPlayerAchievementProgress';
        /** Retrieves the type of condition rule for the specified player's achievement. */
        conditionType: ConditionRuleEnumType;
        /**
         * Retrieves the current numeric value for the specified player's achievement progress.
         * The current value represents the progress made towards the achievement,
         * while the value for opening the achievement signifies the milestone that must be reached.
         */
        currentValue: Scalars['Int'];
        slots: Array<Scalars['String']>;
        /** Retrieves the type of progress for the specified player's achievement. */
        type: PlayerAchievementProgressEnumType;
        /** Retrieves the numeric value required to achieve the milestone for the specified player's achievement. */
        valueForOpen: Scalars['Int'];
    };

export type SocialButton = {
    __typename?: 'SocialButton';
    href: Scalars['String'];
    isActive: Scalars['Boolean'];
    name: Scalars['String'];
};

export type SocialButtonsLoginParamsInput = {
    currencyCode?: InputMaybe<Scalars['String']>;
    originalLocale?: InputMaybe<Scalars['String']>;
    redeemCode?: InputMaybe<Scalars['String']>;
};

export type SocialLoginInput = {
    currencyCode: Scalars['String'];
    originalLocale?: InputMaybe<Scalars['String']>;
    redeemCode: Scalars['String'];
    token: Scalars['String'];
    trackers?: InputMaybe<TrackersInput>;
};

export type SocialLoginResultType = {
    __typename?: 'SocialLoginResultType';
    asyncToken: Scalars['String'];
    id: Scalars['String'];
    login4playResponse: Login4playResponse;
    message: Scalars['String'];
    paseto: Scalars['String'];
    token: Scalars['String'];
    userName: Scalars['String'];
};

export type StakelogicPlayData = PlayData & {
    __typename?: 'StakelogicPlayData';
    demo: Scalars['Boolean'];
    gameClientJsLibUrl: Scalars['String'];
    gameId: Scalars['String'];
    gameProviderName: Scalars['String'];
    gameServerUrl: Scalars['String'];
    gameSizeOptions: GameSizeOptions;
    playUsingBonusBalanceEnabled: Scalars['Boolean'];
};

export type StartEmailVerificationInput = {
    callbackUrl?: InputMaybe<Scalars['String']>;
    email: Scalars['String'];
};

export type SteamElement = {
    __typename?: 'SteamElement';
    tradeUrl: Scalars['String'];
    uid: Scalars['String'];
};

export type SubLogoImage = {
    imageId?: Maybe<Scalars['String']>;
    type: Scalars['String'];
};

export type SubLogoImageImpl = SubLogoImage & {
    __typename?: 'SubLogoImageImpl';
    imageId?: Maybe<Scalars['String']>;
    type: Scalars['String'];
};

export type SubLogoLocalized = {
    __typename?: 'SubLogoLocalized';
    images: Array<SubLogoImage>;
    link: Scalars['String'];
    title: Scalars['String'];
};

export type SuccessResult = {
    __typename?: 'SuccessResult';
    message: Scalars['String'];
};

export type SymfonyUserImpl = SymfonyUserInterface & {
    __typename?: 'SymfonyUserImpl';
    roles: Array<Scalars['String']>;
    userName: Scalars['String'];
};

export type SymfonyUserInterface = {
    roles: Array<Scalars['String']>;
    userName: Scalars['String'];
};

export type TagLocalized = {
    __typename?: 'TagLocalized';
    id: Scalars['String'];
    isPublished: Scalars['Boolean'];
    locale: Scalars['String'];
    name: Scalars['String'];
    slug: Scalars['String'];
    text: Scalars['String'];
    version: Scalars['String'];
};

export type TaskConnection = {
    taskId: Scalars['String'];
    tasksConnectedTo: Array<Scalars['String']>;
};

export type TaskConnectionImpl = TaskConnection & {
    __typename?: 'TaskConnectionImpl';
    taskId: Scalars['String'];
    tasksConnectedTo: Array<Scalars['String']>;
};

export type TaskDescription = {
    __typename?: 'TaskDescription';
    taskId: Scalars['String'];
    text: Scalars['String'];
};

export type TaskSettingsCollection = {
    authLoginTaskSettings: Array<AuthLoginTaskSettings>;
    betTaskSettings: Array<BetTaskSettings>;
    bettingTaskSettings: Array<BettingTaskSettings>;
    compositeTaskSettings: Array<CompositeTaskSettings>;
    confirmEmailTaskSettings: Array<ConfirmEmailTaskSettings>;
    confirmPhoneTaskSettings: Array<ConfirmPhoneTaskSettings>;
    depositTaskSettings: Array<DepositTaskSettings>;
    dummyTaskSettings: Array<DummyTaskSettings>;
    kycApproveTaskSettings: Array<KycApproveTaskSettings>;
    manualTaskSettings: Array<ManualTaskSettings>;
    /** @deprecated use `taskConnection` from metadata to build orders */
    order: Array<Scalars['String']>;
    winTaskSettings: Array<WinTaskSettings>;
};

export type TaskSettingsCollectionImpl = TaskSettingsCollection & {
    __typename?: 'TaskSettingsCollectionImpl';
    authLoginTaskSettings: Array<AuthLoginTaskSettings>;
    betTaskSettings: Array<BetTaskSettings>;
    bettingTaskSettings: Array<BettingTaskSettings>;
    compositeTaskSettings: Array<CompositeTaskSettings>;
    confirmEmailTaskSettings: Array<ConfirmEmailTaskSettings>;
    confirmPhoneTaskSettings: Array<ConfirmPhoneTaskSettings>;
    depositTaskSettings: Array<DepositTaskSettings>;
    dummyTaskSettings: Array<DummyTaskSettings>;
    kycApproveTaskSettings: Array<KycApproveTaskSettings>;
    manualTaskSettings: Array<ManualTaskSettings>;
    /** @deprecated use `taskConnection` from metadata to build orders */
    order: Array<Scalars['String']>;
    winTaskSettings: Array<WinTaskSettings>;
};

export type TaskSettingsRewards = {
    id: Scalars['String'];
    rewardType: Scalars['String'];
    settings: RewardsBasicSettings;
};

export type TaskSettingsRewardsImpl = TaskSettingsRewards & {
    __typename?: 'TaskSettingsRewardsImpl';
    id: Scalars['String'];
    rewardType: Scalars['String'];
    settings: RewardsBasicSettings;
};

export type TaskTypeImage = {
    id: Scalars['String'];
    type: Scalars['String'];
};

export type TaskTypeImageImpl = TaskTypeImage & {
    __typename?: 'TaskTypeImageImpl';
    id: Scalars['String'];
    type: Scalars['String'];
};

export type TaskTypeLocalized = {
    __typename?: 'TaskTypeLocalized';
    description: Array<TaskDescription>;
    images: Array<TaskTypeImage>;
    name: Scalars['String'];
    taskType: Scalars['String'];
};

export type TaxNumber = {
    __typename?: 'TaxNumber';
    number: Scalars['String'];
    type: Scalars['String'];
};

export enum TaxTypeEnum {
    BrCpf = 'BR_CPF',
    Tin = 'TIN',
}

export enum TeamRaceSortTypeEnum {
    Alphabet = 'ALPHABET',
    Points = 'POINTS',
}

export type TimeLine = {
    expiredAt: Scalars['DateTime'];
};

export type TimeLineImpl = TimeLine & {
    __typename?: 'TimeLineImpl';
    expiredAt: Scalars['DateTime'];
};

export type TournamentLocalized = {
    __typename?: 'TournamentLocalized';
    active: Scalars['Boolean'];
    createdAt: Scalars['DateTime'];
    customType: Scalars['String'];
    description: Scalars['String'];
    fields: Array<TournamentLocalizedFieldType>;
    finished: Scalars['Boolean'];
    finishedAt: Scalars['DateTime'];
    games: Array<Game>;
    hasDesktop: Scalars['Boolean'];
    hasMobile: Scalars['Boolean'];
    id: Scalars['String'];
    imageId: Scalars['String'];
    internalId: Scalars['String'];
    mobileImageId: Scalars['String'];
    /** This field may have a high complexity. Use it with caution. */
    participant?: Maybe<TournamentParticipant>;
    /** This field may have a high complexity. Use it with caution. */
    participantPlace?: Maybe<Scalars['Int']>;
    /** This field may have a high complexity. Use it with caution. */
    participants: Array<TournamentParticipant>;
    participationRequired: Scalars['Boolean'];
    platformTournamentMetadata: TournamentPlatformMetadata;
    playerEmail: Scalars['String'];
    position: Scalars['Int'];
    /** This field may have a high complexity. Use it with caution. */
    prize: Scalars['Float'];
    prizePool: TournamentPrizePool;
    prizePoolType: Scalars['Int'];
    roundsToPlay: Scalars['Int'];
    sidebarImageId: Scalars['String'];
    sidebarMiniImageId: Scalars['String'];
    slug: Scalars['String'];
    started: Scalars['Boolean'];
    startedAt: Scalars['DateTime'];
    texts: Array<TournamentLocalizedText>;
    title: Scalars['String'];
    tournamentType: Scalars['String'];
    type: Scalars['String'];
    /**
     * This field may have a high complexity. Use it with caution.
     * @deprecated Use field `participants` (if you need participants) or `winnersNew` (if you need winners) instead
     */
    winners: Array<TournamentParticipant>;
    /** This field may have a high complexity. Use it with caution. */
    winnersNew: Array<TournamentWinner>;
};

export type TournamentLocalizedGamesArgs = {
    limit?: InputMaybe<Scalars['Int']>;
};

export type TournamentLocalizedFieldType = {
    __typename?: 'TournamentLocalizedFieldType';
    key: Scalars['String'];
    value?: Maybe<Scalars['String']>;
};

export type TournamentLocalizedRoutingRelatedEntity =
    RoutingRelatedEntityInfo & {
        __typename?: 'TournamentLocalizedRoutingRelatedEntity';
        entity: TournamentLocalized;
        entityType: Scalars['String'];
        id: Scalars['String'];
    };

export type TournamentLocalizedText = {
    text: Scalars['String'];
    type: Scalars['String'];
};

export type TournamentLocalizedTextImpl = TournamentLocalizedText & {
    __typename?: 'TournamentLocalizedTextImpl';
    text: Scalars['String'];
    type: Scalars['String'];
};

export type TournamentMetadataCalculation = {
    balanceTypes: Array<Scalars['String']>;
    type: Scalars['String'];
};

export type TournamentMetadataCalculationBetting =
    TournamentMetadataCalculation & {
        __typename?: 'TournamentMetadataCalculationBetting';
        balanceTypes: Array<Scalars['String']>;
        type: Scalars['String'];
    };

export type TournamentMetadataCalculationBettingRatio =
    TournamentMetadataCalculation & {
        __typename?: 'TournamentMetadataCalculationBettingRatio';
        balanceTypes: Array<Scalars['String']>;
        onlyWonBets: Scalars['Boolean'];
        type: Scalars['String'];
    };

export type TournamentMetadataCalculationBettingStake =
    TournamentMetadataCalculation & {
        __typename?: 'TournamentMetadataCalculationBettingStake';
        balanceTypes: Array<Scalars['String']>;
        onlyWonBets: Scalars['Boolean'];
        stakeAmountPerPoint: Array<TournamentMetadataCalculationBettingStakeAmount>;
        type: Scalars['String'];
    };

export type TournamentMetadataCalculationBettingStakeAmount = {
    amount: Scalars['Decimal'];
    currencyCode: Scalars['String'];
};

export type TournamentMetadataCalculationBettingStakeAmountImpl =
    TournamentMetadataCalculationBettingStakeAmount & {
        __typename?: 'TournamentMetadataCalculationBettingStakeAmountImpl';
        amount: Scalars['Decimal'];
        currencyCode: Scalars['String'];
    };

export type TournamentMetadataCalculationBettingTurnover =
    TournamentMetadataCalculation & {
        __typename?: 'TournamentMetadataCalculationBettingTurnover';
        balanceTypes: Array<Scalars['String']>;
        moneyPerPoint: Array<TournamentMetadataCalculationMoney>;
        passingRatio: Scalars['Float'];
        type: Scalars['String'];
    };

export type TournamentMetadataCalculationImpl =
    TournamentMetadataCalculation & {
        __typename?: 'TournamentMetadataCalculationImpl';
        balanceTypes: Array<Scalars['String']>;
        type: Scalars['String'];
    };

export type TournamentMetadataCalculationMoney = {
    amount: Scalars['String'];
    currencyCode: Scalars['String'];
};

export type TournamentMetadataCalculationMoneyImpl =
    TournamentMetadataCalculationMoney & {
        __typename?: 'TournamentMetadataCalculationMoneyImpl';
        amount: Scalars['String'];
        currencyCode: Scalars['String'];
    };

export type TournamentMetadataCalculationMultiplier =
    TournamentMetadataCalculation & {
        __typename?: 'TournamentMetadataCalculationMultiplier';
        balanceTypes: Array<Scalars['String']>;
        passingRatio: Scalars['Float'];
        segments: Array<TournamentMetadataCalculationMultiplierSegment>;
        type: Scalars['String'];
    };

export type TournamentMetadataCalculationMultiplierSegment = {
    minBetMoney: Array<TournamentMetadataCalculationMoney>;
    multiplier: Scalars['String'];
};

export type TournamentMetadataCalculationMultiplierSegmentImpl =
    TournamentMetadataCalculationMultiplierSegment & {
        __typename?: 'TournamentMetadataCalculationMultiplierSegmentImpl';
        minBetMoney: Array<TournamentMetadataCalculationMoney>;
        multiplier: Scalars['String'];
    };

export type TournamentMetadataCalculationScoring =
    TournamentMetadataCalculation & {
        __typename?: 'TournamentMetadataCalculationScoring';
        balanceTypes: Array<Scalars['String']>;
        moneyPerPoint: Array<TournamentMetadataCalculationMoney>;
        type: Scalars['String'];
    };

export type TournamentMetadataCalculationTurnover =
    TournamentMetadataCalculation & {
        __typename?: 'TournamentMetadataCalculationTurnover';
        balanceTypes: Array<Scalars['String']>;
        moneyPerPoint: Array<TournamentMetadataCalculationMoney>;
        type: Scalars['String'];
    };

export type TournamentMetadataFilterMinBets = TournamentMetadataFilters & {
    __typename?: 'TournamentMetadataFilterMinBets';
    minBets: Array<TournamentMetadataFilterMinBetsItem>;
    type: Scalars['String'];
};

export type TournamentMetadataFilterMinBetsItem = {
    amount: Scalars['Decimal'];
    currencyCode: Scalars['String'];
};

export type TournamentMetadataFilterMinBetsItemImpl =
    TournamentMetadataFilterMinBetsItem & {
        __typename?: 'TournamentMetadataFilterMinBetsItemImpl';
        amount: Scalars['Decimal'];
        currencyCode: Scalars['String'];
    };

export type TournamentMetadataFilters = {
    type: Scalars['String'];
};

export type TournamentMetadataFiltersImpl = TournamentMetadataFilters & {
    __typename?: 'TournamentMetadataFiltersImpl';
    type: Scalars['String'];
};

export type TournamentMetadataPlaces = {
    prizes: Array<TournamentMetadataPlacesPrize>;
    rank: Scalars['Int'];
};

export type TournamentMetadataPlacesActionPrizeRewardPayload = {
    actionId: Scalars['String'];
    currencyCode: Scalars['String'];
    games: Array<Scalars['String']>;
    rounds: Scalars['Int'];
};

export type TournamentMetadataPlacesActionPrizeRewardPayloadImpl =
    TournamentMetadataPlacesActionPrizeRewardPayload & {
        __typename?: 'TournamentMetadataPlacesActionPrizeRewardPayloadImpl';
        actionId: Scalars['String'];
        currencyCode: Scalars['String'];
        games: Array<Scalars['String']>;
        rounds: Scalars['Int'];
    };

export type TournamentMetadataPlacesImpl = TournamentMetadataPlaces & {
    __typename?: 'TournamentMetadataPlacesImpl';
    prizes: Array<TournamentMetadataPlacesPrize>;
    rank: Scalars['Int'];
};

export type TournamentMetadataPlacesPrize = {
    actions: Array<TournamentMetadataPlacesActionPrizeRewardPayload>;
    monies: Array<TournamentMetadataPlacesPrizeAbsoluteMonies>;
    percent: Scalars['String'];
    type: Scalars['String'];
};

export type TournamentMetadataPlacesPrizeAbsoluteMonies = {
    __typename?: 'TournamentMetadataPlacesPrizeAbsoluteMonies';
    amount: Scalars['String'];
    currencyCode: Scalars['String'];
};

export type TournamentMetadataPlacesPrizeImpl =
    TournamentMetadataPlacesPrize & {
        __typename?: 'TournamentMetadataPlacesPrizeImpl';
        actions: Array<TournamentMetadataPlacesActionPrizeRewardPayload>;
        monies: Array<TournamentMetadataPlacesPrizeAbsoluteMonies>;
        percent: Scalars['String'];
        type: Scalars['String'];
    };

export type TournamentMetadataTemplateDataImpl =
    TournamentMetadataTemplateDataInterface & {
        __typename?: 'TournamentMetadataTemplateDataImpl';
        breakInterval: Scalars['String'];
        period: Scalars['String'];
        workInterval: Scalars['String'];
    };

export type TournamentMetadataTemplateDataInterface = {
    breakInterval: Scalars['String'];
    period: Scalars['String'];
    workInterval: Scalars['String'];
};

export type TournamentParticipant = {
    createdAt: Scalars['DateTime'];
    currencyCode: Scalars['String'];
    displayName: Scalars['String'];
    lastGameId?: Maybe<Scalars['String']>;
    playerId: Scalars['String'];
    points: Scalars['Decimal'];
    qualified: Scalars['Boolean'];
    totalBetAmount: Scalars['Decimal'];
    totalBetCount: Scalars['Int'];
    totalWinAmount: Scalars['Decimal'];
    totalWinCount: Scalars['Int'];
    tournamentId: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type TournamentParticipantImpl = TournamentParticipant & {
    __typename?: 'TournamentParticipantImpl';
    createdAt: Scalars['DateTime'];
    currencyCode: Scalars['String'];
    displayName: Scalars['String'];
    lastGameId?: Maybe<Scalars['String']>;
    playerId: Scalars['String'];
    points: Scalars['Decimal'];
    qualified: Scalars['Boolean'];
    totalBetAmount: Scalars['Decimal'];
    totalBetCount: Scalars['Int'];
    totalWinAmount: Scalars['Decimal'];
    totalWinCount: Scalars['Int'];
    tournamentId: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type TournamentParticipantsInput = {
    last: Scalars['Boolean'];
    tournamentId: Scalars['String'];
};

export type TournamentPlace = {
    multiplier: Scalars['String'];
    participantCount?: Maybe<Scalars['Int']>;
    points?: Maybe<Scalars['String']>;
    position: Scalars['Int'];
    rewards?: Maybe<Array<TournamentReward>>;
    teamId?: Maybe<Scalars['String']>;
};

export type TournamentPlaceImpl = TournamentPlace & {
    __typename?: 'TournamentPlaceImpl';
    multiplier: Scalars['String'];
    participantCount?: Maybe<Scalars['Int']>;
    points?: Maybe<Scalars['String']>;
    position: Scalars['Int'];
    rewards?: Maybe<Array<TournamentReward>>;
    teamId?: Maybe<Scalars['String']>;
};

export type TournamentPlatformMetadata = {
    active: Scalars['Boolean'];
    calculation: TournamentMetadataCalculation;
    createdAt: Scalars['DateTime'];
    endAt: Scalars['DateTime'];
    filters: Array<TournamentMetadataFilters>;
    finishedAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['String'];
    places: Array<TournamentMetadataPlaces>;
    prizeFund: Scalars['Float'];
    startAt: Scalars['DateTime'];
    templateData?: Maybe<TournamentMetadataTemplateDataInterface>;
    templateId: Scalars['String'];
    title: Scalars['String'];
    type: Scalars['String'];
};

export type TournamentPlatformMetadataImpl = TournamentPlatformMetadata & {
    __typename?: 'TournamentPlatformMetadataImpl';
    active: Scalars['Boolean'];
    calculation: TournamentMetadataCalculation;
    createdAt: Scalars['DateTime'];
    endAt: Scalars['DateTime'];
    filters: Array<TournamentMetadataFilters>;
    finishedAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['String'];
    places: Array<TournamentMetadataPlaces>;
    prizeFund: Scalars['Float'];
    startAt: Scalars['DateTime'];
    templateData?: Maybe<TournamentMetadataTemplateDataInterface>;
    templateId: Scalars['String'];
    title: Scalars['String'];
    type: Scalars['String'];
};

export type TournamentPrizePool = {
    __typename?: 'TournamentPrizePool';
    createdAt: Scalars['DateTime'];
    places: Array<TournamentPrizePoolPlace>;
    totalFund: Scalars['Float'];
};

export type TournamentPrizePoolPlace = {
    __typename?: 'TournamentPrizePoolPlace';
    prize: UnionTournamentPrizePoolPlaceSinglePrizeTournamentPrizePoolPlaceMultiPrize;
    rank: Scalars['Int'];
};

export type TournamentPrizePoolPlaceMultiPrize = {
    __typename?: 'TournamentPrizePoolPlaceMultiPrize';
    monies: Array<TournamentMetadataCalculationMoney>;
};

export type TournamentPrizePoolPlaceSinglePrize = {
    __typename?: 'TournamentPrizePoolPlaceSinglePrize';
    amount: Scalars['Float'];
    currencyCode: Scalars['String'];
};

export type TournamentReward = {
    __typename?: 'TournamentReward';
    actionId: Scalars['String'];
    payload?: Maybe<TournamentRewardPayload>;
    type: Scalars['String'];
};

export type TournamentRewardPayload = {
    __typename?: 'TournamentRewardPayload';
    pointsAmount?: Maybe<Scalars['Int']>;
};

export type TournamentRoundInfoInput = {
    previous: Scalars['Boolean'];
    roundId: Scalars['String'];
    sort: TeamRaceSortTypeEnum;
    tournamentId: Scalars['String'];
};

export type TournamentRoundsInfoInput = {
    last: Scalars['Boolean'];
    sort: TeamRaceSortTypeEnum;
    tournamentId: Scalars['String'];
};

export type TournamentRoundsJoinInput = {
    roundId: Scalars['String'];
    teamId: Scalars['String'];
    tournamentId: Scalars['String'];
};

export type TournamentTeamRaceParticipant = {
    betCount: Scalars['Int'];
    createdAt: Scalars['DateTime'];
    participantId: Scalars['String'];
    qualifiedAt?: Maybe<Scalars['String']>;
    roundId: Scalars['String'];
    teamId: Scalars['String'];
    tournamentId: Scalars['String'];
    updatedAt: Scalars['DateTime'];
};

export type TournamentTeamRaceParticipantImpl =
    TournamentTeamRaceParticipant & {
        __typename?: 'TournamentTeamRaceParticipantImpl';
        betCount: Scalars['Int'];
        createdAt: Scalars['DateTime'];
        participantId: Scalars['String'];
        qualifiedAt?: Maybe<Scalars['String']>;
        roundId: Scalars['String'];
        teamId: Scalars['String'];
        tournamentId: Scalars['String'];
        updatedAt: Scalars['DateTime'];
    };

export type TournamentTeamRaceParticipants = {
    participantRoundsInfo: Array<TournamentTeamRaceParticipant>;
};

export type TournamentTeamRaceParticipantsImpl =
    TournamentTeamRaceParticipants & {
        __typename?: 'TournamentTeamRaceParticipantsImpl';
        participantRoundsInfo: Array<TournamentTeamRaceParticipant>;
    };

export type TournamentTeamRaceRound = {
    finishAt: Scalars['DateTime'];
    id: Scalars['String'];
    maxParticipantsCount: Scalars['Int'];
    places?: Maybe<Array<TournamentPlace>>;
    startAt: Scalars['DateTime'];
};

export type TournamentTeamRaceRoundImpl = TournamentTeamRaceRound & {
    __typename?: 'TournamentTeamRaceRoundImpl';
    finishAt: Scalars['DateTime'];
    id: Scalars['String'];
    maxParticipantsCount: Scalars['Int'];
    places?: Maybe<Array<TournamentPlace>>;
    startAt: Scalars['DateTime'];
};

export type TournamentTeamRaceRounds = {
    rounds: Array<TournamentTeamRaceRound>;
};

export type TournamentTeamRaceRoundsImpl = TournamentTeamRaceRounds & {
    __typename?: 'TournamentTeamRaceRoundsImpl';
    rounds: Array<TournamentTeamRaceRound>;
};

export type TournamentWinner = {
    createdAt: Scalars['DateTime'];
    displayName: Scalars['String'];
    grantedAt?: Maybe<Scalars['DateTime']>;
    playerId: Scalars['String'];
    points: Scalars['Decimal'];
    prizes: Array<TournamentWinnerPrize>;
    rank: Scalars['Int'];
    tournamentId: Scalars['String'];
};

export type TournamentWinnerImpl = TournamentWinner & {
    __typename?: 'TournamentWinnerImpl';
    createdAt: Scalars['DateTime'];
    displayName: Scalars['String'];
    grantedAt?: Maybe<Scalars['DateTime']>;
    playerId: Scalars['String'];
    points: Scalars['Decimal'];
    prizes: Array<TournamentWinnerPrize>;
    rank: Scalars['Int'];
    tournamentId: Scalars['String'];
};

export type TournamentWinnerPrize = {
    money?: Maybe<TournamentWinnerPrizeTypeMoney>;
    type: Scalars['String'];
};

export type TournamentWinnerPrizeImpl = TournamentWinnerPrize & {
    __typename?: 'TournamentWinnerPrizeImpl';
    money?: Maybe<TournamentWinnerPrizeTypeMoney>;
    type: Scalars['String'];
};

export type TournamentWinnerPrizeTypeMoney = {
    amount: Scalars['String'];
    currencyCode: Scalars['String'];
};

export type TournamentWinnerPrizeTypeMoneyImpl =
    TournamentWinnerPrizeTypeMoney & {
        __typename?: 'TournamentWinnerPrizeTypeMoneyImpl';
        amount: Scalars['String'];
        currencyCode: Scalars['String'];
    };

export type TrackerInterfaceInput = {
    name: Scalars['String'];
    value: Scalars['String'];
};

export type TrackersInput = {
    trackers?: Array<TrackerInterfaceInput>;
};

export type Trustpilot = {
    __typename?: 'Trustpilot';
    link: Scalars['String'];
};

export type TvBetPlayData = PlayData & {
    __typename?: 'TvBetPlayData';
    /** @deprecated Category should not be used anymore (deprecated by game provider). Field wil be removed after: 2024-12-05 */
    category: Scalars['String'];
    clientId: Scalars['String'];
    demo: Scalars['Boolean'];
    exitUrl: Scalars['String'];
    gameId: Scalars['String'];
    gameProviderName: Scalars['String'];
    gameServerUrl: Scalars['String'];
    gameSizeOptions: GameSizeOptions;
    lng: Scalars['String'];
    playUsingBonusBalanceEnabled: Scalars['Boolean'];
    server: Scalars['String'];
    tokenAuth: Scalars['String'];
};

export type TypeAcceptBetCountConditionRule = ConditionRuleInterface & {
    __typename?: 'TypeAcceptBetCountConditionRule';
    betType: Scalars['String'];
    countEvents: Scalars['Int'];
    type: ConditionRuleEnumType;
};

export type TypedBonusProgramListFilterInput = {
    active?: InputMaybe<Scalars['Boolean']>;
    category?: InputMaybe<Scalars['String']>;
    displayInType?: InputMaybe<Scalars['String']>;
};

export type UnacceptedLogsResultType = {
    __typename?: 'UnacceptedLogsResultType';
    logs: Array<FrontendServiceResult>;
};

export type UnionBonusProgramDataWinTaskDataBonusProgramDataBetTaskDataBonusProgramDataBettingTaskDataBonusProgramDataDepositTaskDataBonusProgramDataManualTaskDataBonusProgramDataTaskData =

        | BonusProgramDataBetTaskData
        | BonusProgramDataBettingTaskData
        | BonusProgramDataDepositTaskData
        | BonusProgramDataManualTaskData
        | BonusProgramDataTaskData
        | BonusProgramDataWinTaskData;

export type UnionGameTournamentLocalizedBonusProgramLocalizedPromotionLocalizedCategoryFrontendGqlLocalizedTheme =

        | BonusProgramLocalized
        | Category
        | FrontendGqlLocalizedTheme
        | Game
        | PromotionLocalized
        | TournamentLocalized;

export type UnionTournamentPrizePoolPlaceSinglePrizeTournamentPrizePoolPlaceMultiPrize =
    TournamentPrizePoolPlaceMultiPrize | TournamentPrizePoolPlaceSinglePrize;

export type UpdateProfileInput = {
    address?: InputMaybe<ProfileAddressInput>;
    avatar?: InputMaybe<ProfileAvatarInput>;
    citizenship?: InputMaybe<ProfileCitizenshipInput>;
    display?: InputMaybe<ProfileDisplayInput>;
    dob?: InputMaybe<ProfileDobInput>;
    email?: InputMaybe<ProfileEmailInput>;
    gender?: InputMaybe<ProfileGenderInput>;
    identification?: InputMaybe<ProfileIdentificationInput>;
    liveContact?: InputMaybe<ProfileLiveContactInput>;
    name?: InputMaybe<ProfileNameInput>;
    phone?: InputMaybe<ProfilePhoneInput>;
    residence?: InputMaybe<ProfileResidenceInput>;
    security?: InputMaybe<ProfileSecurityInput>;
    skype?: InputMaybe<ProfileSkypeInput>;
    taxNumber?: InputMaybe<ProfileTaxNumberInput>;
};

export type UrlLink = PaymentLink & {
    __typename?: 'UrlLink';
    method: Scalars['String'];
    type: Scalars['String'];
    url: Scalars['String'];
};

export type User = SymfonyUserInterface &
    UserInterface & {
        __typename?: 'User';
        id: Scalars['String'];
        roles: Array<Scalars['String']>;
        userName: Scalars['String'];
    };

export type UserInterface = {
    id: Scalars['String'];
    roles: Array<Scalars['String']>;
    userName: Scalars['String'];
};

export enum VipStatusTypeEnum {
    All = 'ALL',
    NotVip = 'NOT_VIP',
    Vip = 'VIP',
}

export type WealthElement = {
    __typename?: 'WealthElement';
    createdAt: Scalars['DateTime'];
    source: Scalars['String'];
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WebPushNotificationSettings = {
    enabledForMobile: Scalars['Boolean'];
    enabledForWeb: Scalars['Boolean'];
};

export type WebPushNotificationSettingsImpl = WebPushNotificationSettings & {
    __typename?: 'WebPushNotificationSettingsImpl';
    enabledForMobile: Scalars['Boolean'];
    enabledForWeb: Scalars['Boolean'];
};

export type WebPushSettingsResultType = {
    __typename?: 'WebPushSettingsResultType';
    settings: WebPushNotificationSettings;
};

export type WheelOfFortune = {
    freeGamesQuantity: Scalars['Int'];
    freeGamesTimeToLive: Array<FreeGamesTimeToLive>;
    game?: Maybe<WheelOfFortuneGame>;
    gameCost: Scalars['Decimal'];
    gameWheel?: Maybe<WheelOfFortuneGameWheel>;
    requestedWheel?: Maybe<WheelOfFortuneGameWheel>;
};

export type WheelOfFortuneFreeGames = {
    quantity: Scalars['Int'];
};

export type WheelOfFortuneFreeGamesImpl = WheelOfFortuneFreeGames & {
    __typename?: 'WheelOfFortuneFreeGamesImpl';
    quantity: Scalars['Int'];
};

export type WheelOfFortuneGame = {
    currencyCode: Scalars['String'];
    ended: Scalars['Boolean'];
    gameCost: Scalars['Decimal'];
    multiplier: Scalars['Decimal'];
    paymentDone: Scalars['Boolean'];
    type: Scalars['String'];
    wonSector: WheelOfFortuneGameSector;
};

export type WheelOfFortuneGameImpl = WheelOfFortuneGame & {
    __typename?: 'WheelOfFortuneGameImpl';
    currencyCode: Scalars['String'];
    ended: Scalars['Boolean'];
    gameCost: Scalars['Decimal'];
    multiplier: Scalars['Decimal'];
    paymentDone: Scalars['Boolean'];
    type: Scalars['String'];
    wonSector: WheelOfFortuneGameSector;
};

export type WheelOfFortuneGameSector = {
    id: Scalars['String'];
    prize: WheelOfFortuneGameSectorPrize;
};

export type WheelOfFortuneGameSectorImpl = WheelOfFortuneGameSector & {
    __typename?: 'WheelOfFortuneGameSectorImpl';
    id: Scalars['String'];
    prize: WheelOfFortuneGameSectorPrize;
};

export type WheelOfFortuneGameSectorPrize = {
    lifeTime?: Maybe<Scalars['Int']>;
    type: Scalars['String'];
    value: Scalars['Decimal'];
};

export type WheelOfFortuneGameSectorPrizeImpl =
    WheelOfFortuneGameSectorPrize & {
        __typename?: 'WheelOfFortuneGameSectorPrizeImpl';
        lifeTime?: Maybe<Scalars['Int']>;
        type: Scalars['String'];
        value: Scalars['Decimal'];
    };

export enum WheelOfFortuneGameTypeEnum {
    Free = 'FREE',
    Paid = 'PAID',
}

export type WheelOfFortuneGameWheel = {
    currencyCode: Scalars['String'];
    multiplier: Scalars['Decimal'];
    sectors: Array<WheelOfFortuneGameSector>;
};

export type WheelOfFortuneGameWheelImpl = WheelOfFortuneGameWheel & {
    __typename?: 'WheelOfFortuneGameWheelImpl';
    currencyCode: Scalars['String'];
    multiplier: Scalars['Decimal'];
    sectors: Array<WheelOfFortuneGameSector>;
};

export type WheelOfFortuneImpl = WheelOfFortune & {
    __typename?: 'WheelOfFortuneImpl';
    freeGamesQuantity: Scalars['Int'];
    freeGamesTimeToLive: Array<FreeGamesTimeToLive>;
    game?: Maybe<WheelOfFortuneGame>;
    gameCost: Scalars['Decimal'];
    gameWheel?: Maybe<WheelOfFortuneGameWheel>;
    requestedWheel?: Maybe<WheelOfFortuneGameWheel>;
};

export type WidgetKeyValue = {
    key: Scalars['String'];
    value: Scalars['String'];
};

export type WidgetKeyValueImpl = WidgetKeyValue & {
    __typename?: 'WidgetKeyValueImpl';
    key: Scalars['String'];
    value: Scalars['String'];
};

export type WidgetLocalized = {
    __typename?: 'WidgetLocalized';
    fields: Array<WidgetKeyValue>;
    id: Scalars['String'];
    locale: Scalars['String'];
    name: Scalars['String'];
    template: Scalars['String'];
};

export type WinTaskSettings = {
    amountPerWin: Array<SettingsCurrencyMap>;
    balanceTypes: Array<Scalars['String']>;
    gameIds: Array<Scalars['String']>;
    id: Scalars['String'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
    totalWinAmount: Array<SettingsCurrencyMap>;
    winCount: Scalars['Int'];
};

export type WinTaskSettingsImpl = WinTaskSettings & {
    __typename?: 'WinTaskSettingsImpl';
    amountPerWin: Array<SettingsCurrencyMap>;
    balanceTypes: Array<Scalars['String']>;
    gameIds: Array<Scalars['String']>;
    id: Scalars['String'];
    rewards: Array<TaskSettingsRewards>;
    taskType: Scalars['String'];
    totalWinAmount: Array<SettingsCurrencyMap>;
    winCount: Scalars['Int'];
};

export type Winner = {
    /** @deprecated Use `currencyData` field instead */
    currency: Scalars['String'];
    currencyData: Currency;
    game?: Maybe<Game>;
    gameId: Scalars['String'];
    linked: Scalars['Boolean'];
    nickname: Scalars['String'];
    value: Scalars['Float'];
};

export type WinnerImpl = Winner & {
    __typename?: 'WinnerImpl';
    /** @deprecated Use `currencyData` field instead */
    currency: Scalars['String'];
    currencyData: Currency;
    game?: Maybe<Game>;
    gameId: Scalars['String'];
    linked: Scalars['Boolean'];
    nickname: Scalars['String'];
    value: Scalars['Float'];
};

export type WithdrawCommissionDto = {
    __typename?: 'WithdrawCommissionDto';
    amount: PaymentAmountDto;
    clarifications: Array<Clarification>;
    commission: PaymentAmountDto;
};

export type YhelperChat = Chat & {
    __typename?: 'YhelperChat';
    /** Contains the type of the main class that must be initialized to create the chat widget */
    class: YhelperClassEnum;
    /** Contains a link to the JS file that should be connected to initialize the chat widget */
    scriptUrl: Scalars['String'];
    signature: Scalars['String'];
    /** Contains a color chat widget theme. It is created by the provider, for use it should be passed in the setting field during the initialization of the chat widget */
    theme: Scalars['String'];
    type: ChatWidgetTypeEnum;
    widgetId: Scalars['Int'];
};

export enum YhelperClassEnum {
    FastChat = 'FAST_CHAT',
    Yhelper = 'YHELPER',
}

export type DynamicRouteEntity = {
    __typename?: 'dynamicRouteEntity';
    info: RoutingRelatedEntityInfo;
    routeId: Scalars['String'];
};

export type DynamicRouteResult = {
    __typename?: 'dynamicRouteResult';
    entity?: Maybe<DynamicRouteEntity>;
};
