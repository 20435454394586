import type { FC } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import MobileBetslipButtonWrapper from 'components/betting/Betslip/MobileBetslipButtonWrapper';
import PackIcon, { IconsPack } from 'components/PackIcon';
import { useToggle } from 'hooks';
import SidebarTabs from '../SidebarTabs';

const SidebarPopup: FC = () => {
    const tBettingGlobal = useTranslations('betting-global.global');

    const [isOpen, handleIsOpen] = useToggle(false);

    // 48px is height of button
    // 16px is gab from bottom
    const height = cn(
        'h-[calc(100vh-theme(spacing.footer-offset)-theme(spacing.header-group-offset)-48px)] md:h-[calc(100vh-theme(spacing.header-group-offset)-16px-48px)]'
    );

    const container = cn(
        'flex w-[280px] flex-col border-default border-surface-middle bg-surface-dark',
        height
    );
    const dropShadow = cn('shadow-[31px_29px_81px_31px_#000]');

    const buttonToggleShadow = cn('shadow-[0_0_72px_rgba(0,0,0,0.5)]');
    const buttonToggle = cn(
        'relative flex w-[280px] cursor-pointer items-center justify-between bg-surface-light p-3 capitalize text-primary-white typo-sm-2',
        { [buttonToggleShadow]: !isOpen }
    );

    const totalOddsContainer = cn(
        'flex items-center gap-1 rounded-full bg-violet-500 px-2 py-1 text-primary-white typo-sm-2'
    );

    const label = isOpen
        ? tBettingGlobal('hide')
        : tBettingGlobal('BettingSlip');

    return (
        <div
            className={cn(
                'fixed bottom-footer-offset right-0 z-10  md:bottom-4',
                { [dropShadow]: isOpen }
            )}
            data-test="sidebar-popup"
        >
            {isOpen && (
                <div className={cn(container)}>
                    <SidebarTabs />
                </div>
            )}
            <MobileBetslipButtonWrapper>
                {({ count, totalOdds }) => (
                    <div className={cn(buttonToggle)} onClick={handleIsOpen}>
                        <div className="flex w-full items-center justify-between">
                            <div className="flex items-center gap-2">
                                {!isOpen && (
                                    <div
                                        className={totalOddsContainer}
                                        data-test="sidebar-popup__count"
                                    >
                                        {count}
                                    </div>
                                )}
                                <div
                                    className="mr-1"
                                    data-test="sidebar-popup__label"
                                >
                                    {label}
                                </div>
                            </div>
                        </div>
                        {!isOpen && !!totalOdds && (
                            <div
                                className="rounded-default bg-violet-500 px-2 py-1"
                                data-test="sidebar-popup__total-odds"
                            >
                                {totalOdds}
                            </div>
                        )}
                        <div className={cn('ml-2', { 'rotate-180': !isOpen })}>
                            <PackIcon
                                id="arrow-down"
                                pack={IconsPack.SpriteIcons}
                                className="fill-primary-white"
                            />
                        </div>
                    </div>
                )}
            </MobileBetslipButtonWrapper>
        </div>
    );
};

export default SidebarPopup;
